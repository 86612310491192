import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { CSVLink } from "react-csv";
import Encoding from 'encoding-japanese';
import { JobCdName } from '../Payment/Payment';
// import Common from '../Common/common.js';
import { generatePath } from 'react-router';
import BackyardOrderDetailPrint from './BackyardOrderDetailPrint';
import Common from '../Common/common.js';

// 注文一覧
const BackyardOrderList = (props) => {
  const { user, condition, conditionActions, settings } = props;
  const [orderData, setOrderData] = useState(null);
  const [lockData, setLockData] = useState(false);
  const [sumCount, setSumCount] = useState(0); //合計点数
  const [sumTotal, setSumTotal] = useState(0); //合計金額
  const [orderCount, setOrderCount] = useState(0); //注文件数
  //const [sortOrder, setSortOrder] = useState([]); // ソート順指定
  // const [entryType, setEntryType] = useState(null); //一覧表示項目
  const [timeMaster, setTimeMaster] = useState([]); //受取時間帯マスタ
  const { addToast } = useToasts();
  const componentRef = useRef();
  const [csvData, setCsvData] = useState(null);
  const [csvDetailData, setCsvDetailData] = useState(null);
  const [csvPaymentData, setCsvPaymentData] = useState(null);
  const [importData, setImportData] = useState(null);
  // const [colTrackingNo, setColTrackingNo] = useState(null);
  // const [colOrderNo, setColOrderNo] = useState(null);
  // const [colDeliveryTel1, setColDeliveryTel1] = useState(null);
  // const [colDeliveryTel2, setColDeliveryTel2] = useState(null);
  // const [colDeliveryTel3, setColDeliveryTel3] = useState(null);
  const [modalPage, setModalPage] = useState(null);
  const [columns, setColumns] = useState({});
  const [importResult, setImportResult] = useState(null);
  const paymentDataRef = useRef();
  const [printModal, setPrintModal] = useState(false);
  // const [initialRowIgnored, setInitialRowIgnored] = useState(false);
  const COL = {
    trackingNo: 'trackingNo',
    orderNo: 'orderNo',
    deliveryTel1: 'deliveryTel1',
    deliveryTel2: 'deliveryTel2',
    deliveryTel3: 'deliveryTel3',
    initialRowIgnored: 'initialRowIgnored',
    orderPrefix: 'orderPrefix',
    deliveryConfirm: 'deliveryConfirm',
    importResult: 'importResult',
  }

  const sortData = useCallback((a, b) => {
    if (condition.sortOrder.length <= 0) { // 初期値は受取日→受取時間
      return (a.receive_date === b.receive_date) ? Number(a.receive_time) - Number(b.receive_time) : new Date(a.receive_date) - new Date(b.receive_date);
    }
    for (var i = 0; i < condition.sortOrder.length; i++) {
      var item = condition.sortOrder[i];
      if (item.column === 't_order_detail') {
        if (item.order === 'ASC') {
          if (!a.t_order_detail) {
            // csvのソートの場合
            if (!a.t_order_detail) {
              // csvのソートの場合
              if (Number(a.total_count) < Number(b.total_count)) return -1;
              if (Number(a.total_count) > Number(b.total_count)) return 1;
            } else {
              if (a.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0) < b.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0)) return -1;
              if (a.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0) > b.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0)) return 1;
            }
          } else {
            if (a.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0) < b.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0)) return -1;
            if (a.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0) > b.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0)) return 1;
          }
        } else {
          if (!a.t_order_detail) {
            // csvのソートの場合
            if (Number(b.total_count) < Number(a.total_count)) return -1;
            if (Number(b.total_count) > Number(a.total_count)) return 1;
          } else {
            if (b.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0) < a.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0)) return -1;
            if (b.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0) > a.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0)) return 1;
          }
        }
      } else if (item.column === 'status') {
        const aStatus = a.status === constClass.STATUS.UPAY ? 1 : a.status === constClass.STATUS.REG ? 2 : a.status === constClass.STATUS.PRE ? 3 : a.status === constClass.STATUS.FIN ? 4 : a.status === constClass.STATUS.DFIN ? 5 : 6;
        const bStatus = b.status === constClass.STATUS.UPAY ? 1 : b.status === constClass.STATUS.REG ? 2 : b.status === constClass.STATUS.PRE ? 3 : b.status === constClass.STATUS.FIN ? 4 : b.status === constClass.STATUS.DFIN ? 5 : 6;
        if (item.order === 'ASC') {
          if (aStatus < bStatus) return -1;
          if (aStatus > bStatus) return 1;
        } else {
          if (bStatus < aStatus) return -1;
          if (bStatus > aStatus) return 1;
        }
      } else if (item.column === 'total' || item.column === 'receipt_num') {
        if (item.order === 'ASC') {
          if (Number(a[item.column]) < Number(b[item.column])) return -1;
          if (Number(a[item.column]) > Number(b[item.column])) return 1;
        } else {
          if (Number(b[item.column]) < Number(a[item.column])) return -1;
          if (Number(b[item.column]) > Number(a[item.column])) return 1;
        }
      } else if (item.column === 'employee_flag') {
        const aFlag = a.employee_flag === '1' ? 0 : 1;
        const bFlag = b.employee_flag === '1' ? 0 : 1;
        if (item.order === 'ASC') {
          if (aFlag < bFlag) return -1;
          if (aFlag > bFlag) return 1;
        } else {
          if (bFlag < aFlag) return -1;
          if (bFlag > aFlag) return 1;
        }
      } else {
        if (item.order === 'ASC') {
          if (a[item.column] < b[item.column]) return -1;
          if (a[item.column] > b[item.column]) return 1;
        } else {
          if (b[item.column] < a[item.column]) return -1;
          if (b[item.column] > a[item.column]) return 1;
        }
      }
    }
    return 0;
  }, [condition.sortOrder]);

  // 「注文データ出力」data
  const createCsvdata = useCallback((data) => {
    var list = [];
    var head = [];
    head.push({ label: "注文番号", key: "receipt_num" });
    head.push({ label: "従業員", key: "employee_flag" });
    head.push({ label: "LINE_ID", key: "line_id" });
    head.push({ label: "お客様名", key: "customer_name" });
    head.push({ label: "カナ", key: "customer_kana" });
    head.push({ label: "電話番号", key: "customer_tel" });
    head.push({ label: "受取"+Common.getSettingValue(settings, 'SHOP_NAME', '店舗')+"ID", key: "receive_shop" });
    head.push({ label: "受取"+Common.getSettingValue(settings, 'SHOP_NAME', '店舗'), key: "receive_shop_name" });
    head.push({ label: "企画ID", key: "plan_id" });
    head.push({ label: "企画名", key: "plan_name" });
    head.push({ label: "受取日", key: "receive_date" });
    if (props.delivery_flag !== constClass.FLAG.ON) {
      head.push({ label: "受取時間帯", key: "receive_time" });
    } else {
      head.push({ label: "郵便番号", key: "customer_postal_code" });
      head.push({ label: "住所", key: "customer_address" });
      head.push({ label: "配送先お客様名", key: "delivery_name" });
      head.push({ label: "配送先カナ", key: "delivery_kana" });
      head.push({ label: "配送先電話番号", key: "delivery_tel" });
      head.push({ label: "配送先郵便番号", key: "delivery_postal_code" });
      head.push({ label: "配送先住所", key: "delivery_address" });
    }
    head.push({ label: "種別", key: "order_type" });
    head.push({ label: "小計", key: "subtotal" });
    head.push({ label: "割引額", key: "discount" });
    head.push({ label: "税額", key: "tax" });
    head.push({ label: "税込総額", key: "total" });
    head.push({ label: "受注者", key: "contractor" });
    head.push({ label: "入力者", key: "inputuser" });
    head.push({ label: "注文日", key: "ins_date" });
    head.push({ label: "更新日", key: "upd_date" });
    head.push({ label: "ステータス", key: "status" });
    head.push({ label: "管理者備考", key: "manager_note" });
    data.forEach(p => {
      var product = {
        receipt_num: p.receipt_num,
        employee_flag: p.employee_flag === '1' ? '○' : '',
        line_id: p.line_id.replace(/"/g, '""'),
        customer_name: p.customer_name.replace(/"/g, '""'),
        customer_kana: p.customer_kana.replace(/"/g, '""'),
        customer_tel: p.customer_tel,
        receive_shop: p.receive_shop,
        receive_shop_name: p.receive_shop_name.replace(/"/g, '""'),
        plan_id: p.plan_id,
        plan_name: p.plan_name.replace(/"/g, '""'),
        receive_date: p.receive_date,
        receive_time: timeMaster.find(t => t.time_id.toString() === p.receive_time) ? timeMaster.find(t => t.time_id.toString() === p.receive_time).time_name.replace(/"/g, '""') : '',
        customer_postal_code: p.customer_postal_code,
        customer_address: (p.customer_address ? p.customer_address.replace(/"/g, '""') : p.customer_address),
        delivery_name: (p.delivery_name ? p.delivery_name.replace(/"/g, '""') : p.delivery_name),
        delivery_kana: (p.delivery_kana ? p.delivery_kana.replace(/"/g, '""') : p.delivery_kana),
        delivery_tel: p.delivery_tel,
        delivery_postal_code: p.delivery_postal_code,
        delivery_address: (p.delivery_address ? p.delivery_address.replace(/"/g, '""') : p.delivery_address),
        order_type: p.order_type,
        subtotal: Math.floor(p.subtotal).toLocaleString(),
        discount: Math.floor(p.discount).toLocaleString(),
        tax: Math.floor(p.tax).toLocaleString(),
        total: Math.floor(p.total).toLocaleString(),
        total_count: p.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0),
        contractor: p.contractor,
        inputuser: p.inputuser,
        ins_date: moment(p.ins_date).format("YYYY/MM/DD HH:mm:ss"),
        upd_date: moment(p.upd_date).format("YYYY/MM/DD HH:mm:ss"),
        status: constClass.STATUS_NAME[p.status],
        manager_note: (p.manager_note ? p.manager_note.replace(/"/g, '""') : p.manager_note),
      };
      // p.t_order_detail.map(d => {
      //   product = { ...product, product_id: d.product_id }
      //   product = { ...product, product_name: d.product_name }
      //   product = { ...product, early_discount: d.early_discount === constClass.EARLY_DISCOUNT.ON ? "対象" : "対象外" }
      //   product = { ...product, price_before_tax: d.price_before_tax }
      //   product = { ...product, price: d.price }
      //   product = { ...product, tax_rate: d.tax_rate }
      //   product = { ...product, count: d.count }
      //   list.push(product);
      // });
      list.push(product);
    });

    setCsvData({ header: head, list: list });
  }, [props.delivery_flag, timeMaster, settings]);

  const createCsvDetaildata = useCallback((data) => {
    var list = [];
    var head = [];
    head.push({ label: "注文番号", key: "receipt_num" });
    head.push({ label: "従業員", key: "employee_flag" });
    head.push({ label: "LINE_ID", key: "line_id" });
    head.push({ label: "お客様名", key: "customer_name" });
    head.push({ label: "カナ", key: "customer_kana" });
    head.push({ label: "電話番号", key: "customer_tel" });
    head.push({ label: "受取"+Common.getSettingValue(settings, 'SHOP_NAME', '店舗')+"ID", key: "receive_shop" });
    head.push({ label: "受取"+Common.getSettingValue(settings, 'SHOP_NAME', '店舗'), key: "receive_shop_name" });
    head.push({ label: "企画ID", key: "plan_id" });
    head.push({ label: "企画名", key: "plan_name" });
    head.push({ label: "受取日", key: "receive_date" });
    if (props.delivery_flag !== constClass.FLAG.ON) {
      head.push({ label: "受取時間帯", key: "receive_time" });
    } else {
      head.push({ label: "郵便番号", key: "customer_postal_code" });
      head.push({ label: "住所", key: "customer_address" });
      head.push({ label: "配送先お客様名", key: "delivery_name" });
      head.push({ label: "配送先カナ", key: "delivery_kana" });
      head.push({ label: "配送先電話番号", key: "delivery_tel" });
      head.push({ label: "配送先郵便番号", key: "delivery_postal_code" });
      head.push({ label: "配送先住所", key: "delivery_address" });
    }
    head.push({ label: "種別", key: "order_type" });
    head.push({ label: "小計", key: "subtotal" });
    head.push({ label: "割引額", key: "discount" });
    head.push({ label: "税額", key: "tax" });
    head.push({ label: "税込総額", key: "total" });
    head.push({ label: "商品CD", key: "product_code" });
    head.push({ label: "商品名", key: "product_name" });
    head.push({ label: "商品単価", key: "price" });
    head.push({ label: "商品税率", key: "tax_rate" });
    head.push({ label: Common.getSettingValue(settings, 'ORDER_DEPT_NAME', '商品デプト')+"コード", key: "product_d_code" });
    head.push({ label: Common.getSettingValue(settings, 'ORDER_DEPT_NAME', '商品デプト'), key: "product_d_name" });
    head.push({ label: "商品早割割引対象", key: "early_discount" });
    head.push({ label: "商品注文数", key: "count" });
    head.push({ label: "受注者", key: "contractor" });
    head.push({ label: "入力者", key: "inputuser" });
    head.push({ label: "注文日", key: "ins_date" });
    head.push({ label: "更新日", key: "upd_date" });
    head.push({ label: "ステータス", key: "status" });
    head.push({ label: "商品JANコード", key: "jan_code" });
    if(Common.getSettingValue(settings, 'ORDER_NOTE', '') === '1'){
      head.push({ label: "備考", key: "order_note" });
    }
    data.forEach(p => {
      var product = {
        receipt_num: p.receipt_num,
        employee_flag: p.employee_flag === '1' ? '○' : '',
        line_id: p.line_id.replace(/"/g, '""'),
        customer_name: p.customer_name.replace(/"/g, '""'),
        customer_kana: p.customer_kana.replace(/"/g, '""'),
        customer_tel: p.customer_tel,
        receive_shop: p.receive_shop,
        receive_shop_name: p.receive_shop_name.replace(/"/g, '""'),
        plan_id: p.plan_id,
        plan_name: p.plan_name.replace(/"/g, '""'),
        receive_date: p.receive_date,
        receive_time: timeMaster.find(t => t.time_id.toString() === p.receive_time) ? timeMaster.find(t => t.time_id.toString() === p.receive_time).time_name.replace(/"/g, '""') : '',
        customer_postal_code: p.customer_postal_code,
        customer_address: (p.customer_address ? p.customer_address.replace(/"/g, '""') : p.customer_address),
        delivery_name: (p.delivery_name ? p.delivery_name.replace(/"/g, '""') : p.delivery_name),
        delivery_kana: (p.delivery_kana ? p.delivery_kana.replace(/"/g, '""') : p.delivery_kana),
        delivery_tel: p.delivery_tel,
        delivery_postal_code: p.delivery_postal_code,
        delivery_address: (p.delivery_address ? p.delivery_address.replace(/"/g, '""') : p.delivery_address),
        order_type: p.order_type,
        subtotal: Math.floor(p.subtotal).toLocaleString(),
        discount: Math.floor(p.discount).toLocaleString(),
        tax: Math.floor(p.tax).toLocaleString(),
        total: Math.floor(p.total).toLocaleString(),
        total_count: p.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0),
        product_code: null,
        product_name: null,
        product_d_code: null,
        product_d_name: null,
        early_discount: null,
        price_before_tax: null,
        price: null,
        tax_rate: null,
        count: 0,
        contractor: p.contractor,
        inputuser: p.inputuser,
        ins_date: moment(p.ins_date).format("YYYY/MM/DD HH:mm:ss"),
        upd_date: moment(p.upd_date).format("YYYY/MM/DD HH:mm:ss"),
        status: constClass.STATUS_NAME[p.status],
        order_note: null,
      };
      p.t_order_detail.forEach(d => {
        product = { ...product, product_code: d.product_code }
        product = { ...product, product_name: d.product_name.replace(/"/g, '""') }
        product = { ...product, product_d_code: d.m_product ? d.m_product.order_product_dept ? d.m_product.order_product_dept.product_d_code : '' : '' }
        product = { ...product, product_d_name: d.m_product ? d.m_product.order_product_dept ? d.m_product.order_product_dept.product_d_name : '' : '' }
        product = { ...product, early_discount: d.early_discount === constClass.EARLY_DISCOUNT.ON ? "対象" : "対象外" }
        product = { ...product, price_before_tax: d.price_before_tax }
        product = { ...product, price: d.price }
        product = { ...product, tax_rate: d.tax_rate }
        product = { ...product, count: d.count }
        product = { ...product, order_note: (d.order_note ? d.order_note.replace(/"/g, '""') : d.order_note)}
        product = { ...product, jan_code: d.m_product ? d.m_product.jan_code : '' }
        list.push(product);
      });
    });

    setCsvDetailData({ header: head, list: list });
  }, [props.delivery_flag, timeMaster, settings]);

  const refreshOrder = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    if (condition.isReload) {
      // console.log(props);
      var reg_params = {};
      reg_params = {
        "site_id": props.match.params.siteId,
        "operator": "and",
        "where": [{ "site_id": props.match.params.siteId }]
      }
      // 店舗
      if (user.userClass !== constClass.CLASS.ADMIN && user.userClass !== constClass.CLASS.READER) {
        //店舗ユーザは自分の店舗のみ
        reg_params.where.push({ "receive_shop": user.shopId });
      } else
        if (condition.shop !== "") {
          reg_params.where.push({ "receive_shop": condition.shop });
        }
      // 企画
      if (condition.plan !== "") {
        reg_params.where.push({ "plan_id": condition.plan });
      }
      // 受取日
      if (condition.receiveDate !== null) {
        reg_params.where.push({
          "operator": "in",
          "attr": "receive_date",
          "val": condition.receiveDate
        });
      }
      // 受取日
      if (condition.status !== null) {
        reg_params.where.push({
          "operator": "in",
          "attr": "status",
          "val": condition.status
        });
      }
      // // 受取日(開始)
      // if (props.condition.startDate) {
      //   reg_params.where.push({
      //     "operator": "gte",
      //     "attr": "receive_date",
      //     "val": props.condition.startDate
      //   });

      //   // 受取日(終了)
      //   if (props.condition.endDate) {
      //     reg_params.where.push({
      //       "operator": "lte",
      //       "attr": "receive_date",
      //       "val": props.condition.endDate
      //     });
      //   } else {
      //     reg_params.where.push({
      //       "operator": "lte",
      //       "attr": "receive_date",
      //       "val": props.condition.startDate
      //     });
      //   }
      // }
      // 配送フラグ
      if (props.delivery_flag === constClass.FLAG.ON) {
        reg_params.where.push({
          "delivery_flag": constClass.FLAG.ON
        });
      } else {
        reg_params.where.push({
          "operator": "or",
          "where": [{ "delivery_flag": null }, { "delivery_flag": constClass.FLAG.OFF }]
        });
      }

      const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/search/detail/`, reg_params, { // 注文一覧取得
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      })).data;
      // 入力用データの加工処理
      data.forEach(d => {
        d.tracking_no = d.tracking_no ? d.tracking_no : ''
      });

      var sc = 0;
      var st = 0;
      data.filter(d => ![constClass.STATUS.CCL, constClass.STATUS.CUPAY].includes(d.status)).forEach(d => {
        st += Math.floor(d.total);
        sc += d.t_order_detail.reduce((sum, element) => sum + element.count, 0);
      });
      setSumCount(sc);
      setSumTotal(st);
      setOrderCount(data.filter(d => ![constClass.STATUS.CCL, constClass.STATUS.CUPAY].includes(d.status)).length);
      // data.sort((a, b) => (a.receive_date === b.receive_date) ? Number(a.receive_time) - Number(b.receive_time) : new Date(a.receive_date) - new Date(b.receive_date));
      // data.sort((a, b) => sortData(a, b));
      setOrderData(data);
      createCsvdata(data);
      createCsvDetaildata(data);
      conditionActions.setIsReload(false);
    }
  }, [props.match.params.siteId, props.delivery_flag, user, condition.shop, condition.plan, condition.receiveDate, condition.status, condition.isReload, createCsvdata, createCsvDetaildata, conditionActions]);

  const refreshMaster = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const filter = {
      "site_id": props.match.params.siteId,
      "operator": "and",
      "where": [
        { "site_id": props.match.params.siteId },
      ]
    }
    // const rs = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/entry_type/search/`, filter)).data; // 入力項目取得
    // rs.sort((a, b) => a.entry_type < b.entry_type ? -1 : 1);
    // setEntryType(rs);
    const time = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/receive_time/search/`, filter, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    setTimeMaster(time);
  }, [props.match.params.siteId]);

  // const handleOrderClick = async (order_id) => {
  //   userActions.refresh();
  //   history.replace(`${props.match.path}order/input/${order_id}`);
  // }

  const handleStatusButtonClick = async (order_id, status) => {
    const jwt = localStorage.getItem('jwt');
    var order = orderData.find(o => o.order_id === order_id);
    if (order.status === status) {
      // 同じボタンを押したときは元に戻す
      status = constClass.STATUS.REG;
    }
    // console.log(util.inspect(order.t_order_detail));
    // 受け取り以外の操作は変更可能日を過ぎるとADMIN権限を持たないユーザは操作できない
    if (status !== constClass.STATUS.FIN && user.userClass !== constClass.CLASS.ADMIN && order.t_order_detail.reduce((min, detail) => min > detail.m_product.change_limit ? detail.m_product.change_limit : min, '99999999') < moment().format('YYYYMMDD')) {
      window.alert(`変更可能日を過ぎているため操作できません。商品部へご連絡をお願いします。`);
      return;
    }
    if (status === constClass.STATUS.CCL && !window.confirm(`注文番号[${order.receipt_num}]をキャンセルします。よろしいですか？`)) {
      return;
    }
    if ([constClass.STATUS.FIN, constClass.STATUS.DFIN].includes(status) && order.status !== constClass.STATUS.UPAY && !window.confirm(`注文番号[${order.receipt_num}]を${constClass.STATUS_NAME[status]}にします。よろしいですか？`)) {
      return;
    }
    if ([constClass.STATUS.FIN, constClass.STATUS.DFIN].includes(status) && order.status === constClass.STATUS.UPAY && !window.confirm(`注文番号[${order.receipt_num}]は決済が完了していません。${constClass.STATUS_NAME[status]}にしてもよろしいですか？`)) {
      return;
    }
    if (status === constClass.STATUS.REG && !window.confirm(`注文番号[${order.receipt_num}]のステータスを元に戻します。よろしいですか？`)) {
      return;
    }
    const params = {
      site_id: props.match.params.siteId,
      order_id,
      status,
      user_id: [constClass.STATUS.REG, constClass.STATUS.FIN, constClass.STATUS.DFIN, constClass.STATUS.CCL].includes(status) ? user.userId : null,
      tracking_no: order.tracking_no,
    };
    console.log(order);
    try {
      setLockData(true);
      if (props.delivery_flag === constClass.FLAG.ON) {
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/order/batch_update/status/${props.match.params.siteId}/${order_id}`, { order_list: [params] }, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        });
      } else {
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/order/status/${props.match.params.siteId}/${order_id}`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        });
      }
      addToast('ステータスを更新しました。', { appearance: 'success', autoDismiss: true });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      conditionActions.setIsReload(true);
      await refreshOrder();
      setLockData(false);
    }
  }

  // ヘッダーソートイベント
  const handleHeaderClick = (column) => {
    var _sortOrder = JSON.parse(JSON.stringify(condition.sortOrder));
    if (_sortOrder.map(s => s.column).includes(column)) { // 既に登録済み
      if (_sortOrder[0].column !== column) { // 先頭ではない
        _sortOrder.splice(_sortOrder.findIndex(s => s.column === column), 1); //削除
        _sortOrder.unshift({ column, order: 'ASC' }); // 追加
      } else { //先頭 並び順変更
        _sortOrder[0].order = _sortOrder[0].order === 'ASC' ? 'DESC' : 'ASC';
      }
    } else { //未登録
      _sortOrder.unshift({ column, order: 'ASC' }); // 追加
    }
    if (_sortOrder.length > 3) { //ソート順は3件まで
      _sortOrder.pop();
    }
    conditionActions.setSortOrder(_sortOrder);
    // var data = orderData.slice();
    // data.sort((a, b) => sortData(a, b));
    // setOrderData(data);
  }

  const handleChangeCell = (index, key) => (event) => {
    const _orderData = [...orderData];
    _orderData[index] = { ..._orderData[index], [key]: event.target.value, updated: true };
    console.log(_orderData);
    setOrderData(_orderData);
  }

  const handleBatchUpdate = async (status) => {
    const jwt = localStorage.getItem('jwt');
    const params = {
      order_list: orderData.filter(o => o.updated).map(o => ({
        site_id: props.match.params.siteId,
        order_id: o.order_id,
        tracking_no: o.tracking_no,
        status: (status && o.status !== status) ? status : null
      }))
    };
    console.log(params);
    if (window.confirm(`以下の注文番号を発送済みに更新します。よろしいですか？\n[${orderData.filter(o => o.updated).map(o => o.receipt_num).join(', ')}]`)) {
      try {
        setLockData(true);
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/order/batch_update/status/${props.match.params.siteId}/${orderData.order_id}`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        });
        addToast('更新しました。', { appearance: 'success', autoDismiss: true });
      } catch (err) {
        if (err.response.data !== null) {
          addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
        } else {
          addToast(err.response, { appearance: 'error', autoDismiss: true });
        }
      } finally {
        conditionActions.setIsReload(true);
        await refreshOrder();
        setLockData(false);
      }
    }
  }

  const handleChangeFile = (e) => {
    const value = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      // console.log(reader.result);
      const codes = new Uint8Array(reader.result);
      // console.log(codes);
      const encoding = Encoding.detect(codes);
      // console.log(encoding);
      const csv = Encoding.convert(codes, {
        to: 'UNICODE',
        from: encoding,
        type: 'string',
      });
      // console.log(csv);
      const imp = csv.split('\n').filter(txt => txt.length > 0).map((row) => row.split(','));
      // console.log(imp);
      setImportData(imp);
      setModalPage(COL.trackingNo);
    }
    reader.readAsArrayBuffer(value);
  }

  const clearImportData = () => {
    setImportData(null);
    // setInitialRowIgnored(false);
    // setColTrackingNo(null);
    // setColOrderNo(null);
    // setColDeliveryTel1(null);
    // setColDeliveryTel2(null);
    // setColDeliveryTel3(null);
    setModalPage(null);
    setColumns({});
    setImportResult(null);
  }

  const handleBackClick = () => {
    switch (modalPage) {
      case COL.trackingNo:
        setImportData(null);
        setModalPage(null);
        break;
      case COL.orderNo:
        setColumns({ ...columns, trackingNo: null });
        setModalPage(COL.trackingNo);
        break;
      case COL.orderPrefix:
        setColumns({ ...columns, orderNo: null });
        setModalPage(COL.orderNo);
        break;
      case COL.deliveryTel1:
        setColumns({ ...columns, orderNo: null });
        setModalPage(COL.orderNo);
        break;
      case COL.deliveryTel2:
        setColumns({ ...columns, deliveryTel1: null });
        setModalPage(COL.deliveryTel1);
        break;
      case COL.deliveryTel3:
        setColumns({ ...columns, deliveryTel2: null });
        setModalPage(COL.deliveryTel2);
        break;
      case COL.deliveryConfirm:
        setColumns({ ...columns, deliveryTel3: null });
        setModalPage(COL.deliveryTel3);
        break;
      default:
        break;
    }
  }

  const handleColClick = (idx) => {
    setColumns({ ...columns, [modalPage]: idx });
    switch (modalPage) {
      case COL.trackingNo:
        setModalPage(COL.orderNo);
        break;
      case COL.orderNo:
        setModalPage(COL.orderPrefix);
        break;
      case COL.deliveryTel1:
        setModalPage(COL.deliveryTel2);
        break;
      case COL.deliveryTel2:
        setModalPage(COL.deliveryTel3);
        break;
      case COL.deliveryTel3:
        setModalPage(COL.deliveryConfirm);
        break;
      default:
        break;
    }
    console.log({ ...columns, [modalPage]: idx });
  }

  const handleSkipClick = () => {
    setColumns({ ...columns, [modalPage]: -1 });
    switch (modalPage) {
      case COL.orderNo:
        setModalPage(COL.deliveryTel1);
        break;
      case COL.deliveryTel2:
        setColumns({ ...columns, [modalPage]: -1, deliveryTel3: -1 });
        setModalPage(COL.deliveryConfirm);
        break;
      case COL.deliveryTel3:
        setModalPage(COL.deliveryConfirm);
        break;
      default:
        break;
    }
  }

  const handleExecClick = () => {
    const notFound = [];
    const duplicated = [];
    const _orderData = [...orderData];
    importData.forEach((row, rowIdx) => {
      if (columns.initialRowIgnored && rowIdx <= 0) {
        return;
      }
      const matched = getMatchedOrder(row, _orderData);
      if (matched.length <= 0) { // 該当なし
        notFound.push(row);
      } else if (matched.length > 1) { // 重複データあり
        duplicated.push({ row: row, data: matched });
      } else { // 1件一致
        matched[0].tracking_no = row[columns.trackingNo];
        matched[0].updated = true;
      }
    });
    console.log(notFound);
    console.log(duplicated);
    setOrderData(_orderData);
    setImportResult({ notFound, duplicated });
    setModalPage(COL.importResult);
  }

  const handleSelectClick = (dataIdx, order_id) => {
    const _importResult = { ...importResult };
    const _orderData = [...orderData];
    _importResult.duplicated[dataIdx].selected = order_id;
    _orderData.find(o => o.order_id === order_id).tracking_no = _importResult.duplicated[dataIdx].row[columns.trackingNo];
    _orderData.find(o => o.order_id === order_id).updated = true;
    setOrderData(_orderData);
    setImportResult(_importResult);
  }

  const handleExportPaymentClick = async () => {
    const jwt = localStorage.getItem('jwt');
    const params = {
      site_id: props.match.params.siteId,
      id: orderData.map(o => o.order_id)
    };
    try {
      setLockData(true);
      setCsvPaymentData(null);
      const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/payment_search/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })).data;
      console.log(data);
      if (data.status === 'success') {
        const list = [];
        const head = [];
        head.push({ label: "注文番号", key: "receipt_num" });
        head.push({ label: "お客様名", key: "customer_name" });
        head.push({ label: "カナ", key: "customer_kana" });
        head.push({ label: "受取"+Common.getSettingValue(settings, 'SHOP_NAME', '店舗')+"ID", key: "receive_shop" });
        head.push({ label: "受取"+Common.getSettingValue(settings, 'SHOP_NAME', '店舗'), key: "receive_shop_name" });
        head.push({ label: "企画ID", key: "plan_id" });
        head.push({ label: "企画名", key: "plan_name" });
        head.push({ label: "注文日", key: "ins_date" });
        head.push({ label: "受取日", key: "receive_date" });
        head.push({ label: "種別", key: "order_type" });
        head.push({ label: "小計", key: "subtotal" });
        head.push({ label: "割引額", key: "discount" });
        head.push({ label: "税額", key: "tax" });
        head.push({ label: "税込総額", key: "total" });
        head.push({ label: "商品CD", key: "product_code" });
        head.push({ label: "商品名", key: "product_name" });
        head.push({ label: "商品単価", key: "price" });
        head.push({ label: "商品税率", key: "tax_rate" });
        head.push({ label: "商品早割割引対象", key: "early_discount" });
        head.push({ label: "商品注文数", key: "count" });
        head.push({ label: "ステータス", key: "status" });
        head.push({ label: "取引ID", key: "actual_id" });
        head.push({ label: "決済代行会社", key: "gateway" });
        head.push({ label: "利用金額", key: "amount" });
        head.push({ label: "手数料等", key: "fee" });
        head.push({ label: "決済手段", key: "payment_type" });
        head.push({ label: "処理状況", key: "job_cd" });
        head.push({ label: "処理状況名称", key: "job_cd_name" });
        head.push({ label: "更新日時", key: "upd_date" });
        data.payment.forEach(p => {
          const order = orderData.find(o => String(o.order_id) === p.id);
          var payment = {
            receipt_num: order.receipt_num,
            customer_name: order.customer_name.replace(/"/g, '""'),
            customer_kana: order.customer_kana.replace(/"/g, '""'),
            receive_shop: order.receive_shop,
            receive_shop_name: order.receive_shop_name.replace(/"/g, '""'),
            plan_id: order.plan_id,
            plan_name: order.plan_name.replace(/"/g, '""'),
            ins_date: moment(order.ins_date).format("YYYY/MM/DD HH:mm:ss"),
            receive_date: order.receive_date,
            actual_id: p.actual_id,
            gateway: p.gateway,
            amount: p.amount,
            fee: p.fee,
            payment_type: p.payment_type,
            job_cd: p.job_cd,
            job_cd_name: JobCdName[p.job_cd],
            upd_date: moment(p.upd_date).format("YYYY/MM/DD HH:mm:ss"),
            status: constClass.STATUS_NAME[order.status],
            order_type: order.order_type,
            subtotal: Math.floor(order.subtotal).toLocaleString(),
            discount: Math.floor(order.discount).toLocaleString(),
            tax: Math.floor(order.tax).toLocaleString(),
            total: Math.floor(order.total).toLocaleString(),
            product_code: null,
            product_name: null,
            early_discount: null,
            price_before_tax: null,
            price: null,
            tax_rate: null,
            count: 0,
          };
          order.t_order_detail.forEach(d => {
            const detail = {
              ...payment,
              product_code: d.product_code,
              product_name: d.product_name.replace(/"/g, '""'),
              early_discount: d.early_discount === constClass.EARLY_DISCOUNT.ON ? "対象" : "対象外",
              price_before_tax: d.price_before_tax,
              price: d.price,
              tax_rate: d.tax_rate,
              count: d.count
            }
            list.push(detail);
          });
        });

        setCsvPaymentData({ header: head, list: list });
        paymentDataRef.current.link.click();
      } else {
        addToast(data.msg, { appearance: 'error', autoDismiss: true });
      }
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      setLockData(false);
    }
  }

  const handleDeliveryCheckClick = async (order_id) => {
    const jwt = localStorage.getItem('jwt');
    var order = orderData.find(o => o.order_id === order_id);
    // ADMIN権限を持たないユーザは操作できない
    if (user.userClass !== constClass.CLASS.ADMIN) {
      window.alert(`本部ユーザのみチェック可能です。`);
      return;
    }
    // 確認フラグを反転させる
    const delivery_check_flag = order.delivery_check_flag === constClass.FLAG.ON ? constClass.FLAG.OFF : constClass.FLAG.ON;
    // 確認フラグを外す場合は確認
    if (delivery_check_flag === constClass.FLAG.OFF && !window.confirm(`注文番号[${order.receipt_num}]の本部確認フラグを解除します。よろしいですか？`)) {
      return;
    }
    const params = {
      delivery_check_flag,
    };
    try {
      setLockData(true);
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/order/flag_update/${props.match.params.siteId}/${order_id}`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
      addToast('本部確認フラグを更新しました。', { appearance: 'success', autoDismiss: true });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      conditionActions.setIsReload(true);
      await refreshOrder();
      setLockData(false);
    }
  }

  const displayModalHeader = () => {
    switch (modalPage) {
      case COL.trackingNo:
        return '伝票番号の列を選択してください。';
      case COL.orderNo:
        return '注文番号の列を選択してください。';
      case COL.orderPrefix:
        return '紐付け条件を確認してください。';
      case COL.deliveryTel1:
      case COL.deliveryTel2:
      case COL.deliveryTel3:
        return '配送先の電話番号の列を選択してください。複数ある場合は先頭から順に選択してください。';
      case COL.deliveryConfirm:
        return '紐付け条件を確認してください。';
      case COL.importResult:
        return `紐付けが完了しました。${importResult && importResult.duplicated.length > 0 ? '重複データは該当の注文データを選択してください。' : ''}${importResult && importResult.notFound.length > 0 ? '一致しない伝票番号は手動で入力してください。' : ''}`;
      default:
        return '';
    }
  }

  const getDeliveryTel = (row) => {
    return row[columns.deliveryTel1] + ((columns.deliveryTel2 !== null && columns.deliveryTel2 > -1) ? row[columns.deliveryTel2] : '') + ((columns.deliveryTel3 !== null && columns.deliveryTel3 > -1) ? row[columns.deliveryTel3] : '');
  }

  const getMatchedOrder = (row, order = orderData) => {
    if (modalPage === COL.orderPrefix) {
      return order.filter(o => o.receipt_num === Number(row[columns.orderNo].replace(columns.orderPrefix, '')).toString());
    } else if (modalPage === COL.deliveryConfirm) {
      return order.filter(o => o.delivery_tel.replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '') === getDeliveryTel(row).replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, ''));
    }
  }

  // 初期実行トリガー
  useEffect(() => {
    refreshMaster();
  }, [refreshMaster]);

  // 初期実行トリガー
  useEffect(() => {
    refreshOrder();
  }, [refreshOrder]);

  const renderButton = (data, status, statusName, additionalStatus = []) => {
    additionalStatus.push(status);
    statusName = [constClass.STATUS_NAME.FIN].includes(statusName) && !additionalStatus.includes(data.status) ? '受取' : statusName;
    statusName = [constClass.STATUS_NAME.DFIN].includes(statusName) && !additionalStatus.includes(data.status) ? '発送' : statusName;
    return (
      <button type="button"
        disabled={lockData}
        className={`btn ${additionalStatus.includes(data.status) ? 'btn-danger' : (constClass.ACTIVE_BUTTONS[data.status].includes(status) ? 'btn-primary' : 'btn-secondary')} mx-1`}
        onClick={() => handleStatusButtonClick(data.order_id, status)}>
        {statusName}
      </button>
    )
  }

  const renderSortTh = (column) =>
    <th className="text-center align-middle like-button p-0 m-0" onClick={() => handleHeaderClick(column)}>
      {condition.sortOrder.filter(s => s.column === column).map((item, idx, self) =>
        <span key={idx}>{item.order === 'ASC' ? '▲' : '▼'}{condition.sortOrder.findIndex(s => s.column === column) + 1}</span>
      )}
    </th>

  const renderDetailPrint = () => {
    return (
      <BackyardOrderDetailPrint
        settings={settings}
        order={orderData}
        timeMaster={timeMaster}
        sortData={sortData}
        setPrintModal={setPrintModal}
      />
    );
  }

  const renderImport = () => {
    return (
      <div className="modal" id="exampleModal">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              伝票番号CSVアップロード
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => clearImportData()}><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
              {!importData && <input type="file" className="form-control-file" name="csv" id="csv" value="" onChange={handleChangeFile} onClick={e => (e.target.value = null)} />}
              {(importData && importData.length < 1) &&
                <div>
                  <span>データが見つかりません。</span>
                  <button type="button" className="btn btn-secondary" onClick={() => setImportData(null)}>戻る</button>
                </div>
              }
              {(importData && importData.length > 0) &&
                <div className="container-fluid">
                  <div className="row">
                    <div className="col">
                      <h5>{displayModalHeader()}</h5>
                    </div>
                  </div>
                  {![COL.importResult].includes(modalPage) &&
                    <div className="row">
                      <div className="col">
                        <input type="checkbox" id="initialRowIgnored" name="initialRowIgnored" checked={columns.initialRowIgnored} onChange={(e) => setColumns({ ...columns, initialRowIgnored: e.target.checked })}></input>
                        <label htmlFor="initialRowIgnored">最初の1行は取り込まない</label>
                      </div>
                    </div>
                  }
                  {![COL.orderPrefix, COL.deliveryConfirm, COL.importResult].includes(modalPage) &&
                    <div className="row">
                      <div className="col table-responsive">
                        <table className="table table-sm table-bordered">
                          <thead>
                            <tr>
                              {importData[0].map((data, idx) =>
                                <td key={'header_' + idx}>
                                  {![columns.trackingNo, columns.orderNo, columns.deliveryTel1, columns.deliveryTel2, columns.deliveryTel3].includes(idx) &&
                                    <button type="button" className="btn btn-primary" onClick={() => handleColClick(idx)}>選択</button>
                                  }
                                  {idx === columns.trackingNo &&
                                    <span>伝票番号</span>
                                  }
                                  {idx === columns.orderNo &&
                                    <span>注文番号</span>
                                  }
                                  {idx === columns.deliveryTel1 &&
                                    <span>配送先電話番号1</span>
                                  }
                                  {idx === columns.deliveryTel2 &&
                                    <span>配送先電話番号2</span>
                                  }
                                  {idx === columns.deliveryTel3 &&
                                    <span>配送先電話番号3</span>
                                  }
                                </td>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {importData.filter((row, rowIdx) => rowIdx < 5).map((row, rowIdx) =>
                              <tr key={rowIdx}>
                                {row.map((data, idx) =>
                                  <td key={rowIdx + '_' + idx} className={`text-nowrap ${(rowIdx === 0 && columns.initialRowIgnored) ? 'bg-light' : ''}`}>{data}</td>
                                )}
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  }
                  {[COL.orderPrefix, COL.deliveryConfirm].includes(modalPage) &&
                    <React.Fragment>
                      {modalPage === COL.orderPrefix &&
                        <div className="row">
                          <div className="col">
                            <label htmlFor="orderPrefix">注文番号から除外する文字</label>
                            <input type="text" id="orderPrefix" name="orderPrefix" value={columns.orderPrefix} onChange={(e) => setColumns({ ...columns, orderPrefix: e.target.value })}></input>
                          </div>
                        </div>
                      }
                      <div className="row">
                        <div className="col table-responsive">
                          <table className="table table-sm table-bordered">
                            <thead>
                              <tr className="bg-light">
                                <td colSpan={2}>CSVデータ</td>
                                <td colSpan={3}>注文データ</td>
                              </tr>
                              <tr className="bg-light">
                                <td>伝票番号</td>
                                <td>{modalPage === COL.orderPrefix ? '注文番号' : '配送先電話番号'}</td>
                                <td>注文番号</td>
                                <td>配送先電話番号</td>
                                <td>配送先情報</td>
                              </tr>
                            </thead>
                            <tbody>
                              {importData.filter((row, rowIdx) => rowIdx < 5).map((row, rowIdx) =>
                              ((!columns.initialRowIgnored || rowIdx > 0) &&
                                <tr key={rowIdx}>
                                  {/* 伝票番号 */}
                                  <td>{row[columns.trackingNo]}</td>
                                  {/* 注文番号 or 配送先電話番号 */}
                                  {modalPage === COL.orderPrefix && <td>{Number(row[columns.orderNo].replace(columns.orderPrefix, ''))}({row[columns.orderNo]})</td>}
                                  {modalPage === COL.deliveryConfirm && <td>{getDeliveryTel(row)}</td>}
                                  {/* 注文番号 or 配送先電話番号 */}
                                  <td>{getMatchedOrder(row).length > 0 ? getMatchedOrder(row)[0].receipt_num : ''}</td>
                                  <td>{getMatchedOrder(row).length > 0 ? getMatchedOrder(row)[0].delivery_tel : ''}</td>
                                  {/* 配送先情報 */}
                                  <td>
                                    <div className="row p-0 m-1">
                                      <div className="col text-left align-self-center py-0 pl-0 pr-1 m-0">{getMatchedOrder(row).length > 0 ? getMatchedOrder(row)[0].delivery_name : ''}</div>
                                    </div>
                                    <div className="row p-0 m-1">
                                      <div className="col text-left align-self-center  p-0 m-0">{getMatchedOrder(row).length > 0 ? getMatchedOrder(row)[0].delivery_address : ''}</div>
                                    </div>
                                  </td>
                                </tr>
                              )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </React.Fragment>
                  }
                  {[COL.importResult].includes(modalPage) &&
                    <React.Fragment>
                      {(importResult && importResult.duplicated.length > 0) &&
                        <div className="row">
                          <div className="col table-responsive">
                            <table className="table table-sm table-bordered">
                              <thead>
                                <tr className="table-warning">
                                  <td colSpan={8}>重複データリスト</td>
                                </tr>
                                <tr className="bg-light">
                                  <td colSpan={2}>CSVデータ</td>
                                  <td colSpan={6}>注文データ</td>
                                </tr>
                                <tr className="bg-light">
                                  <td>伝票番号</td>
                                  {(columns.orderNo !== null && columns.orderNo > -1) && <td>注文番号</td>}
                                  {(columns.deliveryTel1 !== null && columns.deliveryTel1 > -1) && <td>配送先電話番号</td>}
                                  <td>確定</td>
                                  <td>注文番号</td>
                                  <td>配送先電話番号</td>
                                  <td>伝票番号</td>
                                  <td>配送先情報</td>
                                </tr>
                              </thead>
                              <tbody>
                                {importResult.duplicated.map((data, dataIdx) =>
                                  <React.Fragment>
                                    <tr>
                                      {/* 伝票番号 */}
                                      <td rowSpan={data.data.length}>{data.row[columns.trackingNo]}</td>
                                      {/* 注文番号 */}
                                      {(columns.orderNo !== null && columns.orderNo > -1) && <td rowSpan={data.data.length}>{Number(data.row[columns.orderNo].replace(columns.orderPrefix, ''))}({data.row[columns.orderNo]})</td>}
                                      {/* 配送先電話番号 */}
                                      {(columns.deliveryTel1 !== null && columns.deliveryTel1 > -1) && <td rowSpan={data.data.length}>{getDeliveryTel(data.row)}</td>}
                                      {/* 確定ボタン */}
                                      <td>
                                        {data.selected === data.data[0].order_id && <span>選択済み</span>}
                                        {data.selected !== data.data[0].order_id && <button type="button" className="btn btn-primary" onClick={() => handleSelectClick(dataIdx, data.data[0].order_id)}>選択</button>}
                                      </td>
                                      {/* 注文番号 */}
                                      <td>{data.data[0].receipt_num}</td>
                                      {/* 配送先電話番号 */}
                                      <td>{data.data[0].delivery_tel}</td>
                                      {/* 伝票番号 */}
                                      <td>{data.data[0].tracking_no}</td>
                                      {/* 配送先情報 */}
                                      <td>
                                        <div className="row p-0 m-1">
                                          <div className="col text-left align-self-center py-0 pl-0 pr-1 m-0">{data.data[0].delivery_name}</div>
                                        </div>
                                        <div className="row p-0 m-1">
                                          <div className="col text-left align-self-center  p-0 m-0">{data.data[0].delivery_address}</div>
                                        </div>
                                      </td>
                                    </tr>
                                    {data.data.map((d, idx) =>
                                    ((idx > 0) &&
                                      <tr>
                                        {/* 確定ボタン */}
                                        <td>
                                          {data.selected === d.order_id && <span>選択済み</span>}
                                          {data.selected !== d.order_id && <button type="button" className="btn btn-primary" onClick={() => handleSelectClick(dataIdx, d.order_id)}>選択</button>}
                                        </td>
                                        {/* 注文番号 */}
                                        <td>{d.receipt_num}</td>
                                        {/* 配送先電話番号 */}
                                        <td>{d.delivery_tel}</td>
                                        {/* 伝票番号 */}
                                        <td>{d.tracking_no}</td>
                                        {/* 配送先情報 */}
                                        <td>
                                          <div className="row p-0 m-1">
                                            <div className="col text-left align-self-center py-0 pl-0 pr-1 m-0">{d.delivery_name}</div>
                                          </div>
                                          <div className="row p-0 m-1">
                                            <div className="col text-left align-self-center  p-0 m-0">{d.delivery_address}</div>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                    )}
                                  </React.Fragment>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      }
                      {(importResult && importResult.notFound.length > 0) &&
                        <div className="row">
                          <div className="col table-responsive">
                            <table className="table table-sm table-bordered">
                              <thead>
                                <tr className="table-info">
                                  <td colSpan={2}>不一致データリスト</td>
                                </tr>
                                <tr className="bg-light">
                                  <td colSpan={2}>CSVデータ</td>
                                </tr>
                                <tr className="bg-light">
                                  <td>伝票番号</td>
                                  {(columns.orderNo !== null && columns.orderNo > -1) && <td>注文番号</td>}
                                  {(columns.deliveryTel1 !== null && columns.deliveryTel1 > -1) && <td>配送先電話番号</td>}
                                </tr>
                              </thead>
                              <tbody>
                                {importResult.notFound.map((row, rowIdx) =>
                                  <React.Fragment>
                                    <tr>
                                      {/* 伝票番号 */}
                                      <td>{row[columns.trackingNo]}</td>
                                      {/* 注文番号 */}
                                      {(columns.orderNo !== null && columns.orderNo > -1) && <td>{Number(row[columns.orderNo].replace(columns.orderPrefix, ''))}({row[columns.orderNo]})</td>}
                                      {/* 配送先電話番号 */}
                                      {(columns.deliveryTel1 !== null && columns.deliveryTel1 > -1) && <td>{getDeliveryTel(row)}</td>}
                                    </tr>
                                  </React.Fragment>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      }
                    </React.Fragment>
                  }
                  <div className="row">
                    {(![COL.importResult].includes(modalPage)) &&
                      <div className="col-auto">
                        <button type="button" className="btn btn-secondary" onClick={() => handleBackClick()}>戻る</button>
                      </div>
                    }
                    {([COL.orderNo, COL.deliveryTel2, COL.deliveryTel3].includes(modalPage)) &&
                      <div className="col-auto">
                        <button type="button" className="btn btn-secondary" onClick={() => handleSkipClick()}>スキップ</button>
                      </div>
                    }
                    {([COL.orderPrefix, COL.deliveryConfirm].includes(modalPage)) &&
                      <div className="col-auto">
                        <button type="button" className="btn btn-primary" onClick={() => handleExecClick()}>確定</button>
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => clearImportData()}>Close</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(orderData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {orderData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {orderData !== null && (
        <div ref={componentRef} className="print-list">
          <div className="row mb-0 p-0 ">
            <div className="col-9 pl-0 text-left align-self-end">
              {props.condition.shop && orderData[0] && <div className="row"><div className="col-12"><h4>{orderData[0].receive_shop_name}</h4></div></div>}
              {props.condition.plan && orderData[0] && <div className="row"><div className="col-12"><h4>{orderData[0].plan_name}</h4></div></div>}
              <div className="row">
                <div className="col-2 pr-2">
                  <ReactToPrint
                    trigger={() => (
                      <button type="button" className="btn btn-primary print-none px-0 mb-1 w-100"
                        disabled={lockData || orderData.length < 1}
                      >
                        一覧印刷
                      </button>
                    )}
                    content={() => componentRef.current}
                    pageStyle={""}
                  />
                </div>
                <div className="col-2 px-1">
                  <button
                    className="btn btn-primary print-none mb-1 px-0 w-100"
                    disabled={lockData || orderData.length < 1}
                    data-toggle="modal" data-target="#detailprintModal"
                    onClick={() => setPrintModal(true)}>
                    明細印刷
                  </button>
                </div>
                <div className="col-2 px-1">
                  {csvData &&
                    <CSVLink className="btn btn-primary print-none mb-1 px-0 w-100" data={csvData.list.sort((a, b) => sortData(a, b))} headers={csvData.header} filename={"注文データ.csv"}>
                      データ出力
                    </CSVLink>
                  }
                </div>
                <div className="col-2 px-1">
                  {csvDetailData &&
                    <CSVLink className="btn btn-primary print-none mb-1 px-0 w-100" data={csvDetailData.list.sort((a, b) => sortData(a, b))} headers={csvDetailData.header} filename={"注文明細データ.csv"}>
                      明細データ出力
                    </CSVLink>
                  }
                </div>
                <div className="col-2 px-1">
                  {(user.userClass === constClass.CLASS.ADMIN || user.userClass === constClass.CLASS.READER) &&
                    <React.Fragment>
                      <button
                        className="btn btn-primary print-none mb-1 px-0 w-100"
                        disabled={lockData || orderData.length < 1}
                        onClick={() => handleExportPaymentClick()}>
                        決済データ出力
                      </button>
                      {csvPaymentData && <CSVLink data={csvPaymentData.list} headers={csvPaymentData.header} filename={"決済データ.csv"} className="d-none" ref={paymentDataRef} />}
                    </React.Fragment>
                  }
                </div>
                {(orderData && props.delivery_flag === constClass.FLAG.ON && user.userClass !== constClass.CLASS.READER) &&
                  <React.Fragment>{/* <div className="col-1"></div> */}
                    <div className="col-2 px-1">
                      <button
                        className="btn btn-primary print-none mb-1 px-0 w-100"
                        disabled={lockData || orderData.length < 1}
                        data-toggle="modal" data-target="#exampleModal">
                        伝票番号取込
                      </button>
                    </div>
                  </React.Fragment>
                }
              </div>
            </div>
            <div className="col-3 m-0 p-0">
              <table className="table-borderd table-striped mt-0 mr-0 mb-0 ml-auto p-0">
                <tbody>
                  <tr>
                    <th className="p-0">注文件数</th>
                    <td className="text-right p-0">{orderCount.toLocaleString()} 件</td>
                  </tr>
                  <tr>
                    <th className="p-0">1人当たりの注文点数</th>
                    <td className="text-right p-0">{(Math.round((sumCount / orderCount) * Math.pow(10, 1)) / Math.pow(10, 1)).toLocaleString()} 個</td>
                  </tr>
                  <tr>
                    <th className="p-0">1人当たりの注文単価</th>
                    <td className="text-right p-0">{Math.round((sumTotal / orderCount)).toLocaleString()} 円</td>
                  </tr>
                  <tr>
                    <th className="p-0">合計点数</th>
                    <td className="text-right p-0">{sumCount.toLocaleString()} 個</td>
                  </tr>
                  <tr>
                    <th className="p-0">税込合計金額</th>
                    <td className="text-right p-0">{sumTotal.toLocaleString()} 円</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mb-3 p-0">
            <div className="col-12 p-0">
              <table className="table table-bordered table-striped" height="1">
                <thead className={`table-${constClass.COLOR[user.userClass]}`}>
                  <tr>
                    <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('receipt_num')}>
                      注文<br />番号
                    </th>
                    <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('employee_flag')}>
                      従<br />業<br />員
                    </th>
                    {props.delivery_flag !== constClass.FLAG.ON &&
                      <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('customer_name')}>
                        お客様名
                      </th>
                    }
                    {props.delivery_flag !== constClass.FLAG.ON &&
                      <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('customer_kana')}>
                        カナ
                      </th>
                    }
                    {(!props.condition.shop && props.delivery_flag !== constClass.FLAG.ON) && (
                      <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('receive_shop')}>
                        受取{Common.getSettingValue(settings, 'SHOP_NAME', '店舗')}
                      </th>
                    )}
                    {!props.condition.plan && (
                      <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('plan_id')}>
                        企画
                      </th>
                    )}
                    <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('receive_date')}>
                      受取日
                    </th>
                    {props.delivery_flag !== constClass.FLAG.ON &&
                      <th className="text-center text-nowrap align-middle like-button" onClick={() => handleHeaderClick('receive_time')}>
                        受取時間帯
                      </th>
                    }
                    {props.delivery_flag === constClass.FLAG.ON &&
                      <th className="text-center text-nowrap align-middle like-button" onClick={() => handleHeaderClick('customer_postal_code')}>
                        依頼主情報
                      </th>
                    }
                    {props.delivery_flag === constClass.FLAG.ON &&
                      <th className="text-center text-nowrap align-middle like-button" onClick={() => handleHeaderClick('delivery_postal_code')}>
                        配送先情報
                      </th>
                    }
                    <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('order_type')}>
                      種別
                    </th>
                    <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('total')}>
                      税込総額
                    </th>
                    <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('t_order_detail')}>
                      注文明細
                    </th>
                    <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('payment_flag')}>
                      事前<br />決済
                    </th>
                    <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('status')}>
                      ステータス
                    </th>
                    {Common.getSettingValue(settings, 'ORDER_NOTE', '') === '1' &&
                      <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('order_note')}>
                        備考
                      </th>
                    }
                    <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('manager_note')}>
                      管理者備考
                    </th>
                    {props.delivery_flag === constClass.FLAG.ON &&
                      <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('delivery_check_flag')}>
                        本部確認
                      </th>
                    }
                    {props.delivery_flag === constClass.FLAG.ON &&
                      <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('tracking_no')}>
                        伝票番号
                      </th>
                    }
                    {user.userClass !== constClass.CLASS.READER &&
                      <th className="text-center align-middle print-none">
                        処理
                      </th>
                    }
                  </tr>
                  <tr className="print-none">
                    {renderSortTh('receipt_num')}
                    {renderSortTh('employee_flag')}
                    {props.delivery_flag !== constClass.FLAG.ON && renderSortTh('customer_name')}
                    {props.delivery_flag !== constClass.FLAG.ON && renderSortTh('customer_kana')}
                    {(!props.condition.shop && props.delivery_flag !== constClass.FLAG.ON) && (renderSortTh('receive_shop'))}
                    {!props.condition.plan && (renderSortTh('plan_id'))}
                    {renderSortTh('receive_date')}
                    {props.delivery_flag !== constClass.FLAG.ON && renderSortTh('receive_time')}
                    {props.delivery_flag === constClass.FLAG.ON && renderSortTh('customer_postal_code')}
                    {props.delivery_flag === constClass.FLAG.ON && renderSortTh('delivery_postal_code')}
                    {renderSortTh('order_type')}
                    {renderSortTh('total')}
                    {renderSortTh('t_order_detail')}
                    {renderSortTh('payment_flag')}
                    {renderSortTh('status')}
                    {Common.getSettingValue(settings, 'ORDER_NOTE', '') === '1' && renderSortTh('order_note')}
                    {renderSortTh('manager_note')}
                    {props.delivery_flag === constClass.FLAG.ON && renderSortTh('delivery_check_flag')}
                    {props.delivery_flag === constClass.FLAG.ON && renderSortTh('tracking_no')}
                    {user.userClass !== constClass.CLASS.READER &&
                      <th className="text-center align-middle print-none">
                      </th>
                    }
                  </tr>
                </thead>
                <tbody>
                  {orderData.sort((a, b) => sortData(a, b)).map((data, idx) => (
                    <tr key={data.order_id} className={`${data.updated ? 'table-updated' : ''}`}>
                      {/* 注文番号 */}
                      <td className="text-center align-middle p-1">
                        {data.receipt_num}
                      </td>
                      {/* 従業員 */}
                      <td className="text-center align-middle p-1">
                        {data.employee_flag === '1' ? '○' : ''}
                      </td>
                      {/* お客様名 */}
                      {props.delivery_flag !== constClass.FLAG.ON &&
                        <td className="text-center align-middle p-1">
                          <Link to={`${generatePath(`${props.match.path}order/input/${data.order_id}`, { siteId: props.match.params.siteId })}`}>{data.customer_name}</Link>
                        </td>
                      }
                      {/* カナ */}
                      {props.delivery_flag !== constClass.FLAG.ON &&
                        <td className="text-center align-middle p-1">
                          {data.customer_kana}<br />
                        </td>
                      }
                      {/* 受取店舗 */}
                      {(!props.condition.shop && props.delivery_flag !== constClass.FLAG.ON) && (
                        <td className="text-center align-middle p-1">
                          <div className="row p-0 m-1" key={data.order_id}>
                            <div className="col-auto text-left align-self-center py-0 pl-0 pr-1 m-0">{data.receive_shop}</div>
                            <div className="col text-left align-self-center  p-0 m-0">{data.receive_shop_name}</div>
                          </div>
                        </td>
                      )}
                      {/* 企画 */}
                      {!props.condition.plan && (
                        <td className="text-center align-middle p-1 text-nowrap">
                          {data.plan_name}
                        </td>
                      )}
                      {/* 受取日 */}
                      <td className="text-center align-middle p-1 text-nowrap">
                        {data.receive_date}
                      </td>
                      {/* 受取時間帯 */}
                      {props.delivery_flag !== constClass.FLAG.ON &&
                        <td className="text-center align-middle p-1 text-nowrap">
                          {timeMaster.find(t => t.time_id.toString() === data.receive_time) ? timeMaster.find(t => t.time_id.toString() === data.receive_time).time_name : ''}
                        </td>
                      }
                      {/* 依頼主情報 */}
                      {props.delivery_flag === constClass.FLAG.ON &&
                        <td className="text-center align-middle p-1">
                          <div className="row p-0 m-1">
                            <div className="col text-left align-self-center py-0 pl-0 pr-1 m-0 small">{data.customer_kana}</div>
                          </div>
                          <div className="row p-0 m-1">
                            <Link to={`${generatePath(`${props.match.path}order/input/${data.order_id}`, { siteId: props.match.params.siteId })}`}><div className="col text-left align-self-center py-0 pl-0 pr-1 m-0">{data.customer_name}</div></Link>
                          </div>
                          <div className="row p-0 m-1">
                            <div className="col text-left align-self-center py-0 pl-0 pr-1 m-0">&#x3012;{data.customer_postal_code}</div>
                          </div>
                          <div className="row p-0 m-1">
                            <div className="col text-left align-self-center  p-0 m-0">{data.customer_address}</div>
                          </div>
                          <div className="row p-0 m-1">
                            <div className="col text-left align-self-center py-0 pl-0 pr-1 m-0">&#x2121;{data.customer_tel}</div>
                          </div>
                        </td>
                      }
                      {/* 配送先情報 */}
                      {props.delivery_flag === constClass.FLAG.ON &&
                        <td className="text-center align-middle p-1">
                          <div className="row p-0 m-1">
                            <div className="col text-left align-self-center py-0 pl-0 pr-1 m-0 small">{data.delivery_kana}</div>
                          </div>
                          <div className="row p-0 m-1">
                            <div className="col text-left align-self-center py-0 pl-0 pr-1 m-0">{data.delivery_name}</div>
                          </div>
                          <div className="row p-0 m-1">
                            <div className="col text-left align-self-center py-0 pl-0 pr-1 m-0">&#x3012;{data.delivery_postal_code}</div>
                          </div>
                          <div className="row p-0 m-1">
                            <div className="col text-left align-self-center  p-0 m-0">{data.delivery_address}</div>
                          </div>
                          <div className="row p-0 m-1">
                            <div className="col text-left align-self-center py-0 pl-0 pr-1 m-0">&#x2121;{data.delivery_tel}</div>
                          </div>
                        </td>
                      }
                      {/* 種別 */}
                      <td className="text-center align-middle p-1">
                        {data.order_type}
                      </td>
                      {/* 税込総額 */}
                      <td className="text-right align-middle p-1">
                        {Math.floor(data.total).toLocaleString()}
                      </td>
                      {/* 注文明細 */}
                      <td className="text-center align-middle p-0">
                        {data.t_order_detail.sort((a, b) => a.product_id - b.product_id).map((detail, detail_idx) => (
                          <div className="row p-0 m-1" key={data.order_id + '_' + detail.product_id}>
                            <div className="col-auto text-left align-self-center py-0 pl-0 pr-1 m-0">{detail.product_code}</div>
                            <div className="col text-left align-self-center  p-0 m-0">{detail.product_name}</div>
                            <div className="col-auto  text-right align-self-center py-0 pl-1 pr-0 m-0">{detail.count}</div>
                          </div>
                        ))
                        }
                      </td>
                      {/* 事前決済 */}
                      <td className="text-center align-middle p-1">
                        {constClass.FLAG_MARK[data.payment_flag]}
                      </td>
                      {/* ステータス */}
                      <td className="text-center align-middle p-1">
                        {constClass.STATUS_NAME[data.status]}
                      </td>
                      {/* 備考 */}
                      {Common.getSettingValue(settings, 'ORDER_NOTE', '') === '1' &&
                        <td className="text-center align-middle p-1">
                          {data.t_order_detail.sort((a, b) => a.product_id - b.product_id).map((detail, detail_idx) => (
                            <div key={data.order_id + '_' + detail.product_id}>
                              {detail.order_note ? "〇" : ""}
                            </div>
                          ))}
                        </td>
                      }
                      {/* 管理者備考 */}
                      <td className="text-center align-middle p-1">
                        {data.manager_note ? "〇" : ""}
                      </td>
                      {/* 本部確認 */}
                      {props.delivery_flag === constClass.FLAG.ON &&
                        <td className="text-center align-middle p-1">
                          {user.userClass === constClass.CLASS.ADMIN &&
                            <button type="button"
                              disabled={lockData}
                              className={`btn ${(data.delivery_check_flag === constClass.FLAG.ON) ? 'btn-danger' : 'btn-primary'} mx-0 px-1`}
                              onClick={() => handleDeliveryCheckClick(data.order_id)}>
                              {(data.delivery_check_flag === constClass.FLAG.ON) ? '確認済' : '確認'}
                            </button>
                          }
                          {user.userClass !== constClass.CLASS.ADMIN &&
                            <span>{(data.delivery_check_flag === constClass.FLAG.ON) ? '確認済' : '未確認'}</span>
                          }
                        </td>
                      }
                      {/* 伝票番号 */}
                      {props.delivery_flag === constClass.FLAG.ON &&
                        <td className="text-center align-middle p-1">
                          {user.userClass === constClass.CLASS.READER &&
                            <input type="text" className="form-control" id={"tracking_no" + data.order_id} name="tracking_no[]" value={data.tracking_no} onChange={handleChangeCell(idx, 'tracking_no')} readOnly></input>
                          }
                          {user.userClass !== constClass.CLASS.READER &&
                            <input type="text" className="form-control" id={"tracking_no" + data.order_id} name="tracking_no[]" value={data.tracking_no} onChange={handleChangeCell(idx, 'tracking_no')}></input>
                          }
                        </td>
                      }
                      {/* 処理 */}
                      {user.userClass !== constClass.CLASS.READER &&
                        <td className="text-center align-middle text-nowrap p-1 print-none">
                          {data.delivery_flag !== constClass.FLAG.ON && renderButton(data, constClass.STATUS.FIN, constClass.STATUS_NAME.FIN)}
                          {data.delivery_flag === constClass.FLAG.ON && renderButton(data, constClass.STATUS.DFIN, constClass.STATUS_NAME.DFIN)}
                          {renderButton(data, constClass.STATUS.CCL, constClass.STATUS_NAME.CCL)}
                        </td>
                      } 
                    </tr>
                  ))
                  }
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mb-3 p-0">
            <div className="col-10 p-0">
            </div>
            <div className="col-2 p-0">

              {(orderData && props.delivery_flag === constClass.FLAG.ON && user.userClass !== constClass.CLASS.READER) &&
                <button
                  className="btn btn-primary print-none mb-1 px-0 w-100"
                  onClick={() => handleBatchUpdate(constClass.STATUS.DFIN)}
                  disabled={lockData || orderData.filter(o => o.updated).length < 1}>
                  一括発送
                </button>
              }
            </div>
          </div>
        </div>
      )}
      {renderImport()}
      {printModal && renderDetailPrint()}
    </div>
  )
}

export default BackyardOrderList;