import React, { useEffect } from 'react';
import axios from 'axios';
import constClass from '../../Constants/Constants';
import BackyardOrderShop from './BackyardOrderShop'
import BackyardOrderList from './BackyardOrderList';
import BackyardOrderProduct from './BackyardOrderProduct';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ja from 'date-fns/locale/ja';
import Select from 'react-select';
import moment from 'moment';
import BackyardOrderPaymentList from './BackyardOrderPaymentList';
import DatePicker from "react-datepicker"
import BackyardOrderDept from './BackyardOrderDept';
import Common from '../Common/common.js';

const BackyardOrderFinTab = (props) => {
  const { user, condition, conditionActions, settings } = props;
  registerLocale('ja', ja);
  var deptMaster = condition.deptMaster.find(d => d.product_dept_code === user.productDeptCode);

  // const [index, setIndex] = useState(1);
  const handleTabSelect = (idx, lastIndex, e) => {
    conditionActions.setIndex(idx);
    if (user.userClass === constClass.CLASS.SHOP) { //店舗ユーザは自店舗を設定
      conditionActions.setShop(user.shopId);
    }
  };

  // const [shopMaster, setShopMaster] = useState([]);
  // const [planMaster, setPlanMaster] = useState([]);
  // const [productMaster, setProductMaster] = useState([]);
  // const [shop, setShop] = useState('');
  // const [plan, setPlan] = useState('');
  // const [reciveDate, setReciveDate] = useState(null);
  // const [selectReciveDate, setSelectReciveDate] = useState(null);
  // const [selectStatus, setSelectStatus] = useState([{ value: constClass.STATUS.UPAY, label: constClass.STATUS_NAME.UPAY },
  // { value: constClass.STATUS.REG, label: constClass.STATUS_NAME.REG },
  // { value: constClass.STATUS.PRE, label: constClass.STATUS_NAME.PRE },
  // { value: constClass.STATUS.FIN, label: constClass.STATUS_NAME.FIN },
  // { value: constClass.STATUS.DFIN, label: constClass.STATUS_NAME.DFIN }]);
  // const [status, setStatus] = useState([constClass.STATUS.UPAY, constClass.STATUS.REG, constClass.STATUS.PRE, constClass.STATUS.FIN, constClass.STATUS.DFIN]);
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  // const [isReload, setisReload] = useState(true);

  // タブ変更イベント
  const handleChange = (event) => {
    if (event.target.name === 'shop') {
      conditionActions.setShop(event.target.value);
    } else if (event.target.name === 'plan') {
      conditionActions.setPlan(event.target.value);
      conditionActions.setSelectReceiveDate(null);
      // } else if (event.target.name === 'startdate') {
      //   setStartDate(event.target.value);
      //   if (endDate === null) setEndDate(event.target.value);
      // } else if (event.target.name === 'enddate') {
      //   setEndDate(event.target.value);
      //   if (startDate === null) setStartDate(event.target.value);
    } else if (event.target.name === 'dept') {
      conditionActions.setDept(event.target.value);
    }
  }

  // 「受取日」変更イベント（セレクト）
  const handleSelectReceiveDate = (data) => {
    if (data.length <= 0) {
      conditionActions.setSelectReceiveDate(null);
    } else {
      conditionActions.setSelectReceiveDate(data);
    }
  }

  // 「受取日」変更イベント（カレンダー）
  const handleSelectReceiveDatePicker = (data) => {
    conditionActions.setReceiveDateRange(data);
    if (data[0] && data[1]) {
      const dateArray = [];
      for (var i = new Date(data[0]); i <= data[1]; i.setDate(i.getDate() + 1)) {
        const val = moment(new Date(i)).format('YYYY/MM/DD');
        dateArray.push({value: val, lavel: val});
      }
      conditionActions.setSelectReceiveDate(dateArray.length > 0 ? dateArray : null);
    } else {
      if (!data[0] && !data[1]) {
        conditionActions.setSelectReceiveDate(null);
      }
    }
  }

  // 「ステータス」変更イベント（セレクト）
  const handleSelectStatus = (data) => {
    if (data.length <= 0) {
      conditionActions.setSelectStatus(null);
    } else {
      conditionActions.setSelectStatus(data);
    }
  }

  const handleIsReload = (data) => {
    conditionActions.setIsReload(data);
  }

  // 初期実行トリガー
  useEffect(() => {
    if (!condition.initFlag) {
      async function fetchData() {
        const jwt = localStorage.getItem('jwt');
        const reg_params = {
          "site_id": props.match.params.siteId,
          "operator": "and",
          "where": [{ "site_id": props.match.params.siteId }]
        }
        const shop = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/shop/search/`, reg_params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })).data;
        conditionActions.setShopMaster(shop);
        const plan = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/plan/search/`, reg_params, { // 企画情報取得 
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })).data;
        conditionActions.setPlanMaster(plan);
        const dept = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/productdept/search/`, reg_params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })).data;
        dept.sort((a, b) => a.product_dept_code - b.product_dept_code);
        conditionActions.setDeptMaster(dept);
        const calendarshop = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/shopcalendar/search/`, reg_params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })).data;
        conditionActions.setCalendarShopMaster(calendarshop);
        //console.log(conditionActions.deptMaster.find(d => d.product_dept_code === user.productDeptCode));
        if (plan.length > 0) {//企画が複数あったら、開催中企画を表示。なければ最新企画を表示
          const nowdate = moment().format("YYYYMMDD");
          const nowplan = plan.find((item) => item.plan_start <= nowdate && item.plan_end >= nowdate);
          const defaultplan = nowplan ? nowplan.plan_id : plan.slice(-1)[0].plan_id;
          conditionActions.setPlan(defaultplan);
        }
        if (Common.getSettingValue(settings, 'USER_DEPT', '') === '1' && (user.productDeptCode || user.productDeptCode === 0)) {
          conditionActions.setPlan("");
          const date = moment().add(Common.getSettingValue(settings, 'RECEIVE_DATA_PLUS', ''), 'days'); 
          conditionActions.setSelectReceiveDate([{ value: date.format('YYYY/MM/DD'), lavel: date.format('YYYY/MM/DD') }]);
          conditionActions.setReceiveDateRange([date.toDate(), date.toDate()]);
          conditionActions.setSelectStatus(
          [
            { value: constClass.STATUS.REG, label: constClass.STATUS_NAME.REG },
            { value: constClass.STATUS.PRE, label: constClass.STATUS_NAME.PRE },
            { value: constClass.STATUS.FIN, label: constClass.STATUS_NAME.FIN },
            { value: constClass.STATUS.DFIN, label: constClass.STATUS_NAME.DFIN },
          ]);
        }
        const product = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/product/search/`, reg_params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })).data;
        conditionActions.setProductMaster(product);
        if (user.userClass === constClass.CLASS.SHOP) { //店舗ユーザは自店舗を初期値に設定
          conditionActions.setShop(user.shopId);
        }
        conditionActions.setInitFlag();
      }
      fetchData();
      conditionActions.setDept(user.productDeptCode);
    }
    return () => {
      conditionActions.setIsReload(true);
    }
  }, [props.match.params.siteId, user.shopId, user.userClass, user.productDeptCode, conditionActions, condition.initFlag, settings]);

  return (
    <div className="container">
      <div className="row mx-0 my-3 px-1-env">
        <div className="col">
          <div className="row">
            <div className="col-3">
              {(Common.getSettingValue(settings, 'USER_DEPT', '') !== '1' || (!user.productDeptCode && user.productDeptCode !== 0 && Common.getSettingValue(settings, 'USER_DEPT', '') === '1')) &&
                <div className="row">
                  <div className="col-auto text-center align-self-center">
                    <span>{Common.getSettingValue(settings, 'SHOP_NAME', '店舗')}</span>
                  </div>
                  <div className="col text-center">
                    <select className="custom-select w-100" aria-label="店舗" name="shop" value={condition.shop} onChange={handleChange} disabled={(condition.index === 1 && user.userClass === constClass.CLASS.SHOP) || (condition.index === 2 && user.userClass === constClass.CLASS.SHOP) ? true : false}>
                      <option value="">- 全て -</option>
                      {condition.shopMaster.map((item) =>
                        <option key={item.shop_id} value={item.shop_id}>{item.shop_name}</option>
                      )}
                    </select>
                  </div>
                </div>
              }
              {Common.getSettingValue(settings, 'USER_DEPT', '') === '1' && (user.productDeptCode || user.productDeptCode === 0) &&
                <div className="row">
                  <div className="col-auto text-center align-self-center">
                    <span>{Common.getSettingValue(settings, 'ORDER_DEPT_NAME', '商品デプト')}</span>
                  </div>
                  <div className="col text-center">
                    <select className="custom-select w-100" aria-label="商品デプト" name="dept" value={condition.dept} onChange={handleChange} disabled={true}>
                      <option value={user.productDeptCode}>{deptMaster ? deptMaster.product_dept_name : ""}</option>
                    </select>
                  </div>
                </div>
              }
            </div>
            <div className="col-3">
              <div className="row">
                <div className="col-auto text-center align-self-center">
                  <span>企画</span>
                </div>
                <div className="col text-center">
                  <select className="custom-select w-100" aria-label="企画" name="plan" value={condition.plan} onChange={handleChange}>
                    <option value="">- 全て -</option>
                    {condition.planMaster.map((item) =>
                      <option key={item.plan_id} value={item.plan_id}>{item.plan_name}</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                <div className="col-auto text-center align-self-center">
                  <span>受取日</span>
                </div>
                {/* 店舗カレンダー設定が無効 and 企画選択済みand企画が通年ではない */}
                {(Common.getSettingValue(settings, 'SHOP_CALENDAR', '') !== '1' && condition.plan && condition.planMaster.find(p => p.plan_id === Number(condition.plan)) && condition.planMaster.find(p => p.plan_id === Number(condition.plan)).year_round_type === constClass.FLAG.OFF) &&
                  <div className="col text-center">
                    <Select
                      styles={{ container: (base) => ({ ...base, zIndex: 500 }) }}
                      options={condition.productMaster.filter(p => (p.plan_id === Number(condition.plan) || !condition.plan)) //productMasterをcount有りでフィルタ
                        .reduce((arr, item) => arr.concat(item.m_product_receive), []) //m_product_receiveの配列作成
                        .map(item => item.receive_date).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => parseInt(a) - parseInt(b)).map( // receive_dateの配列に変換→重複除外→オプションのDOM要素に変換
                          item => ({ value: item.substr(0, 4) + '/' + item.substr(4, 2) + '/' + item.substr(6, 2), label: item.substr(0, 4) + '/' + item.substr(4, 2) + '/' + item.substr(6, 2) })
                        )}
                      isClearable={false}
                      isMulti
                      closeMenuOnSelect={false}
                      value={condition.selectReceiveDate}
                      onChange={handleSelectReceiveDate} />
                  </div>
                }
                {/* 店舗カレンダー設定が有効 and 企画選択済みand企画が通年ではない */}
                {(Common.getSettingValue(settings, 'SHOP_CALENDAR', '') === '1' && condition.plan && condition.planMaster.find(p => p.plan_id === Number(condition.plan)) && condition.planMaster.find(p => p.plan_id === Number(condition.plan)).year_round_type === constClass.FLAG.OFF) &&
                  <div className="col text-center">
                    <Select
                      styles={{ container: (base) => ({ ...base, zIndex: 500 }) }}
                      options={condition.CalendarShopMaster
                        .map(item => item.receive_date).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => parseInt(b) - parseInt(a)).map( // receive_dateの配列に変換→重複除外→オプションのDOM要素に変換
                          item => ({ value: item.substr(0, 4) + '/' + item.substr(4, 2) + '/' + item.substr(6, 2), label: item.substr(0, 4) + '/' + item.substr(4, 2) + '/' + item.substr(6, 2) })
                        )}
                      isClearable={false}
                      isMulti
                      closeMenuOnSelect={false}
                      value={condition.selectReceiveDate}
                      onChange={handleSelectReceiveDate} />
                  </div>
                }
                {/* 企画未選択or企画が通年 */}
                {(!condition.plan || (condition.planMaster.find(p => p.plan_id === Number(condition.plan)) && condition.planMaster.find(p => p.plan_id === Number(condition.plan)).year_round_type !== constClass.FLAG.OFF)) &&
                  <div className="col text-center">
                    <DatePicker className="custom-select w-100 text-center border" aria_labal="受取日" locale="ja" dateFormat="yyyyMMdd" monthsShown="1" id="receiveDate" name="receiveDate"
                      // popperPlacement='bottom'
                      // popperModifiers={{
                      //   flip: {
                      //     behavior: ["bottom"] // don't allow it to flip to be above
                      //   },
                      // }}
                      // selected={condition.selectReceiveDate ? new Date(condition.selectReceiveDate[0].value) : null}
                      selectsRange={true}
                      startDate={condition.receiveDateRange[0]}
                      endDate={condition.receiveDateRange[1]}
                      onChange={handleSelectReceiveDatePicker}
                      placeholderText="- 選択 -"
                      isClearable={true}
                      customInput={<button>{condition.receiveDateRange[0] ? moment(new Date(condition.receiveDateRange[0])).format('MM/DD') + '-' + (condition.receiveDateRange[1] ? moment(new Date(condition.receiveDateRange[1])).format('MM/DD') : '') : "- 選択 -"}</button>}
                    />
                  </div>
                }
              </div>
            </div>
            {condition.index === 0 &&
              <div className="col-2"></div>
            }
            {(condition.index === 1 || condition.index === 2 || condition.index === 3 || condition.index === 4 || condition.index === 5) &&
              <div className="col-2">
                <div className="row">
                  <div className="col text-center">
                    <Select
                      styles={{ container: (base) => ({ ...base, zIndex: 500 }) }}
                      options={[
                        { value: constClass.STATUS.REG, label: constClass.STATUS_NAME.REG },
                        { value: constClass.STATUS.PRE, label: constClass.STATUS_NAME.PRE },
                        { value: constClass.STATUS.FIN, label: constClass.STATUS_NAME.FIN },
                        { value: constClass.STATUS.DFIN, label: constClass.STATUS_NAME.DFIN },
                        { value: constClass.STATUS.CCL, label: constClass.STATUS_NAME.CCL },
                        { value: constClass.STATUS.UPAY, label: constClass.STATUS_NAME.UPAY },
                        { value: constClass.STATUS.CUPAY, label: constClass.STATUS_NAME.CUPAY },
                      ]}
                      isClearable={false}
                      isMulti
                      closeMenuOnSelect={false}
                      value={condition.selectStatus}
                      placeholder={'ステータス...'}
                      backspaceRemovesValue={false}
                      controlShouldRenderValue={false}
                      hideSelectedOptions={false}
                      onChange={handleSelectStatus} />
                  </div>
                </div>
              </div>
            }
            <div className="col-1 text-center">
              <button type="button"
                className={`btn btn-primary mx-1 w-100`}
                onClick={() => handleIsReload(true)}>
                検索</button>
            </div>
          </div>
        </div>
      </div>

      {/* ジャンル別一覧表示選択 */}
      {(Common.getSettingValue(settings, 'USER_DEPT', '') !== '1' || (!user.productDeptCode && user.productDeptCode !== 0 && Common.getSettingValue(settings, 'USER_DEPT', '') === '1')) &&
        <Tabs onSelect={handleTabSelect} defaultIndex={condition.index}>
          <TabList className="nav nav-pills mb-2">
            <Tab className="nav-item"><div className={`nav-link btn ${condition.index === 0 ? 'active' : ''}`} href="#">{Common.getSettingValue(settings, 'SHOP_NAME', '店舗')}別企画商品別</div></Tab>
            <Tab className="nav-item"><div className={`nav-link btn ${condition.index === 1 ? 'active' : ''}`} href="#">注文一覧({Common.getSettingValue(settings, 'SHOP_NAME', '店舗')}受取)</div></Tab>
            <Tab className="nav-item"><div className={`nav-link btn ${condition.index === 2 ? 'active' : ''}`} href="#">注文一覧(商品別)</div></Tab>
            <Tab className="nav-item"><div className={`nav-link btn ${condition.index === 3 ? 'active' : ''}`} href="#">注文一覧(配送)</div></Tab>
            <Tab className="nav-item"><div className={`nav-link btn ${condition.index === 4 ? 'active' : ''}`} href="#">決済データ一覧</div></Tab>
          </TabList>
          <TabPanel>
            <BackyardOrderShop {...props} /> {/* 店舗別企画商品別 */}
          </TabPanel>
          <TabPanel>
            <BackyardOrderList {...props} delivery_flag={constClass.FLAG.OFF} /> {/* 注文一覧 */}
          </TabPanel>
          <TabPanel>
            <BackyardOrderProduct {...props} /> {/* 注文一覧（商品別） */}
          </TabPanel>
          <TabPanel>
            <BackyardOrderList {...props} delivery_flag={constClass.FLAG.ON} />
          </TabPanel>
          <TabPanel>
            <BackyardOrderPaymentList {...props} />
          </TabPanel>
        </Tabs>
      }
      {Common.getSettingValue(settings, 'USER_DEPT', '') === '1' && (user.productDeptCode || user.productDeptCode === 0) &&
        <Tabs onSelect={handleTabSelect} defaultIndex={condition.index}>
          <TabList className="nav nav-pills mb-2">
            <Tab className="nav-item"><div className={`nav-link btn ${condition.index === 5 ? 'active' : ''}`} href="#">注文一覧</div></Tab>
          </TabList>
          <TabPanel>
            <BackyardOrderDept {...props} />
          </TabPanel>
        </Tabs>
      }
    </div>
  )
}

export default BackyardOrderFinTab;