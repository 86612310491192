import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Loading from '../Loading/Loading';

const Base = (props) => {
  const { user, userActions, conditionActions  } = props;


  useEffect(() => {
    if (!user.isPrepared) {
      const jwt = localStorage.getItem('jwt');
      if (jwt === null) {
        userActions.logout();
        conditionActions.logout();
      } else {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/login/verify/`, { site_id: props.match.params.siteId }, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })
          .then((response) => {
            if (response.status === 200) {
              var userData = response.data;
              if (userData !== null && userData.site_id === Number(props.match.params.siteId)) {
                userActions.login({
                  userId: userData.user_id,
                  userName: userData.user_name,
                  userShort: userData.user_short,
                  userClass: String(userData.user_class),
                  productDeptCode: userData.product_dept_code,
                  shopId: userData.shop_id,
                  userToken: jwt,
                });

              } else {
                userActions.logout();
                conditionActions.logout();
              }
            } else {
              userActions.logout();
              conditionActions.logout();
            }
          })
          .catch((err) => {
            console.log(err);
            userActions.logout();
            conditionActions.logout();
          });
      }
    }
  }, [user.isPrepared, userActions, props.match.params.siteId, conditionActions])

  return user.isPrepared ? (
    <div>
      {props.children}
    </div>) :
    <Loading />;
}

Base.propTypes = {
  children: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

// function select({ auth }) {
//   return { auth };
// }

export default Base;