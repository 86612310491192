import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import util from 'util';
import constClass from '../../Constants/Constants'
import { generatePath } from 'react-router';
import Common from '../Common/common.js';

const NavBar = (props) => {
  const { user, userActions, settings, conditionActions } = props;
  const history = useHistory();

  // タイトルボタン：処理
  const handleTitleClick = () => {
    userActions.refresh();
    history.replace(generatePath(`${props.match.path}`, { siteId: props.match.params.siteId }));
  }

  // ログアウトボタン：処理
  const handleLogoutClick = () => {
    localStorage.removeItem('jwt');
    userActions.logout();
    conditionActions.logout();
    history.replace(generatePath(`${props.match.path}login`, { siteId: props.match.params.siteId }));
  }

  return (
    <nav className="navbar navbar-light navbar-expand-lg  bg-light fixed-top">
      <Link className="navbar-brand" onClick={handleTitleClick} to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}`}>
        {Common.getSettingValue(settings, 'TITLE_SHORT_NAME', '')}<div className='d-none'>{util.inspect(history)}</div>
      </Link>
      {
        user.isLoggedIn &&
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
      }
      {
        user.isLoggedIn && (Common.getSettingValue(settings, 'USER_DEPT', '') !== '1' || (!user.productDeptCode && user.productDeptCode !== 0 && Common.getSettingValue(settings, 'USER_DEPT', '') === '1')) &&
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
            {user.userClass === constClass.CLASS.ADMIN &&
              <li className="nav-item">
                <Link className="nav-link" to={`${generatePath(`${props.match.path}plancategory`, { siteId: props.match.params.siteId })}`}>企画カテゴリマスタ</Link>
              </li>
            }
            {user.userClass === constClass.CLASS.ADMIN &&
              <li className="nav-item">
                <Link className="nav-link" to={`${generatePath(`${props.match.path}productcategory`, { siteId: props.match.params.siteId })}`}>商品カテゴリマスタ</Link>
              </li>
            }
            {user.userClass === constClass.CLASS.ADMIN &&
              <li className="nav-item">
                <Link className="nav-link" to={`${generatePath(`${props.match.path}productdept`, { siteId: props.match.params.siteId })}`}>{Common.getSettingValue(settings, 'ORDER_DEPT_NAME', '商品デプト')}マスタ</Link>
              </li>
            }
            {user.userClass === constClass.CLASS.ADMIN && 
              <li className="nav-item">
                <Link className="nav-link" to={`${generatePath(`${props.match.path}plan`, { siteId: props.match.params.siteId })}`}>企画</Link>
              </li>
            }
            {user.userClass === constClass.CLASS.ADMIN &&
              <li className="nav-item">
                <Link className="nav-link" to={`${generatePath(`${props.match.path}product`, { siteId: props.match.params.siteId })}`}>商品</Link>
              </li>
            }
            {user.userClass === constClass.CLASS.ADMIN && Common.getSettingValue(settings, 'SHOP_CALENDAR', '') === '1' &&
              <li className="nav-item dropdown">
                <div className="nav-link nav-btn dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  カレンダー
                </div>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <Link className="dropdown-item" to={`${generatePath(`${props.match.path}shopcalendar`, { siteId: props.match.params.siteId })}`}>{Common.getSettingValue(settings, 'SHOP_NAME', '店舗')}別受取日カレンダーマスタ</Link>
                  <Link className="dropdown-item" to={`${generatePath(`${props.match.path}productcalendar`, { siteId: props.match.params.siteId })}`}>商品カレンダーマスタ</Link>
                </div>
              </li>
            }
            <li className="nav-item">
              <Link className="nav-link" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}`}>注文一覧</Link>
            </li>
            {user.userClass !== constClass.CLASS.READER &&
              <li className="nav-item">
                <Link className="nav-link" to={`${generatePath(`${props.match.path}order/input`, { siteId: props.match.params.siteId })}`}>注文入力</Link>
              </li>
            }
            {user.userClass === constClass.CLASS.ADMIN &&
              <li className="nav-item dropdown">
                <div className="nav-link nav-btn dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  管理用マスタ
                </div>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <Link className="dropdown-item" to={`${generatePath(`${props.match.path}noticeinterval`, { siteId: props.match.params.siteId })}`}>通知頻度マスタ</Link>
                  <Link className="dropdown-item" to={`${generatePath(`${props.match.path}area`, { siteId: props.match.params.siteId })}`}>{Common.getSettingValue(settings, 'SHOP_NAME', '店舗')}エリアマスタ</Link>
                  <Link className="dropdown-item" to={`${generatePath(`${props.match.path}shop`, { siteId: props.match.params.siteId })}`}>{Common.getSettingValue(settings, 'SHOP_NAME', '店舗')}マスタ</Link>
                  <Link className="dropdown-item" to={`${generatePath(`${props.match.path}user`, { siteId: props.match.params.siteId })}`}>ユーザマスタ</Link>
                  <Link className="dropdown-item" to={`${generatePath(`${props.match.path}receivetime`, { siteId: props.match.params.siteId })}`}>受取時間帯マスタ</Link>
                  <Link className="dropdown-item" to={`${generatePath(`${props.match.path}setting`, { siteId: props.match.params.siteId })}`}>設定</Link>
                </div>
              </li>
            }
            {user.userClass !== constClass.CLASS.READER &&
              <li className="nav-item">
                <a className="nav-link" href="/pdf/予約注文アプリforLINE操作マニュアル.pdf" target="_blank" rel="noreferrer">マニュアル</a>
              </li>
            }
          </ul>
        </div>
      }
      {            
        user.isLoggedIn && Common.getSettingValue(settings, 'USER_DEPT', '') === '1' && (user.productDeptCode || user.productDeptCode === 0) &&
       <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}`}>注文一覧</Link>
            </li>
          </ul>
        </div>
      }
      {
        user.isLoggedIn &&
        <div>
          <span className="navbar-text">
            {user.userClass !== constClass.CLASS.READER &&
              <Link className="nav-link px-2 py-0" to={`${generatePath(`${props.match.path}user`, { siteId: props.match.params.siteId })}`}>{user.userName}</Link>
            }
            {user.userClass === constClass.CLASS.READER &&
              <div className="px-2 py-0">{user.userName}</div>
            }  
          </span>
          <button className="btn btn-dark" onClick={handleLogoutClick}>ログアウト</button>
        </div>
      }
    </nav>
  );
}

export default NavBar;