import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import App from './Containers/App';
import BackApp from './Containers/BackApp';
import reportWebVitals from './reportWebVitals';
import {Route} from 'react-router-dom';
import { Provider } from "react-redux";
import BackAppStore from './Stores/BackApp';

ReactDOM.render(
  <BrowserRouter>
    {/* <Route exact path='/' render={(props) => <App {...props}/>} /> */}
    <Route exact path='/:siteId' render={(props) => <App {...props}/>} />
    <Route path='/:siteId/backyard/' render={(props) => <Provider store={BackAppStore}><BackApp {...props}/></Provider>} />
  </BrowserRouter>
, document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
