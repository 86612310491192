import React, { useMemo, useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import axios from 'axios';
import NavBar from '../../src/Components/NavBar/NavBar';
import UserSlice from '../../src/Slices/User';
import UserOnly from '../../src/Components/Auth/UserOnly';
import GuestOnly from '../../src/Components/Auth/GuestOnly';
import Login from '../../src/Components/Auth/Login';
import Base from '../../src/Components/Auth/Base';
import util from 'util';
import Modal from 'react-modal';
import DocumentMeta from 'react-document-meta';
import Common from '../Components/Common/common.js';
import { ToastProvider } from 'react-toast-notifications';
import BackyardOrderFinTab from '../../src/Components/BackyardOrder/BackyardOrderFinTab';
import PlanCategoryMaster from '../../src/Components/Master/PlanCategory';
import ProductCategoryMaster from '../../src/Components/Master/ProductCategory';
import constClass from '../../src/Constants/Constants';
import BackyardOrderInput from '../../src/Components/BackyardOrder/BackyardOrderInput';
import PlanMaster from '../../src/Components/Master/Plan'
import ProductMaster from '../../src/Components/Master/Product'
import ProductDept from '../../src/Components/Master/ProductDept'
import ShopCalendar from '../Components/Master/ShopCalendar'
import ProductCalendar from '../Components/Master/ProductCalendar'
import NoticeInterval from '../../src/Components/Master/NoticeInterval';
import Area from '../../src/Components/Master/Area';
import UserMaster from '../../src/Components/Master/User'
import ShopMaster from '../../src/Components/Master/Shop'
import SettingMaster from '../../src/Components/Master/Setting'
import ReceiveTimeMaster from '../../src/Components/Master/ReceiveTime'
import BackyardOrderCondition from '../Slices/BackyardOrderCondition';

const useActions = (actions, deps) => {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map(a => bindActionCreators(a, dispatch))
      }
      return bindActionCreators(actions, dispatch)
    },
    deps ? [dispatch, ...deps] : [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )
};

const BackApp = (props) => {
  const initSettings = async (siteId) => {
    const data = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/setting/${siteId}/`)).data;
    setSettings(data);
    const temp = {
      link: {
        rel: {
          icon: `/${siteId}/favicon.ico`,
          "apple-touch-icon": `/${siteId}/logo192.png`,
          manifest: `/${siteId}/manifest.json`,
          stylesheet: `/${siteId}/style.css`,
        },
      },
      title: data.find(s => s.setting_type === 'TITLE_NAME') ? data.find(s => s.setting_type === 'TITLE_NAME').setting_val : '',
      description: data.find(s => s.setting_type === 'TITLE_NAME') ? data.find(s => s.setting_type === 'TITLE_NAME').setting_val : '',
    };
    console.log(temp)
    setMeta(temp);
  };

  const userActions = useActions(UserSlice.actions);
  const user = useSelector(state => state.User);
  const [settings, setSettings] = useState(null);
  const [meta, setMeta] = useState({});
  const conditionActions = useActions(BackyardOrderCondition.actions);
  const condition = useSelector(state => state.BackyardOrderCondition);

  useEffect(() => {
    initSettings(props.match.params.siteId);
  }, [props.match.params.siteId]);

  const _userProps = { user, userActions, conditionActions, condition, settings, ...props };
  return (
    <div className='backyard-top'>
      <div className="row d-none"><div className="col-12">{util.inspect(settings)}</div></div>
      <DocumentMeta {...meta} />
      <Modal isOpen={Common.checkContractPeriod(settings) === false}>
        <div className="text-center section-white m-0 py-2">
          <h4>サービス契約期間外です</h4>
        </div>
      </Modal>
      <Route path={`${props.match.path}`} render={() =>
        <Base {..._userProps}>
          <NavBar {..._userProps} />
          <ToastProvider placement="bottom-center">
            <Route render={() =>
              <UserOnly {..._userProps} >
                <Route exact path={`${props.match.path}`} >
                  <BackyardOrderFinTab sts={constClass.STATUS.REG} {..._userProps} />
                </Route>
                <Route path={`${props.match.path}plancategory`} >
                  <PlanCategoryMaster {..._userProps} />
                </Route>
                <Route path={`${props.match.path}productcategory`} >
                  <ProductCategoryMaster {..._userProps} />
                </Route>
                <Route path={`${props.match.path}order/input/:id`}>
                  <BackyardOrderInput {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}order/input`} >
                  <BackyardOrderInput {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}plan`} >
                  <PlanMaster {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}product`} >
                  <ProductMaster {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}productdept`} >
                  <ProductDept {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}shopcalendar`} >
                  <ShopCalendar {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}productcalendar`} >
                  <ProductCalendar {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}noticeinterval`} >
                  <NoticeInterval {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}area`} >
                  <Area {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}user`} >
                  <UserMaster {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}setting`} >
                  <SettingMaster {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}shop`} >
                  <ShopMaster {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}receivetime`} >
                  <ReceiveTimeMaster {..._userProps} />
                </Route>
              </UserOnly>
            } />
            <Route render={() =>
              <GuestOnly {..._userProps} >
                <Route path={`${props.match.path}login`} render={() =>
                  <Login {..._userProps} />
                } />
              </GuestOnly>
            } />
          </ToastProvider>
        </Base>
      } />
    </div>
  );
}

export default BackApp;