import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import Reactcalendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const ProductCalendar = (props) => {
  const { user, shop } = props;
  const [values, setValues] = useState({ product_start: "", product_end: "" });
  const [productData, setProductData] = useState(null);
  const [calendarData, setCalendarData] = useState([]);
  const [selectDate, setSelectDate] = useState([null, null]);
  const [planMaster, setPlanMaster] = useState(null);
  const [plan, setPlan] = useState(null);
  const { addToast } = useToasts();

  const refreshPlan = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const param = {
      "site_id": props.match.params.siteId,
    }
    const plandata = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/plan/search/`, param, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    setPlanMaster(plandata);
  }, [props.match.params.siteId])

  const refreshShop = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const param = {
      "site_id": props.match.params.siteId,
      "operator": "and",
      "where": [
        {
          "operator": "eq",
          "attr": "site_id",
          "val": props.match.params.siteId
        }
      ]
    }
    const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/product/search/`, param, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    data.sort((a, b) => a.product_id - b.product_id);  // 昇順ソート
    setProductData(data);

    const calendar = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/productcalendar/search`, param, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    setCalendarData(calendar);

  }, [props.match.params.siteId])

  const checkDate = async () => {
    if (values.product_start && values.product_end) {
      setValues({
        ...values,
        product_start: "",
        product_end: "",
      });
    }
  }

  const changeDate = async (date) => {
    setSelectDate(date);
    var product_start = moment(date[0]).format('YYYYMMDD');
    var product_end = moment(date[1]).format('YYYYMMDD');
    setValues({
      ...values,
      product_start: product_start,
      product_end: product_end,
    });
    const jwt = localStorage.getItem('jwt');
    let res;
    const params = {
      site_id: Number(props.match.params.siteId),
      product: [],
    };
    const data = calendarData.filter(x => (moment(x.product_start).format('YYYYMMDD') <= product_start && moment(x.product_end).format('YYYYMMDD') >= product_start) || (moment(x.product_start).format('YYYYMMDD') <= product_end && moment(x.product_end).format('YYYYMMDD') >= product_end) || (moment(x.product_start).format('YYYYMMDD') > product_start && moment(x.product_end).format('YYYYMMDD') < product_end));
    const data2 = calendarData.filter(x => moment(x.product_start).format('YYYYMMDD') === product_start && moment(x.product_end).format('YYYYMMDD') === product_end);
    if (data2.length > 0) { // 同じ期間の設定がある
      const _productData = [...productData];
      data2.forEach(a => {
        const p = _productData.find(b => b.product_id === a.product_id);
        if (a.stock) {
          p.stock = a.stock; // 在庫数を既存の設定に変更する
          p.enable = true; // 販売ありに設定
        } else {
          p.enable = false; // 販売なしに設定
        }
      });
      setProductData(_productData);
      return;
    }
    if (data.length > 0) {
      data.forEach((b) => {
        params.product.push({
          'site_id': Number(props.match.params.siteId),
          'product_id': b.product_id,
          'product_start': b.product_start,
        });
      });
      if (!window.confirm('期間が重複しています。削除してよろしいですか？')) {
        setValues({
          ...values,
          product_start: "",
          product_end: "",
        });
        setSelectDate([null, null]);
        return;
      }
    }
    try {
      const filter = {
        "site_id": props.match.params.siteId,
        "operator": "and",
        "where": [
          {
            "operator": "eq",
            "attr": "site_id",
            "val": props.match.params.siteId
          }
        ]
      }
      const product = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/product/search/`, filter, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      })).data;
      product.sort((a, b) => a.product_id - b.product_id);  // 昇順ソート
      setProductData(product);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/productcalendar/delete`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
      const calendar = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/productcalendar/search`, filter, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      })).data;
      setCalendarData(calendar);
    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (!res) {
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        setValues({
          ...values,
          product_start: "",
          product_end: "",
        });
        setSelectDate([null, null]);
      }
    }
  }
  const getTileContent = (a) => {
    if (calendarData.find(x => moment(x.product_start).format('YYYYMMDD') <= moment(a.date).format('YYYYMMDD') && moment(x.product_end).format('YYYYMMDD') >= moment(a.date).format('YYYYMMDD'))) {
      return (
        <p className={`${calendarData.find(x => moment(x.product_start).format('YYYYMMDD') <= moment(a.date).format('YYYYMMDD') && moment(x.product_end).format('YYYYMMDD') >= moment(a.date).format('YYYYMMDD')) ? "bg-yellow" : ""}`}>
          {calendarData.find(x => moment(x.product_start).format('YYYYMMDD') <= moment(a.date).format('YYYYMMDD') && moment(x.product_end).format('YYYYMMDD') >= moment(a.date).format('YYYYMMDD')) ? "〇" : ""}
          <br />
          {calendarData.find(x => moment(x.product_start).format('YYYYMMDD') === moment(a.date).format('YYYYMMDD')) ? "開始日" : ""}
          {calendarData.find(x => moment(x.product_end).format('YYYYMMDD') === moment(a.date).format('YYYYMMDD')) ? "終了日" : ""}
        </p>
      );
    }
  }

  // value値変更イベント
  const handleChangeCell = (product_id, key) => (event) => {
    const _productData = [...productData];
    const index = _productData.findIndex(p => p.product_id === product_id);
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    _productData[index] = { ..._productData[index], [key]: value };
    setProductData(_productData);
  }

  // 登録ボタン
  const renderRegistButton = () => {
    return (
      <button type="button"
        className={`btn btn-primary mx-1 w-20`}
        onClick={() => handleProductRegistClick()}
        disabled={!values.product_start || !values.product_end}>
        登録
      </button>
    )
  }

  // 登録ボタン：処理
  const handleProductRegistClick = async () => {
    const jwt = localStorage.getItem('jwt');
    const params = {
      site_id: Number(props.match.params.siteId),
      product_start: values.product_start,
      product_end: values.product_end,
      product: [],
    };
    productData.filter(p => !plan || p.plan_id === plan).forEach((product) => {
      params.product.push({
        'site_id': Number(props.match.params.siteId),
        'product_id': product.product_id,
        'stock': product.enable ? (product.stock || 0) : 0,
      });
    });

    let res;
    try {
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/productcalendar/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
      const calendar = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/productcalendar/search`, { site_id: props.match.params.siteId }, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      })).data;
      setCalendarData(calendar);
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (!res) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        setValues({
          ...values,
          product_start: "",
          product_end: "",
        });
        setSelectDate([null, null]);
        refreshShop();
      }
    }
  }

  const checkAll = () => {
    const _productData = [...productData];
    const planList = _productData.filter(p => !plan || p.plan_id === plan);
    if (planList.length !== planList.filter(p => p.enable).length) {
      // 一つでもチェックされていなければ全選択処理
      _productData.filter(p => !plan || p.plan_id === plan).forEach(p => {
        p.enable = true;
      });
    } else {
      // 全て選択されている場合は全解除処理
      _productData.filter(p => !plan || p.plan_id === plan).forEach(p => {
        p.enable = false;
      });
    }
    setProductData(_productData);
  }

  useEffect(() => {
    refreshPlan();
  }, [refreshPlan]);

  useEffect(() => {
    async function fetchData() {
      await refreshShop();
    }
    return fetchData();
  }, [refreshShop]);

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(productData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(shop)}</div></div>
      {(productData === null || planMaster === null) && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {(productData !== null && planMaster !== null) && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <div className="row mx-0 my-3 text-center">
              <div className="col text-center align-self-center">
                <Reactcalendar
                  locale="ja-JP"
                  calendarType="US"
                  onChange={changeDate}
                  tileContent={getTileContent}
                  className="w-100"
                  selectRange={true}
                  onClickDay={checkDate}
                  value={selectDate}
                />
              </div>
            </div>
            <div className="row mx-0 my-3 text-center">
              <div className="col-2 text-center align-self-center">
                <span>企画</span>
              </div>
              <div className="col-6">
                <select className="custom-select w-100" aria-label="企画" name="plan_id" value={plan || ''} onChange={(e) => setPlan(Number(e.target.value))}>
                  <option value="">選択してください</option>
                  {planMaster.map((item) =>
                    <option key={item.plan_id} value={item.plan_id}>{item.plan_name}({item.plan_start}～{item.plan_end})</option>
                  )}
                </select>
              </div>
            </div>
            <div className="row mx-0 my-3 text-center">
              <div className="col text-center align-self-center">
                <table className="table table-bordered table-striped">
                  <thead className={`table-${constClass.COLOR[user.userClass]}`}>
                    <tr>
                      <td className="text-center align-middle">
                        商品コード
                      </td>
                      <td className="text-center align-middle">
                        商品名
                      </td>
                      <td className="text-center align-middle like-button" onClick={checkAll}>
                        全{productData.filter(p => !plan || p.plan_id === plan).length !== productData.filter(p => (!plan || p.plan_id === plan) && p.enable).length ? '選択' : '解除'}
                      </td>
                      <td className="text-center align-middle">
                        在庫数
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {productData.filter(p => !plan || p.plan_id === plan).map((data, idx) => (
                      <tr key={data.product_id}>
                        {/* 商品ID */}
                        <td className="text-center align-middle">
                          {data.product_code}
                        </td>
                        {/* 商品名 */}
                        <td className="text-center align-middle">
                          {data.product_name}
                        </td>
                        {/* 商品名 */}
                        <td className="text-center align-middle">
                          <input type="checkbox" className="form-control" id={"enable" + data.product_code} name="enable[]" value={data.enable} checked={data.enable || false} onChange={handleChangeCell(data.product_id, 'enable')}></input>
                        </td>
                        {/* 在庫数*/}
                        <td className="text-center align-middle">
                          <input type="text" className="form-control" id={"stock" + data.product_code} name="stock[]" value={data.stock} onChange={handleChangeCell(data.product_id, 'stock')} disabled={!data.enable}></input>
                        </td>
                      </tr>
                    ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              {renderRegistButton()}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProductCalendar