import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';

const PlanCategory = (props) => {
  const { user } = props;
  const [planCategoryData, setPlanCategoryData] = useState(null);
  const [planCategoryNew, setPlanCategoryNew] = useState({ plan_category_id: null, plan_category_name: '', upd_date: null });
  const [lockData, setLockData] = useState(false);
  const [maxId, setMaxId] = useState(null);
  const { addToast } = useToasts();

  const refreshPlanCategory = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const reg_params = {
      "site_id": props.match.params.siteId,
      "operator": "and",
      "where": [{ "site_id": props.match.params.siteId }]
    }
    const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/plancategory/search/`, reg_params, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    data.sort((a, b) => a.plan_category_id - b.plan_category_id);
    setPlanCategoryData(data);
    setMaxId(data.length > 0 ? Math.max(...data.map((p) => p.plan_category_id)) : 0);
    const new_data = { plan_category_id: null, plan_category_name: '', upd_date: null };
    setPlanCategoryNew(new_data);
  }, [props.match.params.siteId])

  // 登録ボタン：処理
  const handlePlanCategoryRegistClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!data.plan_category_name) {
      window.alert('企画カテゴリ名を入力してください。');
      return;
    }
    const params = {
      site_id: props.match.params.siteId,
      plan_category_id: data.plan_category_id,
      plan_category_name: data.plan_category_name,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/plancategory/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshPlanCategory();
      }
      setLockData(false);
    }
  }

  // 削除ボタン：処理
  const handlePlanCategoryDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (data.plan_count > 0) {
      window.alert('対象のカテゴリに企画が登録されているため、削除できません。');
      return;
    }
    if (!window.confirm(`企画カテゴリ[${data.plan_category_id}:${data.plan_category_name}]を削除します。よろしいですか？`)) {
      return;
    }
    const params = {
      site_id: props.match.params.siteId,
      plan_category_id: data.plan_category_id,
      plan_category_name: data.plan_category_name,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/plancategory/delete/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        await refreshPlanCategory();
      }
      setLockData(false);
    }
  }

  // value値変更イベント
  const handleChangeCell = (index, key) => (event) => {
    if (index === 'new') {
      // id付与
      var numId = maxId;
      numId = Number(numId) + 1;
      const new_data = { plan_category_id: numId, plan_category_name: event.target.value, upd_date: null };
      setPlanCategoryNew(new_data);
    } else {
      const _planCategoryData = [...planCategoryData]
      _planCategoryData[index] = { ..._planCategoryData[index], [key]: event.target.value }
      setPlanCategoryData(_planCategoryData)
    }
  }

  // 登録ボタン
  const renderRegistButton = (data) => {
    return (
      <button type="button"
        disabled={lockData}
        className={`btn btn-primary mx-1`}
        onClick={() => handlePlanCategoryRegistClick(data)}>
        登録
      </button>
    )
  }

  // 削除ボタン
  const renderDeleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.plan_category_id === null}
        className={`btn btn-primary mx-1`}
        onClick={() => handlePlanCategoryDeleteClick(data)}>
        削除
      </button>
    )
  }

  // 初期実行トリガー
  useEffect(() => {
    async function fetchData() {
      await refreshPlanCategory();
    }
    return fetchData();
  }, [refreshPlanCategory]);

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(planCategoryData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {planCategoryData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {planCategoryData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-${constClass.COLOR[user.userClass]}`}>
                <tr>
                  <td className="text-center">
                    ID
                  </td>
                  <td className="text-center">
                    <span className="needmark">※</span>
                      企画カテゴリ名
                  </td>
                  <td className="text-center">
                    作成者
                  </td>
                  <td className="text-center">
                    作成日時
                  </td>
                  <td className="text-center">
                    更新者
                  </td>
                  <td className="text-center">
                    更新日時
                  </td>
                  <td className="text-center">
                    処理
                  </td>
                </tr>
              </thead>
              <tbody>
                {planCategoryData.map((data, idx) => (
                  <tr key={data.plan_category_id}>
                    {/* 企画カテゴリID */}
                    <td className="text-center align-middle">
                      {data.plan_category_id}
                    </td>
                    {/* 企画カテゴリ名 */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"plan_category_name" + data.plan_category_id} name="plan_category_name[]" value={data.plan_category_name} onChange={handleChangeCell(idx, 'plan_category_name')}></input>
                    </td>
                    {/* 作成者 */}
                    <td className="text-center align-middle">
                      {data.ins_name}
                    </td>
                    {/* 作成日時 */}
                    <td className="text-center align-middle">
                      {data.ins_date ? moment(data.ins_date).format('YYYY-MM-DD HH:mm:ss') : ''}
                    </td>
                    {/* 更新者 */}
                    <td className="text-center align-middle">
                      {data.upd_name}
                    </td>
                    {/* 更新日時 */}
                    <td className="text-center align-middle">
                      {data.upd_date ? moment(data.upd_date).format('YYYY-MM-DD HH:mm:ss') : ''}
                    </td>
                    {/* 処理 */}
                    <td className="text-center align-middle">
                      {renderRegistButton(data)}
                      {renderDeleteButton(data)}
                    </td>
                  </tr>
                ))
                }
                {/* 新規入力行 */}
                <tr key="new">
                  {/* 企画カテゴリID */}
                  <td className="text-center align-middle">
                    新規
                  </td>
                  {/* 企画カテゴリ名 */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"plan_category_name_new"} name="plan_category_name[]" value={planCategoryNew.plan_category_name} onChange={handleChangeCell('new', 'plan_category_name')}></input>
                  </td>
                  {/* 作成者 */}
                  <td className="text-center align-middle">
                  </td>
                  {/* 作成日時 */}
                  <td className="text-center align-middle">
                  </td>
                  {/* 更新者 */}
                  <td className="text-center align-middle">
                  </td>
                  {/* 更新日時 */}
                  <td className="text-center align-middle">
                  </td>
                  {/* 処理 */}
                  <td className="text-center align-middle">
                    {renderRegistButton(planCategoryNew)}
                    {renderDeleteButton(planCategoryNew)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default PlanCategory;