import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';

const ReceiveTime = (props) => {
  const { user } = props;
  const [receiveTimeData, setReceiveTimeData] = useState(null);
  const [receiveTimeNew, setReceiveTimeNew] = useState({ time_id: '', time_name: '', upd_date: null });
  const [lockData, setLockData] = useState(false);
  const { addToast } = useToasts();

  const refreshReceiveTime = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const reg_params = {
      "site_id": props.match.params.siteId,
      "operator": "and",
      "where": [{ "site_id": props.match.params.siteId }]
    }
    const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/receive_time/search/`, reg_params, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;

    data.sort((a, b) => a.time_id - b.time_id);
    setReceiveTimeData(data);

    const new_data = { time_id: '', time_name: '', upd_date: null };
    setReceiveTimeNew(new_data);
  }, [props.match.params.siteId])

  // 登録ボタン：処理
  const handleReceiveTimeRegistClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    //入力値チェック
    if (data.time_id === '') {
      window.alert('IDを入力してください。');
      return;
    }
    if (data.time_id && !(String(data.time_id)).match(/^[0-9]+$/)) {
      window.alert('IDは半角整数で入力してください。');
      return;
    }
    if (!data.time_name) {
      window.alert('受取時間帯内容を入力してください。');
      return;
    }
    if (data.time_name && data.time_name.length > 30) {
      window.alert('受取時間帯内容は30字以内で入力してください。');
      return;
    }

    //データ重複チェック
    var DuplicateFlag = false;
    if (data.upd_date === null) {
      receiveTimeData.forEach(function (output) {
        if (String(output.time_id) === data.time_id) {
          DuplicateFlag = true;
        }
      });
      if (DuplicateFlag) {
        window.alert('既に登録されているIDです。');
        return;
      }
    }

    // データ登録・更新
    const params = {
      site_id: props.match.params.siteId,
      time_id: data.time_id,
      time_name: data.time_name,
      upd_date: data.upd_date
    };
    let res;

    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/receive_time/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshReceiveTime();
      }
      setLockData(false);
    }

  }

  // 削除ボタン：処理
  const handleReceiveTimeDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!window.confirm(`受取時間帯[${data.time_id}:${data.time_name}]を削除します。よろしいですか？`)) {
      return;
    }

    //データ削除
    const params = {
      site_id: props.match.params.siteId,
      time_id: data.time_id,
      time_name: data.time_name,
      upd_date: data.upd_date
    };
    let res;

    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/receive_time/delete/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        await refreshReceiveTime();
      }
      setLockData(false);
    }
  }

  // data値変更イベント
  const handleChangeCell = (index, key) => (event) => {
    if (index === 'new') {
      const new_data = { ...receiveTimeNew, [key]: event.target.value };
      setReceiveTimeNew(new_data)
    } else {
      const _receiveTimeData = [...receiveTimeData]
      _receiveTimeData[index] = { ...receiveTimeData[index], [key]: event.target.value }
      setReceiveTimeData(_receiveTimeData)
    }
  }

  // 登録ボタン
  const renderRegistButton = (data) => {
    return (
      <button type="button"
        disabled={lockData}
        className={`btn btn-primary mx-1`}
        onClick={() => handleReceiveTimeRegistClick(data)}>
        登録
      </button>
    )
  }

  // 削除ボタン
  const renderDeleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.time_id === null}
        className={`btn btn-primary mx-1`}
        onClick={() => handleReceiveTimeDeleteClick(data)}>
        削除
      </button>
    )
  }

  // 初期実行トリガー
  useEffect(() => {
    async function fetchData() {
      await refreshReceiveTime();
    }
    return fetchData();
  }, [refreshReceiveTime]);

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(receiveTimeData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {receiveTimeData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {receiveTimeData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className="table-info">
                <tr>
                  <td className="text-center col-1">
                    <span className='needmark'>※</span>
                      ID
                  </td>
                  <td className="text-center col-3">
                    <span className='needmark'>※</span>
                      受取時間帯内容
                  </td>
                  <td className="text-center col-1">
                    作成者
                  </td>
                  <td className="text-center col-2">
                    作成日時
                  </td>
                  <td className="text-center col-1">
                    更新者
                  </td>
                  <td className="text-center col-2">
                    更新日時
                  </td>
                  <td className="text-center col-2">
                    処理
                  </td>
                </tr>
              </thead>
              <tbody>
                {/* 新規入力行 */}
                <tr key="new">
                  {/* 受取時間帯ID */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id="time_id_new" name="time_id_new" value={receiveTimeNew.time_id} onChange={handleChangeCell('new', 'time_id')}></input>
                  </td>
                  {/* 受取時間帯内容 */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id="time_name_new" name="time_name_new" value={receiveTimeNew.time_name} onChange={handleChangeCell('new', 'time_name')}></input>
                  </td>
                  {/* 作成者 */}
                  <td className="text-center align-middle">
                  </td>
                  {/* 作成日時 */}
                  <td className="text-center align-middle">
                  </td>
                  {/* 更新者 */}
                  <td className="text-center align-middle">
                  </td>
                  {/* 更新日時 */}
                  <td className="text-center align-middle">
                  </td>
                  {/* 処理 */}
                  <td className="text-center align-middle">
                    {renderRegistButton(receiveTimeNew)}
                  </td>
                </tr>
                {receiveTimeData.map((data, idx) => (
                  <tr key={data.time_id}>
                    {/* 受取時間帯ID */}
                    <td className="text-center align-middle">
                      {data.time_id}
                    </td>
                    {/* 受取時間帯内容 */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"time_name" + data.time_id} name={"time_name" + data.time_id} value={data.time_name} onChange={handleChangeCell(idx, 'time_name')}></input>
                    </td>
                    {/* 作成者 */}
                    <td className="text-center align-middle">
                      {data.ins_name}
                    </td>
                    {/* 作成日時 */}
                    <td className="text-center align-middle">
                      {data.ins_date ? moment(data.ins_date).format('YYYY-MM-DD HH:mm:ss') : ''}
                    </td>
                    {/* 更新者 */}
                    <td className="text-center align-middle">
                      {data.upd_name}
                    </td>
                    {/* 更新日時 */}
                    <td className="text-center align-middle">
                      {data.upd_date ? moment(data.upd_date).format('YYYY-MM-DD HH:mm:ss') : ''}
                    </td>
                    {/* 処理 */}
                    <td className="text-center align-middle">
                      {renderRegistButton(data)}
                      {renderDeleteButton(data)}
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default ReceiveTime;