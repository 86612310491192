import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import Select from 'react-select'
import chroma from 'chroma-js';
import Common from '../Common/common.js';

const ProductDept = (props) => {
  const { user, settings } = props;
  const [productDeptData, setProductDeptData] = useState(null);
  const [productDeptNew, setProductDeptNew] = useState({ product_dept_code: null, product_dept_name: '', product_dept_color: constClass.PRODUCT_DIPT_COLOROPTION[0].value, upd_date: null });
  const [lockData, setLockData] = useState(false);
  const { addToast } = useToasts();
  const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  const colorStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled ? null : isSelected ? data.color : isFocused ? color.alpha(0.1).css() : null,
        color: isDisabled ? '#ccc' : isSelected ? chroma.contrast(color, 'white') > 2 ? 'white' : 'black' : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        },
        ...dot(data.color)
      };
    },
    input: styles => ({ ...styles, ...dot() }),
    placeholder: styles => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  };

  const refreshProductDept = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const reg_params = {
      "site_id": props.match.params.siteId,
      "operator": "and",
      "where": [{ "site_id": props.match.params.siteId }]
    }
    const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/productdept/search/`, reg_params, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    data.sort((a, b) => a.product_dept_code - b.product_dept_code);
    setProductDeptData(data);
    const new_data = { product_dept_code: null, product_dept_name: '', product_dept_color: constClass.PRODUCT_DIPT_COLOROPTION[0].value, upd_date: null };
    setProductDeptNew(new_data);
  }, [props.match.params.siteId])

  // 登録ボタン：処理
  const handleProductDeptRegistClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!data.product_dept_code) {
      window.alert(Common.getSettingValue(settings, 'ORDER_DEPT_NAME', '商品デプト')+'コードを入力してください。 ')
      return;
    }
    if (!(data.product_dept_code.match(/^([1-9]\d*|0)$/))) {
      window.alert(Common.getSettingValue(settings, 'ORDER_DEPT_NAME', '商品デプト')+'コードは数値を入力してください。');
      return;
    }
    if (!data.product_dept_name) {
      window.alert(Common.getSettingValue(settings, 'ORDER_DEPT_NAME', '商品デプト')+'名を入力してください。');
      return;
    }
    const params = {
      site_id: props.match.params.siteId,
      product_dept_code: data.product_dept_code,
      product_dept_name: data.product_dept_name,
      product_dept_color: data.product_dept_color,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/productdept/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshProductDept();
      }
      setLockData(false);
    }
  }

  // 削除ボタン：処理
  const handleProductDeptDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (data.product_count > 0) {
      window.alert('対象の'+Common.getSettingValue(settings, 'ORDER_DEPT_NAME', '商品デプト')+'に商品が登録されているため、削除できません。');
      return;
    }
    if (!window.confirm(Common.getSettingValue(settings, 'ORDER_DEPT_NAME', '商品デプト')+`[${data.product_dept_code}:${data.product_dept_name}]を削除します。よろしいですか？`)) {
      return;
    }
    const params = {
      site_id: props.match.params.siteId,
      product_dept_code: data.product_dept_code,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/productdept/delete/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        await refreshProductDept();
      }
      setLockData(false);
    }
  }

  // value値変更イベント
  const handleChangeCell = (index, key) => (event) => {
    if (index === 'new') {
      const new_data = { ...productDeptNew, [key]: event.target.value }
      setProductDeptNew(new_data);
    } else {
      const _productDeptData = [...productDeptData]
      _productDeptData[index] = { ..._productDeptData[index], [key]: event.target.value }
      setProductDeptData(_productDeptData)
    }
  }

  // 「商品デプト色」変更イベント（セレクト）
  const handleChangeSelect = (index, key, data) => {
    if (index === 'new') {
      const new_data = { ...productDeptNew, [key]: data.value }
      setProductDeptNew(new_data);
    } else {
      const _productDeptData = [...productDeptData]
      _productDeptData[index] = { ..._productDeptData[index], [key]: data.value }
      setProductDeptData(_productDeptData)
    }
  }

  // 登録ボタン
  const renderRegistButton = (data) => {
    return (
      <button type="button"
        disabled={lockData}
        className={`btn btn-primary mx-1`}
        onClick={() => handleProductDeptRegistClick(data)}>
        登録
      </button>
    )
  }

  // 削除ボタン
  const renderDeleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.upd_date === null || data.product_dept_code === Number(constClass.PRODUCT_DEPT_DEFAULT)}
        className={`btn btn-primary mx-1`}
        onClick={() => handleProductDeptDeleteClick(data)}>
        削除
      </button>
    )
  }

  // 初期実行トリガー
  useEffect(() => {
    async function fetchData() {
      await refreshProductDept();
    }
    return fetchData();
  }, [refreshProductDept]);

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(productDeptData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {productDeptData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {productDeptData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-${constClass.COLOR[user.userClass]}`}>
                <tr>
                  <td className="text-center">
                    <span className='needmark'>※</span>
                      {Common.getSettingValue(settings, 'ORDER_DEPT_NAME', '商品デプト')}<br />コード
                  </td>
                  <td className="text-center">
                    <span className='needmark'>※</span>
                      {Common.getSettingValue(settings, 'ORDER_DEPT_NAME', '商品デプト')}名
                  </td>
                  <td className="text-center w-25">
                    {Common.getSettingValue(settings, 'ORDER_DEPT_NAME', '商品デプト')}色
                  </td>
                  <td className="text-center">
                    作成者<br />更新者
                  </td>
                  <td className="text-center">
                    作成日時<br />更新日時
                  </td>
                  <td className="text-center">
                    処理
                  </td>
                </tr>
              </thead>
              <tbody>
                {/* 新規入力行 */}
                <tr key="new">
                  {/* 商品デプトコード */}
                  <td className="text-center align-middle input-number-w120">
                    <input type="text" className="form-control" id={"product_dept_code_new"} name="product_dept_code[]" value={productDeptNew.product_dept_code || ''} onChange={handleChangeCell('new', 'product_dept_code')}></input>
                  </td>
                  {/* 商品デプト名 */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"product_dept_name_new"} name="product_dept_name[]" value={productDeptNew.product_dept_name || ''} onChange={handleChangeCell('new', 'product_dept_name')}></input>
                  </td>
                  {/* 商品デプト色 */}
                  <td className="text-center align-middle">
                    <Select
                      name="product_dept_color[]"
                      id={"product_dept_color_new"}
                      defaultValue={constClass.PRODUCT_DIPT_COLOROPTION.find(r => r.value === productDeptNew.product_dept_color) || ''}
                      label="商品デプト色"
                      options={constClass.PRODUCT_DIPT_COLOROPTION}
                      styles={colorStyles}
                      onChange={(e) => handleChangeSelect('new', 'product_dept_color', e)}
                    />
                  </td>
                  {/* 作成者更新者 */}
                  <td className="text-center align-middle">
                  </td>
                  {/* 作成日時 更新日時 */}
                  <td className="text-center align-middle">
                  </td>
                  {/* 処理 */}
                  <td className="text-center align-middle">
                    {renderRegistButton(productDeptNew)}
                    {renderDeleteButton(productDeptNew)}
                  </td>
                </tr>
                {productDeptData.map((data, idx) => (
                  <tr key={data.product_dept_code}>
                    {/* 商品デプトコード */}
                    <td className="text-center align-middle">
                      {data.product_dept_code}
                    </td>
                    {/* 商品デプト名 */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"product_dept_name" + data.product_dept_code} name="product_dept_name[]" value={data.product_dept_name || ''} onChange={handleChangeCell(idx, 'product_dept_name')}></input>
                    </td>
                    {/* 商品デプト色 */}
                    <td className="text-center align-middle">
                      {/*<input type="text" className="form-control" id={"product_dept_color_new"} name="product_dept_color[]" value={productDeptNew.product_dept_color} onChange={handleChangeCell('new', 'product_dept_color')}></input>*/}
                      <Select
                        name="product_dept_color[]"
                        id={"product_dept_color" + data.product_dept_code}
                        defaultValue={constClass.PRODUCT_DIPT_COLOROPTION.find(r => r.value === data.product_dept_color) || ''}
                        label="商品デプト色"
                        options={constClass.PRODUCT_DIPT_COLOROPTION}
                        styles={colorStyles}
                        onChange={(e) => handleChangeSelect(idx, 'product_dept_color', e)}
                      />
                    </td>
                    {/* 作成者 更新者*/}
                    <td className="text-center align-middle">
                      {data.ins_name}<br />
                      {data.upd_name}
                    </td>
                    {/* 作成日時 更新日時 */}
                    <td className="text-center align-middle">
                      {data.ins_date ? moment(data.ins_date).format('YYYY-MM-DD HH:mm:ss') : ''}<br />
                      {data.upd_date ? moment(data.upd_date).format('YYYY-MM-DD HH:mm:ss') : ''}
                    </td>
                    {/* 処理 */}
                    <td className="text-center align-middle">
                      {renderRegistButton(data)}
                      {renderDeleteButton(data)}
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProductDept;