import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import { useToasts } from 'react-toast-notifications';
import Common from '../Common/common.js';

const Shop = (props) => {
  const { shop, settings } = props;
	const [shopNew, setShopNew] = useState({ shop_id: 0, shop_name: "", shop_detail: "", shop_tel: "", shop_address: "", shop_image_url: "", area_id: 0 , order_note_flag: "0", view_flag: "1" });
  const [shopData, setShopData] = useState(null);
  const [settingData, setSettingData] = useState([]);
  const { addToast } = useToasts();
  const [areaId, setAreaId] = useState(null);
  const [areaData, setAreaData] = useState([]);



  const refreshShop = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const param = {
        "site_id": props.match.params.siteId,
        "operator": "and",
        "where": [
            { 
                "operator": "eq",
                "attr": "site_id",
                "val": props.match.params.siteId 
            }
        ]
      }
    const getShop = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/shop/search/`, param, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    getShop.sort((a, b) => a.shop_id < b.shop_id ? -1 : 1);
    setShopData(getShop);
    setShopNew({ shop_id: "", shop_name: "", shop_detail: "", shop_tel: "", shop_address: "", shop_image_url: "", area_id: null, order_note_flag: "0", view_flag: "1" });
    const area = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/area/search/`, param, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    setAreaData(area);
    const setting = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/setting/${props.match.params.siteId}`)).data;
    setSettingData(setting);
  },[props.match.params.siteId])

  // value値変更イベント
  const handleChangeCell = (index, key) => (event) => {
    const target = event.target;
    var value = target.type === "select" ? target.selected : target.value;
    if (index === 'new') {     
      const new_data = { ...shopNew, [key]: value };
      setShopNew(new_data)
    } else {
      const _shopData = [...shopData]
      _shopData[index] = { ..._shopData[index], [key]: value };
      setShopData(_shopData)
    }
  }

  //「地区」変更イベント（セレクト）
  const handleChangeArea = (e) => {
    const target = e.target;
    const value = target.value;
    setAreaId(value);
  }

  //更新ボタン押下時
  const editButtonClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    // 入力チェック
    if (data.shop_id === "") {
      window.alert(Common.getSettingValue(settings, 'SHOP_NAME', '店舗')+'IDを入力してください。');
      return;
    }    
    if (data.shop_id && !(String(data.shop_id)).match(/^[0-9]+$/)) {
      window.alert(Common.getSettingValue(settings, 'SHOP_NAME', '店舗')+'IDは半角整数で入力してください。');
      return;
    }
    if (!data.shop_name) {
      window.alert(Common.getSettingValue(settings, 'SHOP_NAME', '店舗')+'名を入力してください。');
      return;
    }
    if (data.shop_name && data.shop_name.length > 100) {
      window.alert(Common.getSettingValue(settings, 'SHOP_NAME', '店舗')+'名は100字以内で入力してください。');
      return;
    }
    if (data.shop_detail && data.shop_detail.length > 500) {
      window.alert(Common.getSettingValue(settings, 'SHOP_NAME', '店舗')+'詳細は500字以内で入力してください。');
      return;
    }
    if (data.shop_tel && data.shop_tel.length > 20) {
      window.alert('電話番号は20字以内で入力してください。');
      return;
    }
    if (data.shop_address && data.shop_address.length > 100) {
      window.alert('住所は100字以内で入力してください。');
      return;
    }
    if (data.shop_image_url && data.shop_image_url.length > 100) {
      window.alert(Common.getSettingValue(settings, 'SHOP_NAME', '店舗')+'画像URLは100字以内で入力してください。');
      return;
    }
    
    // 確認メッセージ
    if (!window.confirm(Common.getSettingValue(settings, 'SHOP_NAME', '店舗')+`マスタ[${data.shop_id}:${data.shop_name}]を登録します。よろしいですか？`)) {
      return;
    }

    const params = {
      site_id: props.match.params.siteId,
      shop_id: data.shop_id,
      shop_name: data.shop_name,
      shop_detail: data.shop_detail,
      shop_tel: data.shop_tel,
      shop_address: data.shop_address,
      shop_image_url: data.shop_image_url,
      area_id: data.area_id === "" ? null : data.area_id,
      order_note_flag: data.order_note_flag,
      view_flag: data.view_flag,
      upd_date: data.upd_date,
    };
    // const params = data;
    let res;
    try {
      console.log(data.site_id);
      if(data.site_id !== undefined){
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/shop/update/`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
      } else {
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/shop/insert/`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      console.log(res);
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else{
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshShop();
      }
    }
  }

  // 登録ボタン
  const registerButton = (data) => {
    return (
      <button type="button"
        className={`btn btn-primary mx-1`}
        onClick={() => editButtonClick(data)}>
        登録
      </button>
    )
  }

  useEffect(() => {
    async function fetchData() {
      await refreshShop();
    }
    return fetchData();
  }, [refreshShop]);

  return (
    <div className="container">
			<div className="row my-2 ">
        <div className="col-2 text-center line-height-2-2">
          <span className="d-inline-block align-middle">{Common.getSettingValue(settings, 'SHOP_NAME', '店舗')}エリア</span>
        </div>
        <div className="col-3 text-center line-height-2-2">
          <select className="custom-select w-100" aria-label="エリア" name="area_id" value={areaId || ''} onChange={handleChangeArea}>
            <option value="">- 全て -</option>
            {areaData.map((item) =>
              <option key={item.area_id} value={item.area_id}>{item.area_name}</option>
            )}
          </select>
        </div>
      </div>

      <div className="row d-none"><div className="col-12">{util.inspect(shop)}</div></div>
      {shopData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}

      {shopData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-info`}>
                <tr>
                  <td className="text-center" width="10%">
                    <span className='needmark'>※</span>
                      {Common.getSettingValue(settings, 'SHOP_NAME', '店舗')}ID
                  </td>
                  <td className="text-center">
                    <span className='needmark'>※</span>
                      {Common.getSettingValue(settings, 'SHOP_NAME', '店舗')}名
                  </td>
                  <td className="text-center">
                    {Common.getSettingValue(settings, 'SHOP_NAME', '店舗')}詳細
                  </td>
                  <td className="text-center">
                    電話番号
                  </td>
                  <td className="text-center">
                    住所
                  </td>
                  <td className="text-center">
                    画像URL
                  </td>
                  <td className="text-center" width="10%">
                    {Common.getSettingValue(settings, 'SHOP_NAME', '店舗')}エリア
                  </td>
                  {Common.getSettingValue(settingData, 'ORDER_NOTE', '') === '1' &&
                    <td className="text-center" width="10%">
                      備考欄表示フラグ
                    </td>
                  }
                  <td className="text-center" width="10%">
                    表示フラグ
                  </td>
                  <td className="text-center" width="12%">
                    処理
                  </td>
                </tr>
              </thead>
              <tbody>
                {/* 新規入力行 */}
                <tr>
                  {/* 店舗ID */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"shop_id_new"} name="shop_id" value={shopNew.shop_id || ""} onChange={handleChangeCell('new', 'shop_id')}></input>
                    </td>  
									{/* 店舗名 */}
										<td className="text-center align-middle">
											<input type="text" className="form-control" id={"shop_name_new"} name="shop_name" value={shopNew.shop_name || ""} onChange={handleChangeCell('new', 'shop_name')}></input>
										</td>
									{/* 店舗詳細 */}
										<td className="text-center align-middle">
												<input type="text" className="form-control" id={"shop_detail_new"} name="shop_detail" value={shopNew.shop_detail || ""} onChange={handleChangeCell('new', 'shop_detail')}></input>
										</td>
									{/* 電話番号 */}
										<td className="text-center align-middle">
											<input type="text" className="form-control" id={"shop_tel_new"} name="shop_tel" value={shopNew.shop_tel || ""} onChange={handleChangeCell('new', 'shop_tel')}></input>
										</td>
									{/* 住所 */}
										<td className="text-center align-middle">
											<input type="text" className="form-control" id={"shop_address_new"} name="shop_address" value={shopNew.shop_address || ""} onChange={handleChangeCell('new', 'shop_address')}></input>
										</td>
									{/* 画像url */}
										<td className="text-center align-middle">
											<input type="text" className="form-control" id={"shop_image_url_new"} name="shop_image_url" value={shopNew.shop_image_url || ""} onChange={handleChangeCell('new', 'shop_image_url')}></input>
										</td>
                  {/* 地区ID */}
										<td className="text-center align-middle">
                      <select className="form-control" id="area_id" name={"area_id_new"} value={shopNew.area_id || ''} onChange={handleChangeCell('new', 'area_id')}>
                        <option value="">指定なし</option>
                          {areaData.map((item) =>
                            <option key={item.area_id} value={item.area_id}>{item.area_name}</option>
                          )}
                      </select>											
                    </td>
                  {/* 備考欄表示フラグ */}
                  {Common.getSettingValue(settingData, 'ORDER_NOTE', '') === '1' &&
                    <td className="text-center align-middle">
                      <select className="form-control" id="order_note_flag" name={"order_note_flag_new"}  value={shopNew.order_note_flag || ""} onChange={handleChangeCell('new', 'order_note_flag')}>
                        <option value="0">0:非表示</option>
                        <option value="1">1:表示</option>
                      </select>
										</td>
                  }
                  {/* 表示フラグ */}
                    <td className="text-center align-middle">
                      <select className="form-control" id="view_flag" name={"view_flag_new"}  value={shopNew.view_flag || ""} onChange={handleChangeCell('new', 'view_flag')}>
                        <option value="1">1:表示</option>
                        <option value="0">0:非表示</option>
                      </select>
										</td>
									{/* 編集 */}
										<td className="text-center align-middle">
											{registerButton(shopNew)}
										</td>
                </tr>
                {shopData.filter(s => !areaId || shopData.filter(a => a.area_id === Number(areaId)).map(m => m.shop_id).includes(s.shop_id)).map((data, idx) => (     
                  <tr key={data.shop_id}>
                    {/* 店舗ID */}
                      <td className="text-center align-middle">
                        {data.shop_id}
                      </td>  
                    {/* 店舗名 */}
											<td className="text-center align-middle">
												<input type="text" className="form-control" id={"shop_name" + idx} name="shop_name" value={data.shop_name || ""} onChange={handleChangeCell(idx, 'shop_name')}></input>
											</td>
                    {/* 店舗詳細 */}
											<td className="text-center align-middle">
												<input type="text" className="form-control" id={"shop_detail" + idx} name="shop_detail" value={data.shop_detail || ""} onChange={handleChangeCell(idx, 'shop_detail')}></input>
											</td>
                    {/* 電話番号 */}
											<td className="text-center align-middle">
												<input type="text" className="form-control" id={"shop_tel" + idx} name="shop_tel" value={data.shop_tel || ""} onChange={handleChangeCell(idx, 'shop_tel')}></input>
											</td>
                    {/* 住所 */}
											<td className="text-center align-middle">
												<input type="text" className="form-control" id={"shop_address" + idx} name="shop_address" value={data.shop_address || ""} onChange={handleChangeCell(idx, 'shop_address')}></input>
											</td>
                    {/* 画像url */}
											<td className="text-center align-middle">
												<input type="text" className="form-control" id={"shop_image_url" + idx} name="shop_image_url" value={data.shop_image_url || ""} onChange={handleChangeCell(idx, 'shop_image_url')}></input>
											</td>
                    {/* 地区ID */}
											<td className="text-center align-middle">
                        <select className="form-control" id="area_id" name={"area_id" + idx} value={data.area_id || ''} onChange={handleChangeCell(idx, 'area_id')}>
                        <option value="">指定なし</option>
                          {areaData.map((item) =>
                            <option key={item.area_id} value={item.area_id}>{item.area_name}</option>
                          )}
                        </select>											
                      </td>
                    {/* 備考欄表示フラグ */}
                    {Common.getSettingValue(settingData, 'ORDER_NOTE', '') === '1' &&
                      <td className="text-center align-middle">
                        <select className="form-control" id="order_note_flag" name={"order_note_flag" + idx} value={data.order_note_flag || ""} onChange={handleChangeCell(idx, 'order_note_flag')}>
                          <option value="0">0:非表示</option>
                          <option value="1">1:表示</option>
                        </select>
										  </td>
                    } 
                    {/* 表示フラグ */}
                      <td className="text-center align-middle">
                        <select className="form-control" id="view_flag" name={"view_flag" + idx} value={data.view_flag || ""} onChange={handleChangeCell(idx, 'view_flag')}>
                          <option value="1">1:表示</option>
                          <option value="0">0:非表示</option>
                        </select>
										  </td>
                    {/* 編集 */}
											<td className="text-center align-middle table-cell">
												{registerButton(data)}
                        {/* {deleteButton(data)} */}
											</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default Shop