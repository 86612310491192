import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import Select from 'react-select'
import chroma from 'chroma-js';
import { generatePath } from 'react-router';

const Setting = (props) => {
  const { user } = props;
  const history = useHistory();
  const [lockData, setLockData] = useState(false);
  const { addToast } = useToasts();
  const [settingData, setSettingData] = useState(null);
  const [settingDataOld, setSettingDataOld] = useState(null);
  const [settingDataError, setSettingDataError] = useState({});
  const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  const refreshSetting = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const reg_params = {
      "site_id": props.match.params.siteId,
      "operator": "and",
      "where": [{ "site_id": props.match.params.siteId }]
    }
    const result = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/setting/search/`, reg_params, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    var data = result.filter(res => res.site_id === Number(props.match.params.siteId) && res.setting_name).map(item =>  ({...item,input_list : item.input_list? JSON.parse(item.input_list) : null})); // setting_nameが含まれているもののみ設定可能
    data.sort((a, b) => a.setting_order < b.setting_order ? -1 : 1); // 昇順ソート
    setSettingData(data);
    setSettingDataOld(data);
  }, [props.match.params.siteId])

  // value値変更イベント
  const handleChangeCell = (index, key) => (event) => {
    const _settingData = [...settingData]
    const isUpdate = !checkEqual(settingDataOld[index][key], event.target.value);
    _settingData[index] = { ..._settingData[index], [key]: event.target.value, isUpdate: isUpdate }
    setSettingData(_settingData)
    const _settingDataError = { ...settingDataError, [_settingData[index].setting_type]: validation(index, event.target.value) }
    setSettingDataError(_settingDataError)
  }

  // 「テーマカラー」変更イベント（セレクト）
  const handleChangeSelect = (index, key, data) => {
    const _settingData = [...settingData]
    const isUpdate = !checkEqual(settingDataOld[index][key], data.value);
    _settingData[index] = { ..._settingData[index], [key]: data.value, isUpdate: isUpdate }
    setSettingData(_settingData);
  }

  //入力形式チェック
  const validation = (index, value) => {
    const _setting = settingData[index];
    if (!_setting.regex_pattern) return true;//設定がない場合はOK
    var regex = new RegExp(_setting.regex_pattern, _setting.regex_flags ? _setting.regex_flags : undefined);
    return (regex.test(value));
  }
  const checkEqual = (value1, value2) => {
    var val1 = (value1 === null || value1 === undefined) ? "" : value1.toString();
    var val2 = (value2 === null || value2 === undefined) ? "" : value2.toString();
    return (val1 === val2)
  }
  // 更新ボタン：処理
  const handleUpdateClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    // 確認メッセージ
    if (!window.confirm(`設定マスタを更新します。よろしいですか？`)) {
      return;
    }
    let res;
    try {
      setLockData(true);
      if (data.isUpdate === true) {
        let params = {
          setting_type: data.setting_type,
          setting_val: data.setting_val,
        };
        res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/setting/update/${props.match.params.siteId}/${data.setting_type}`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
      };
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('更新しました。', { appearance: 'success', autoDismiss: true });
        await refreshSetting();
      }
      setLockData(false);
    }
  }

  // 更新ボタン
  const updateButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || !data.isUpdate || (data.setting_type in settingDataError && settingDataError[data.setting_type] !== true)}
        className={`btn btn-primary mx-1 text-nowrap`}
        onClick={() => handleUpdateClick(data)}>
        更新
      </button>
    )
  }

  // カラースタイルリスト
  const notColorStyle = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        cursor: isDisabled ? 'not-allowed' : 'default',
        ':active': {
          ...styles[':active'],
        },
        alignItems: 'center', display: 'flex'
      };
    },
    input: styles => ({ ...styles, alignItems: 'center', display: 'flex' }),
    placeholder: styles => ({ ...styles, alignItems: 'center', display: 'flex' }),
    singleValue: (styles, { data }) => ({ ...styles, alignItems: 'center', display: 'flex' }),
  };
  const colorStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled ? null : isSelected ? data.color : isFocused ? color.alpha(0.1).css() : null,
        color: isDisabled ? '#ccc' : isSelected ? chroma.contrast(color, 'white') > 2 ? 'white' : 'black' : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        },
        ...dot(data.color)
      };
    },
    input: styles => ({ ...styles, ...dot() }),
    placeholder: styles => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  };

  // 初期実行トリガー
  useEffect(() => {
    if (user.userClass === constClass.CLASS.CHECK) {
      history.replace(generatePath(`${props.match.path}checkin`, { siteId: props.match.params.siteId }));
    }
    async function fetchData() {
      await refreshSetting();
    }
    return fetchData();
  }, [user.userClass, props.match.path, history, props.match.params.siteId, refreshSetting]);

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {settingData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {settingData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-${constClass.COLOR.BUS}`}>
                <tr>
                  <td className="text-center">
                    設定名称
                  </td>
                  <td className="text-center">
                    設定備考
                  </td>
                  <td className="text-center w-50">
                    設定値
                  </td>
                  <td className="text-center">
                    処理
                  </td>
                </tr>
              </thead>
              <tbody>
                {settingData.map((data, idx) => (
                  // {orderData.filter(o => o[constClass.COLUMN.CLASS] === String(user.userClass)).map((data, idx) => (
                  <tr key={data.setting_type}>
                    {/* 設定名称 */}
                    {data.setting_type !== "THEME_STYLE" && (
                      <td className="text-left align-middle">
                        {data.setting_name}
                      </td>
                    )}
                    {data.setting_type === "THEME_STYLE" && (
                      <td className="text-left align-middle"
                        ref={(el) => {
                          if (el) {
                            el.style.setProperty('color', data.setting_val);
                            el.style.setProperty('background-color', chroma(data.setting_val).alpha(0.3).css(), 'important');
                          }
                        }}>
                        {data.setting_name}
                      </td>
                    )}
                    {/* 設定備考 */}
                    <td className="text-left align-middle text-prewrap">
                      {data.setting_note}
                    </td>
                    {/* 設定値 */}
                    <td className="text-right align-middle">
                      {(data.setting_type !== "THEME_STYLE" && (!data.input_list || !data.input_list.input_type)) && (
                        <textarea rows={1} className={`form-control${(data.setting_type in settingDataError && settingDataError[data.setting_type] !== true) ? ' is-invalid' : ""}`} id={"setting_val" + data.setting_type} name="setting_val[]" value={data.setting_val || ""} onChange={handleChangeCell(idx, 'setting_val')}></textarea>
                      )}
                      {(data.setting_type === "THEME_STYLE" && (!data.input_list || !data.input_list.input_type)) && (
                        <Select
                          name="setting_val"
                          id={"setting_val"}
                          defaultValue={constClass.THEME_STYLE_COLOR.find(r => r.value === data.setting_val) || ''}
                          label="テーマカラー"
                          options={constClass.THEME_STYLE_COLOR}
                          styles={colorStyles}
                          onChange={(e) => handleChangeSelect(idx, 'setting_val', e)}
                        />
                      )}
                      {(data.input_list && data.input_list.input_type === 'radio') &&
                        <div className='row px-2'>
                          {data.input_list.options.map((item, optionidx) =>
                            <label className='px-2' key={optionidx}>
                              <input type="radio" className="mx-2" id={`setting_val_${data.setting_type}_${optionidx}`} name={`setting_val_${data.setting_type}`} onChange={handleChangeCell(idx, 'setting_val')} value={item.value} checked={checkEqual(item.value, data.setting_val)} />
                              {item.label}
                            </label>
                          )}
                        </div>
                      }
                      {(data.input_list && data.input_list.input_type === 'select') &&
                        <Select
                          name={`setting_val_${data.setting_type}`}
                          id={`setting_val_${data.setting_type}`}
                          defaultValue={data.input_list.options.find(r => r.value === data.setting_val) || ''}
                          label={data.setting_name}
                          options={data.input_list.options}
                          styles={data.input_list.options_color ? colorStyles : notColorStyle}
                          onChange={(e) => handleChangeSelect(idx, 'setting_val', e)}
                        />
                      }
                      {(data.setting_type in settingDataError && settingDataError[data.setting_type] !== true) && <div className='w-100 text-left text-danger'>入力内容が間違っています。</div>}
                    </td>
                    {/* 処理 */}
                    <td className="text-center align-middle">
                      {updateButton(data)}
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default Setting