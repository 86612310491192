import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
// import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import Common from '../Common/common.js';

const User = (props) => {
  const { user, settings } = props;
  const [areaId, setAreaId] = useState(null);
  const [shopId, setShopId] = useState("");
  const [userData, setUserData] = useState(null);
  const [areaMaster, setAreaMaster] = useState([]);
  const [shopMaster, setShopMaster] = useState([]);
  const [settingData, setSettingData] = useState([]);
  const [productDeptData, setProductDeptData] = useState(null);
  const [userNew, setUserNew] = useState({ user_id: '', user_class: constClass.CLASS.SHOP, shop_id: 0, user_name: '', user_short: '', password: '', mail_address: '', product_dept_code: null, upd_date: null });
  const [lockData, setLockData] = useState(false);
  const [errCheckMsg, setErrCheckMsg] = useState({});
  const { addToast } = useToasts();

  const refreshUser = useCallback(async () => {
    setUserData(null);
    const jwt = localStorage.getItem('jwt');
    const reg_params = {
      "site_id": props.match.params.siteId,
      "operator": "and",
      "where": [{ "site_id": props.match.params.siteId }]
    }
    // 非管理者は自分のユーザのみ
    if (user.userClass !== constClass.CLASS.ADMIN || user.productDeptCode !== null) {
      reg_params.where.push({ "user_id": user.userId });
    }
    // userデータ取得
    var data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/search/${props.match.params.siteId}`, reg_params, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    // 新規ユーザ初期化
    const new_data = { user_id: '', user_class: constClass.CLASS.SHOP, shop_id: 0, user_name: '', user_short: '', password: '', mail_address: '', upd_date: null, product_dept_code: null, updflg: false };
    setUserNew(new_data);
    // 地区マスタ設定
    const area = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/area/search/`, { site_id: props.match.params.siteId }, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    setAreaMaster(area);
    // 店舗マスタ設定
    const shop = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/shop/search/`, { site_id: props.match.params.siteId }, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    setShopMaster(shop);
    // 設定マスタ読み込み
    const setting = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/setting/${props.match.params.siteId}`)).data;
    setSettingData(setting)
    const dept = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/productdept/search/`, { site_id: props.match.params.siteId }, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    dept.sort((a, b) => a.product_dept_code - b.product_dept_code);
    setProductDeptData(dept);
    // 非管理者の権限設定
    if (user.userClass !== constClass.CLASS.ADMIN && shopId !== String(user.shopId)) {
      setShopId(String(user.shopId));
    }
    // 地区絞り込み
    if (areaId) {
      data = data.filter(s => !areaId || shop.filter(a => a.area_id === Number(areaId)).map(m => m.shop_id).includes(s.shop_id));
    }
    // 店舗絞り込み
    if (shopId !== "") {
      data = data.filter(s => shopId === "" || s.shop_id === Number(shopId));
    }
    data.sort((a, b) => a.shop_id !== b.shop_id ? a.shop_id - b.shop_id : a.user_id > b.user_id ? 1 : -1);
    setUserData(data);
    // エラーメッセージ初期化
    setErrCheckMsg({});
  }, [props.match.params.siteId, user.userClass, user.userId, user.shopId, areaId, shopId, user.productDeptCode])

  // 登録ボタン：処理
  const handleUserRegistClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!data.user_name) {
      window.alert('ユーザ名を入力してください。');
      return;
    }
    if (data.upd_date === null && (!data.user_id || !data.password)) {
      window.alert('新規登録時はユーザID・パスワードを入力してください。');
      return;
    }
    if(data.user_id && !(data.user_id.length <= 20)){
      window.alert('ユーザIDは20文字以内で入力してください');
      return;
    }
    if(data.password && !(data.password.length <= 20)){
      window.alert('パスワードは20文字以内で入力してください');
      return;
    }
    if(data.user_name && !(data.user_name.length <= 50)){
      window.alert('ユーザ名は50文字以内で入力してください');
      return;
    }
    if(data.mail_address && !(data.mail_address.length <= 120)){
      window.alert('メールアドレスは120文字以内で入力してください');
      return;
    }
    if (data.user_id && data.user_id.trim().match(/\s+/g)) {
      window.alert('ユーザIDに空白文字は使用できません。');
      return;
    }
    if (data.password && data.password.match(/\s+/g)) {
      window.alert('パスワードに空白文字は使用できません。');
      return;
    }
    if(data.password && data.password.match(/[^a-zA-Z0-9\-/:;()\\&@".,?!'[\]{}#%^*+=_|~<>$`€£•]+/g)){
      window.alert('パスワードは半角英数記号で入力してください');
      return;
    }
    //(data.password && data.password.match(/\s+/g))
    const params = {
      site_id: props.match.params.siteId,
      user_id: data.user_id.trim(),
      user_class: data.user_class,
      shop_id: shopId === "" ? data.shop_id : shopId,
      user_name: data.user_name,
      user_short: data.user_short,
      password: data.password ? data.password : null,
      mail_address: data.mail_address,
      product_dept_code: data.product_dept_code ? data.product_dept_code : null,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshUser();
      }
      setLockData(false);
    }
  }

  // 削除ボタン：処理
  const handleUserDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!window.confirm(`ユーザ[${data.user_id}:${data.user_name}]を削除します。よろしいですか？`)) {
      return;
    }
    const params = {
      site_id: props.match.params.siteId,
      user_id: data.user_id,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/delete/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        await refreshUser();
      }
      setLockData(false);
    }
  }

  // 削除ボタン：処理
  const handleUserUnlockClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    const params = {
      site_id: props.match.params.siteId,
      user_id: data.user_id
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/unlock/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
      addToast('ロックを解除しました。', { appearance: 'success', autoDismiss: true });
      await refreshUser();
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      }
      setLockData(false);
    }

  }

  // 削除ボタン：処理
  const handleUserMfaClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!window.confirm(`ユーザ[${data.user_id}:${data.user_name}]の二要素認証(MFA)を削除します。\n次回ログイン時にアプリの登録が必要です。\n削除してもよろしいですか？`)) {
      return;
    }
    const params = {
      site_id: props.match.params.siteId,
      user_id: data.user_id
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/mfa/delete/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
      addToast('MFA登録を削除しました。', { appearance: 'success', autoDismiss: true });
      await refreshUser();
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } 
      setLockData(false);
    }

  }

  // 「エリア」変更イベント（セレクト）
  const handleChangeArea = (e) => {
    const target = e.target;
    const value = target.value;
    setAreaId(value);
  }

  // 「店舗」変更イベント（セレクト）
  const handleChangeShop = (e) => {
    const target = e.target;
    const value = target.value;
    setShopId(value);
  }

  // value値変更イベント
  const handleChangeCell = (index, key) => (event) => {
    if (index === 'new') {
      const new_data = { ...userNew, [key]: event.target.value };
      setUserNew(new_data);
    } else {
      const _userData = [...userData];
      _userData[index] = { ..._userData[index], [key]: event.target.value };
      setUserData(_userData);
    }
    if (key === 'mail_address') {
      setErrCheckMsg({...errCheckMsg, [index]: mailAddressValidation(event.target.value)});
    }
  }
  
  const mailAddressValidation = (value) => {
    if (value === '') return '';
    const regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
    if (!regex.test(value)) return '※正しいメールアドレスを入力してください';
    return '';
  }

  // 登録ボタン
  const renderRegistButton = (data, idx) => {
    return (
      <button type="button"
        disabled={lockData || !!errCheckMsg[idx]}
        className={`btn btn-primary mx-1 text-nowrap`}
        onClick={() => handleUserRegistClick(data)}>
        登録
      </button>
    )
  }

  // 削除ボタン
  const renderDeleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.user_id === null}
        className={`btn btn-primary mx-1 text-nowrap`}
        onClick={() => handleUserDeleteClick(data)}>
        削除
      </button>
    )
  }

  // ロック解除ボタン
  const renderUnlockButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.time_id === null}
        className={`btn btn-primary mx-1`}
        onClick={() => handleUserUnlockClick(data)}>
        ロック解除
      </button>
    )
  }

  // MFA削除ボタン
  const renderMfaButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.time_id === null}
        className={`btn btn-secondary mx-1`}
        onClick={() => handleUserMfaClick(data)}>
        MFA登録削除
      </button>
    )
  }

  // 初期実行トリガー
  useEffect(() => {
    async function fetchData() {
      await refreshUser();
    }
    return fetchData();
  }, [areaId, refreshUser]);

  return (
    <div className="container">
      {(user.userClass === constClass.CLASS.ADMIN && user.productDeptCode === null) && // 店舗の絞り込みは管理者のみ
        <div className="row my-2 ">
          <div className="col-2 text-center line-height-2-2">
            <span className="d-inline-block align-middle">{Common.getSettingValue(settings, 'SHOP_NAME', '店舗')}エリア</span>
          </div>
          <div className="col-3 text-center line-height-2-2">
            <select className="custom-select w-100" aria-label="エリア" name="area_id" value={areaId || ''} onChange={handleChangeArea}>
              <option value="">- 全て -</option>
              {areaMaster.map((item) =>
                <option key={item.area_id} value={item.area_id}>{item.area_name}</option>
              )}
            </select>
          </div>
          <div className="col-2 text-center line-height-2-2">
            <span className="d-inline-block align-middle">{Common.getSettingValue(settings, 'SHOP_NAME', '店舗')}</span>
          </div>
          <div className="col-3 text-center line-height-2-2">
            <select className="custom-select w-100" aria-label="店舗" name="shop_id" value={shopId} onChange={handleChangeShop}>
              <option value="">- 全て -</option>
              {shopMaster.filter(s => !areaId || s.area_id === Number(areaId)).map((item) =>
                <option key={item.shop_id} value={item.shop_id}>{item.shop_name}</option>
              )}
            </select>
          </div>
        </div>
      }

      {userData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {userData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-${constClass.COLOR[user.userClass]}`}>
                <tr>
                  <td className="text-center">
                    <span className='needmark'>※</span>
                      ユーザID
                  </td>
                  <td className="text-center">
                    <span className='needmark'>※</span>
                      パスワード
                  </td>
                  <td className="text-center">
                    <span className='needmark'>※</span>
                      ユーザ区分
                  </td>
                  {shopId === "" &&
                    <td className="text-center">
                      <span className='needmark'>※</span>
                        {Common.getSettingValue(settings, 'SHOP_NAME', '店舗')}
                    </td>
                  }
                  <td className="text-center">
                    <span className='needmark'>※</span>
                      ユーザ名
                  </td>
                  {/* <td className="text-center">
                    ユーザ名略称
                  </td> */}
                  <td className="text-center">
                    メールアドレス
                  </td>
                  {Common.getSettingValue(settingData, 'USER_DEPT', '') === '1' &&
                    <td className="text-center">
                      {Common.getSettingValue(settings, 'ORDER_DEPT_NAME', '商品デプト')}
                    </td>
                  }
                  <td className="text-center">
                    更新日時
                  </td>
                  <td className="text-center">
                    処理
                  </td>
                </tr>
              </thead>
              <tbody>
                {/* 新規入力行 管理者のみ表示 */}
                {(user.userClass === constClass.CLASS.ADMIN && user.productDeptCode === null) &&
                  <tr key="new">
                    {/* ユーザID */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"user_id_new"} name="user_id[]" value={userNew.user_id} onChange={handleChangeCell('new', 'user_id')} size="15"></input>
                    </td>
                    {/* パスワード */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"password_new"} name="password[]" value={userNew.password} onChange={handleChangeCell('new', 'password')} size="15"></input>
                    </td>
                    {/* ユーザ区分 */}
                    <td className="text-center align-middle">
                      <select className="form-control" name="user_class[]" id={"user_class_new"} value={userNew.user_class} onChange={handleChangeCell('new', 'user_class')}>
                        <option value={constClass.CLASS.SHOP}>{constClass.CLASS_NAME.SHOP}</option>
                        <option value={constClass.CLASS.ADMIN}>{constClass.CLASS_NAME.ADMIN}</option>
                        <option value={constClass.CLASS.READER}>{constClass.CLASS_NAME.READER}</option>
                      </select>
                    </td>
                    {/* 店舗 */}
                    {shopId === "" &&
                      <td className="text-center align-middle">
                        <select className="form-control" name="shop_id[]" id={"shop_id_new"} value={userNew.shop_id} onChange={handleChangeCell('new', 'shop_id')}>
                          {shopMaster.filter(s => !areaId || s.area_id === Number(areaId)).map((data2, idx2) => (
                            <option key={data2.shop_id} value={data2.shop_id} >{data2.shop_name}</option>
                          ))}</select>
                      </td>
                    }
                    {/* ユーザ名 */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"user_name_new"} name="user_name[]" value={userNew.user_name} onChange={handleChangeCell('new', 'user_name')}></input>
                    </td>
                    {/* ユーザ名略称 */}
                    {/* <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"user_short_new"} name="user_short[]" value={userNew.user_short} onChange={handleChangeCell('new', 'user_short')}></input>
                  </td> */}
                    {/* メールアドレス */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"mail_address_new"} name="mail_address[]" value={userNew.mail_address} onChange={handleChangeCell('new', 'mail_address')}></input>
                      {!!errCheckMsg['new'] && <p className="text-danger mb-0">{errCheckMsg['new']}</p>}
                    </td>
                    {/* 商品デプトコード */}
                    {Common.getSettingValue(settingData, 'USER_DEPT', '') === '1' &&
                      <td className="text-center align-middle">
                        <select className="form-control" id={"product_dept_code_new"} name="product_dept_code[]" value={userNew.product_dept_code} onChange={handleChangeCell('new', 'product_dept_code')}>
                        <option value="">- 選択 -</option>
                          {productDeptData.map((data2, idx2) => (
                            <option key={data2.product_dept_code} value={data2.product_dept_code} >{data2.product_dept_name}</option>
                          ))}</select>
                      </td>
                    }
                    {/* 更新日時 */}
                    <td className="text-center align-middle">
                    </td>
                    {/* 処理 */}
                    <td className="text-center align-middle">
                      {renderRegistButton(userNew, 'new')}
                    </td>
                  </tr>
                }
                {userData.map((data, idx) => (
                  <tr key={idx}>
                    {/* ユーザID */}
                    <td className="text-center align-middle">
                      {data.user_id}
                    </td>
                    {/* パスワード */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"password" + idx} name="password[]" value={data.password} onChange={handleChangeCell(idx, 'password')} size="15"></input>
                    </td>
                    {/* ユーザ区分 */}
                    <td className="text-center align-middle">
                      <select className="form-control" name="user_class[]" id={"user_class" + idx} value={data.user_class} onChange={handleChangeCell(idx, 'user_class')} disabled={user.userClass !== constClass.CLASS.ADMIN}>
                        <option value={constClass.CLASS.SHOP}>{constClass.CLASS_NAME.SHOP}</option>
                        <option value={constClass.CLASS.ADMIN}>{constClass.CLASS_NAME.ADMIN}</option>
                        <option value={constClass.CLASS.READER}>{constClass.CLASS_NAME.READER}</option>
                      </select>
                    </td>
                    {/* 店舗 */}
                    {shopId === "" &&
                      <td className="text-center align-middle">
                        <select className="form-control" name="shop_id[]" id={"shop_id" + idx} value={data.shop_id} onChange={handleChangeCell(idx, 'shop_id')} disabled={user.userClass !== constClass.CLASS.ADMIN}>
                          {shopMaster.filter(s => !areaId || s.area_id === Number(areaId)).map((data2, idx2) => (
                            <option key={data2.shop_id} value={data2.shop_id} >{data2.shop_name}</option>
                          ))}</select>
                      </td>
                    }
                    {/* ユーザ名 */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"user_name" + idx} name="user_name[]" value={data.user_name} onChange={handleChangeCell(idx, 'user_name')} disabled={user.userClass !== constClass.CLASS.ADMIN}></input>
                    </td>
                    {/* ユーザ名略称 */}
                    {/* <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"user_short" + idx} name="user_short[]" value={data.user_short} onChange={handleChangeCell(idx, 'user_short')}></input>
                    </td> */}
                    {/* メールアドレス */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"mail_address" + idx} name="mail_address[]" value={data.mail_address || ''} onChange={handleChangeCell(idx, 'mail_address')}></input>
                      {!!errCheckMsg[idx] && <p className="text-danger mb-0">{errCheckMsg[idx]}</p>}
                    </td>
                    {/* 商品デプトコード */}
                    {Common.getSettingValue(settingData, 'USER_DEPT', '') === '1' &&
                      <td className="text-center align-middle">
                        <select className="form-control" id={"product_dept_code" + idx} name="product_dept_code[]" value={data.product_dept_code} onChange={handleChangeCell(idx, 'product_dept_code')}>
                        <option value="">- 選択 -</option>
                          {productDeptData.map((data2, idx2) => (
                            <option key={data2.product_dept_code} value={data2.product_dept_code} >{data2.product_dept_name}</option>
                          ))}</select>
                      </td>
                    }
                    {/* 更新日時 */}
                    <td className="text-center align-middle">
                      {data.upd_date ? moment(data.upd_date).format('YYYY-MM-DD HH:mm:ss') : ''}
                    </td>
                    {/* 処理 */}
                    <td className="text-center align-middle">
                      {renderRegistButton(data, idx)}
                      {data.user_id !== user.userId && renderDeleteButton(data)}
                      {(Common.getSettingValue(settings, 'LOGIN_FAIL_COUNT', '') && data.fail_count >= Number(Common.getSettingValue(settings, 'LOGIN_FAIL_COUNT', ''))) && renderUnlockButton(data)}
                      {(Common.getSettingValue(settings, 'MFA_USE', '0') === constClass.FLAG.ON && data.secret_count >= 1) && renderMfaButton(data)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default User;