import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
// import util from 'util';
import constClass from '../../Constants/Constants';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { CSVLink } from "react-csv";
import { JobCdName } from '../Payment/Payment';
import Barcode from 'react-barcode';
import { generatePath } from 'react-router';
import Common from '../Common/common.js';

const BackyardOrderPaymentList = (props) => {
  const { user, condition, conditionActions, settings } = props;
  const [orderData, setOrderData] = useState(null);
  const [lockData, setLockData] = useState(false);
  const [sumCount, setSumCount] = useState(0); //合計点数
  const [sumTotal, setSumTotal] = useState(0); //合計金額
  const [orderCount, setOrderCount] = useState(0); //注文件数
  //const [sortOrder, setSortOrder] = useState([]); // ソート順指定
  const { addToast } = useToasts();
  const componentRef = useRef();
  const [csvPaymentData, setCsvPaymentData] = useState(null);
  const paymentDataRef = useRef();

  const sortData = useCallback((a, b) => {
    if (condition.sortOrder.length <= 0) { // 初期値は受取日→受取時間
      return (a.receive_date === b.receive_date) ? Number(a.receive_time) - Number(b.receive_time) : new Date(a.receive_date) - new Date(b.receive_date);
    }
    for (var i = 0; i < condition.sortOrder.length; i++) {
      var item = condition.sortOrder[i];
      if (item.column === 't_order_detail') {
        if (item.order === 'ASC') {
          if (a.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0) < b.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0)) return -1;
          if (a.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0) > b.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0)) return 1;
        } else {
          if (b.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0) < a.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0)) return -1;
          if (b.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0) > a.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0)) return 1;
        }
      } else if (item.column === 'status') {
        const aStatus = a.status === constClass.STATUS.UPAY ? 1 : a.status === constClass.STATUS.REG ? 2 : a.status === constClass.STATUS.PRE ? 3 : a.status === constClass.STATUS.FIN ? 4 : a.status === constClass.STATUS.DFIN ? 5 : 6;
        const bStatus = b.status === constClass.STATUS.UPAY ? 1 : b.status === constClass.STATUS.REG ? 2 : b.status === constClass.STATUS.PRE ? 3 : b.status === constClass.STATUS.FIN ? 4 : b.status === constClass.STATUS.DFIN ? 5 : 6;
        if (item.order === 'ASC') {
          if (aStatus < bStatus) return -1;
          if (aStatus > bStatus) return 1;
        } else {
          if (bStatus < aStatus) return -1;
          if (bStatus > aStatus) return 1;
        }
      } else if (item.column === 'total' || item.column === 'receipt_num') {
        if (item.order === 'ASC') {
          if (Number(a[item.column]) < Number(b[item.column])) return -1;
          if (Number(a[item.column]) > Number(b[item.column])) return 1;
        } else {
          if (Number(b[item.column]) < Number(a[item.column])) return -1;
          if (Number(b[item.column]) > Number(a[item.column])) return 1;
        }
      } else if (item.column === 'employee_flag') {
        const aFlag = a.employee_flag === '1' ? 0 : 1;
        const bFlag = b.employee_flag === '1' ? 0 : 1;
        if (item.order === 'ASC') {
          if (aFlag < bFlag) return -1;
          if (aFlag > bFlag) return 1;
        } else {
          if (bFlag < aFlag) return -1;
          if (bFlag > aFlag) return 1;
        }
      } else {
        if (item.order === 'ASC') {
          if (a[item.column] < b[item.column]) return -1;
          if (a[item.column] > b[item.column]) return 1;
        } else {
          if (b[item.column] < a[item.column]) return -1;
          if (b[item.column] > a[item.column]) return 1;
        }
      }
    }
    return 0;
  }, [condition.sortOrder]);

  const refreshOrder = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    if (condition.isReload) {
      // console.log(props);
      var reg_params = {};
      reg_params = {
        "site_id": props.match.params.siteId,
        "operator": "and",
        "where": [
          { "site_id": props.match.params.siteId }
        ]
      }
      // 店舗
      if (user.userClass !== constClass.CLASS.ADMIN && user.userClass !== constClass.CLASS.READER) {
        //店舗ユーザは自分の店舗のみ
        reg_params.where.push({ "receive_shop": user.shopId });
      } else if (condition.shop !== "") {
        reg_params.where.push({ "receive_shop": condition.shop });
      }
      // 企画
      if (condition.plan !== "") {
        reg_params.where.push({ "plan_id": condition.plan });
      }
      // 受取日
      if (condition.receiveDate !== null) {
        reg_params.where.push({
          "operator": "in",
          "attr": "receive_date",
          "val": condition.receiveDate
        });
      }
      // 受取日
      if (condition.status !== null) {
        reg_params.where.push({
          "operator": "in",
          "attr": "status",
          "val": condition.status
        });
      }
      // // 受取日(開始)
      // if (props.condition.startDate) {
      //   reg_params.where.push({
      //     "operator": "gte",
      //     "attr": "receive_date",
      //     "val": props.condition.startDate
      //   });

      //   // 受取日(終了)
      //   if (props.condition.endDate) {
      //     reg_params.where.push({
      //       "operator": "lte",
      //       "attr": "receive_date",
      //       "val": props.condition.endDate
      //     });
      //   } else {
      //     reg_params.where.push({
      //       "operator": "lte",
      //       "attr": "receive_date",
      //       "val": props.condition.startDate
      //     });
      //   }
      // }

      // 事前決済のみ
      reg_params.where.push({
        "payment_flag": constClass.FLAG.ON
      });

      const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/search/detail/`, reg_params, { // 注文一覧取得
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      })).data;

      var sc = 0;
      var st = 0;
      data.filter(d => ![constClass.STATUS.CCL, constClass.STATUS.CUPAY].includes(d.status)).forEach(d => {
        st += Math.floor(d.total);
        sc += d.t_order_detail.reduce((sum, element) => sum + element.count, 0);
      });
      setSumCount(sc);
      setSumTotal(st);
      setOrderCount(data.filter(d => ![constClass.STATUS.CCL, constClass.STATUS.CUPAY].includes(d.status)).length);
      // data.sort((a, b) => (a.receive_date === b.receive_date) ? Number(a.receive_time) - Number(b.receive_time) : new Date(a.receive_date) - new Date(b.receive_date));
      // data.sort((a, b) => sortData(a, b));
      setOrderData(data);
      conditionActions.setIsReload(false);
    }
  }, [props.match.params.siteId, user, condition.shop, condition.plan, condition.receiveDate, condition.status, condition.isReload, conditionActions]);


  const handleHeaderClick = (column) => {
    var _sortOrder = JSON.parse(JSON.stringify(condition.sortOrder));
    if (_sortOrder.map(s => s.column).includes(column)) { // 既に登録済み
      if (_sortOrder[0].column !== column) { // 先頭ではない
        _sortOrder.splice(_sortOrder.findIndex(s => s.column === column), 1); //削除
        _sortOrder.unshift({ column, order: 'ASC' }); // 追加
      } else { //先頭 並び順変更
        _sortOrder[0].order = _sortOrder[0].order === 'ASC' ? 'DESC' : 'ASC';
      }
    } else { //未登録
      _sortOrder.unshift({ column, order: 'ASC' }); // 追加
    }
    if (_sortOrder.length > 3) { //ソート順は3件まで
      _sortOrder.pop();
    }
    conditionActions.setSortOrder(_sortOrder);
    // var data = orderData.slice();
    // data.sort((a, b) => sortData(a, b));
    // setOrderData(data);
  }

  const handleExportPaymentClick = async () => {
    const jwt = localStorage.getItem('jwt');
    const params = {
      site_id: props.match.params.siteId,
      id: orderData.map(o => o.order_id)
    };
    try {
      setLockData(true);
      setCsvPaymentData(null);
      const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/payment_search/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })).data;
      console.log(data);
      if (data.status === 'success') {
        const list = [];
        const head = [];
        head.push({ label: "注文番号", key: "receipt_num" });
        head.push({ label: "お客様名", key: "customer_name" });
        head.push({ label: "カナ", key: "customer_kana" });
        head.push({ label: "受取"+Common.getSettingValue(settings, 'SHOP_NAME', '店舗')+"ID", key: "receive_shop" });
        head.push({ label: "受取"+Common.getSettingValue(settings, 'SHOP_NAME', '店舗'), key: "receive_shop_name" });
        head.push({ label: "企画ID", key: "plan_id" });
        head.push({ label: "企画名", key: "plan_name" });
        head.push({ label: "注文日", key: "ins_date" });
        head.push({ label: "受取日", key: "receive_date" });
        head.push({ label: "種別", key: "order_type" });
        head.push({ label: "小計", key: "subtotal" });
        head.push({ label: "割引額", key: "discount" });
        head.push({ label: "税額", key: "tax" });
        head.push({ label: "税込総額", key: "total" });
        head.push({ label: "商品CD", key: "product_code" });
        head.push({ label: "商品名", key: "product_name" });
        head.push({ label: "JANコード", key: "jan_code" });
        head.push({ label: "商品単価", key: "price" });
        head.push({ label: "商品税率", key: "tax_rate" });
        head.push({ label: "商品早割割引対象", key: "early_discount" });
        head.push({ label: "商品注文数", key: "count" });
        head.push({ label: "ステータス", key: "status" });
        head.push({ label: "取引ID", key: "actual_id" });
        head.push({ label: "決済代行会社", key: "gateway" });
        head.push({ label: "利用金額", key: "amount" });
        head.push({ label: "手数料等", key: "fee" });
        head.push({ label: "決済手段", key: "payment_type" });
        head.push({ label: "処理状況", key: "job_cd" });
        head.push({ label: "処理状況名称", key: "job_cd_name" });
        head.push({ label: "更新日時", key: "upd_date" });
        data.payment.forEach(p => {
          const order = orderData.find(o => String(o.order_id) === p.id);
          var payment = {
            receipt_num: order.receipt_num,
            customer_name: order.customer_name.replace(/"/g, '""'),
            customer_kana: order.customer_kana.replace(/"/g, '""'),
            receive_shop: order.receive_shop,
            receive_shop_name: order.receive_shop_name.replace(/"/g, '""'),
            plan_id: order.plan_id,
            plan_name: order.plan_name.replace(/"/g, '""'),
            ins_date: moment(order.ins_date).format("YYYY/MM/DD HH:mm:ss"),
            receive_date: order.receive_date,
            actual_id: p.actual_id,
            gateway: p.gateway,
            amount: p.amount,
            fee: p.fee,
            payment_type: p.payment_type,
            job_cd: p.job_cd,
            job_cd_name: JobCdName[p.job_cd],
            upd_date: moment(p.upd_date).format("YYYY/MM/DD HH:mm:ss"),
            status: constClass.STATUS_NAME[order.status],
            order_type: order.order_type,
            subtotal: Math.floor(order.subtotal).toLocaleString(),
            discount: Math.floor(order.discount).toLocaleString(),
            tax: Math.floor(order.tax).toLocaleString(),
            total: Math.floor(order.total).toLocaleString(),
          };
          order.t_order_detail.forEach(d => {
            const detail = {
              ...payment,
              product_code: d.product_code,
              product_name: d.product_name.replace(/"/g, '""'),
              jan_code: (d.m_product && d.m_product.jan_code) ? d.m_product.jan_code : '',
              early_discount: d.early_discount === constClass.EARLY_DISCOUNT.ON ? "対象" : "対象外",
              price_before_tax: d.price_before_tax,
              price: d.price,
              tax_rate: d.tax_rate,
              count: d.count
            }
            list.push(detail);
          });
        });

        setCsvPaymentData({ header: head, list: list });
        paymentDataRef.current.link.click();
      } else {
        addToast(data.msg, { appearance: 'error', autoDismiss: true });
      }
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      setLockData(false);
    }
  }

  useEffect(() => {
    refreshOrder();
  }, [refreshOrder]);

  const renderSortTh = (column) =>
    <th className="text-center align-middle like-button p-0 m-0" onClick={() => handleHeaderClick(column)}>
      {condition.sortOrder.filter(s => s.column === column).map((item, idx, self) =>
        <span key={idx}>{item.order === 'ASC' ? '▲' : '▼'}{condition.sortOrder.findIndex(s => s.column === column) + 1}</span>
      )}
    </th>

  return (
    <div className="container">
      {/* <div className="row d-none"><div className="col-12">{util.inspect(orderData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div> */}
      {orderData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {orderData !== null && (
        <div ref={componentRef} className="print-list">
          <div className="row mb-0 p-0 ">
            <div className="col-9 pl-0 text-left align-self-end">
              {props.condition.shop && orderData[0] && <div className="row"><div className="col-12"><h4>{orderData[0].receive_shop_name}</h4></div></div>}
              {props.condition.plan && orderData[0] && <div className="row"><div className="col-12"><h4>{orderData[0].plan_name}</h4></div></div>}
              <div className="row">
                <div className="col-2 pr-2 print-none">
                  {orderData[0] &&
                    <ReactToPrint
                      trigger={() => (
                        <button type="button" className="btn btn-primary px-0 mb-1 w-100">
                          注文情報印刷
                        </button>
                      )}
                      content={() => componentRef.current}
                      pageStyle={""}
                    />}
                </div>
                <div className="col-2 px-2 print-none">
                  {(user.userClass === constClass.CLASS.ADMIN || user.userClass === constClass.CLASS.READER) &&
                    <React.Fragment>
                      <button
                        className="btn btn-primary mb-1 px-0 w-100"
                        disabled={lockData || orderData.length < 1}
                        onClick={() => handleExportPaymentClick()}>
                        決済データ出力
                      </button>
                      {csvPaymentData && <CSVLink data={csvPaymentData.list.sort((a, b) => sortData(a, b))} headers={csvPaymentData.header} filename={"決済データ.csv"} className="d-none" ref={paymentDataRef} />}
                    </React.Fragment>
                  }
                </div>
                <div className="col-8 align-self-center">
                  {orderData.filter(o => [constClass.STATUS.CCL, constClass.STATUS.UPAY, constClass.STATUS.CUPAY].includes(o.status)).length > 0 &&
                    <span className="text-danger">※{[...new Set(orderData.filter(o => [constClass.STATUS.CCL, constClass.STATUS.UPAY, constClass.STATUS.CUPAY].includes(o.status)).map(o => constClass.STATUS_NAME[o.status]))].join('、')}のデータが含まれています。</span>
                  }
                </div>
              </div>
            </div>
            <div className="col-3 m-0 p-0 align-self-end">
              <table className="table-borderd table-striped mt-0 mr-0 mb-0 ml-auto p-0">
                <tbody>
                  <tr>
                    <th className="p-0">注文件数</th>
                    <td className="text-right p-0">{orderCount.toLocaleString()} 件</td>
                  </tr>
                  <tr>
                    <th className="p-0">1人当たりの注文点数</th>
                    <td className="text-right p-0">{(Math.round((sumCount / orderCount) * Math.pow(10, 1)) / Math.pow(10, 1)).toLocaleString()} 個</td>
                  </tr>
                  <tr>
                    <th className="p-0">1人当たりの注文単価</th>
                    <td className="text-right p-0">{Math.round((sumTotal / orderCount)).toLocaleString()} 円</td>
                  </tr>
                  <tr>
                    <th className="p-0">合計点数</th>
                    <td className="text-right p-0">{sumCount.toLocaleString()} 個</td>
                  </tr>
                  <tr>
                    <th className="p-0">税込合計金額</th>
                    <td className="text-right p-0">{sumTotal.toLocaleString()} 円</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mb-1 p-0">
            <div className="col-12 p-0">
              <table className="table table-bordered table-striped" height="1">
                <thead className={`table-${constClass.COLOR[user.userClass]}`}>
                  <tr>
                    <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('receipt_num')}>
                      注文<br />番号
                    </th>
                    <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('employee_flag')}>
                      従<br />業<br />員
                    </th>
                    <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('customer_name')}>
                      お客様名
                    </th>
                    {/* <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('customer_kana')}>
                      カナ
                    </th> */}
                    {(!props.condition.shop) && (
                      <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('receive_shop')}>
                        受取{Common.getSettingValue(settings, 'SHOP_NAME', '店舗')}
                      </th>
                    )}
                    {!props.condition.plan && (
                      <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('plan_id')}>
                        企画
                      </th>
                    )}
                    <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('receive_date')}>
                      受取日
                    </th>
                    <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('order_type')}>
                      種別
                    </th>
                    <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('total')}>
                      税込総額
                    </th>
                    <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('t_order_detail')}>
                      注文明細
                    </th>
                    <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('status')}>
                      ステータス
                    </th>
                  </tr>
                  <tr className="print-none">
                    {renderSortTh('receipt_num')}
                    {renderSortTh('employee_flag')}
                    {renderSortTh('customer_name')}
                    {(!props.condition.shop) && (renderSortTh('receive_shop'))}
                    {(!props.condition.plan) && (renderSortTh('plan_id'))}
                    {renderSortTh('receive_date')}
                    {renderSortTh('order_type')}
                    {renderSortTh('total')}
                    {renderSortTh('t_order_detail')}
                    {renderSortTh('status')}
                  </tr>
                </thead>
                <tbody>
                  {orderData.sort((a, b) => sortData(a, b)).map((data, idx) => (
                    <tr key={data.order_id} className={`${data.updated ? 'table-updated' : ''}`}>
                      {/* 注文番号 */}
                      <td className="text-center align-middle p-1">
                        {data.receipt_num}
                      </td>
                      {/* 従業員 */}
                      <td className="text-center align-middle p-1">
                        {data.employee_flag === '1' ? '○' : ''}
                      </td>
                      {/* お客様名 */}
                      <td className="text-center align-middle p-1">
                        <div className="row p-0 m-1">
                          <div className="col text-left align-self-center py-0 pl-0 pr-1 m-0 small">{data.customer_kana}</div>
                        </div>
                        <div className="row p-0 m-1">
                          <Link to={`${generatePath(`${props.match.path}order/input/${data.order_id}`, { siteId: props.match.params.siteId })}`}><div className="col text-left align-self-center py-0 pl-0 pr-1 m-0">{data.customer_name}</div></Link>
                        </div>
                      </td>
                      {/* カナ
                      <td className="text-center align-middle p-1">
                        {data.customer_kana}<br />
                      </td> */}
                      {/* 受取店舗 */}
                      {(!props.condition.shop) && (
                        <td className="text-center align-middle p-1">
                          <div className="row p-0 m-1" key={data.order_id}>
                            <div className="col-auto text-left align-self-center py-0 pl-0 pr-1 m-0">{data.receive_shop}</div>
                            <div className="col text-left align-self-center  p-0 m-0">{data.receive_shop_name}</div>
                          </div>
                        </td>
                      )}
                      {/* 企画 */}
                      {!props.condition.plan && (
                        <td className="text-center align-middle p-1 text-nowrap">
                          {data.plan_name}
                        </td>
                      )}
                      {/* 受取日 */}
                      <td className="text-center align-middle p-1 text-nowrap">
                        {data.receive_date}
                      </td>
                      {/* 種別 */}
                      <td className="text-center align-middle p-1">
                        {data.order_type}
                      </td>
                      {/* 税込総額 */}
                      <td className="text-right align-middle p-1">
                        {Math.floor(data.total).toLocaleString()}
                      </td>
                      {/* 注文明細 */}
                      <td className="text-center align-middle p-0">
                        {data.t_order_detail.sort((a, b) => a.product_id - b.product_id).map((detail, detail_idx) => (
                          <div className="row p-0 m-1" key={data.order_id + '_' + detail.product_id}>
                            <div className="col-auto text-left align-self-center py-0 pl-0 pr-1 m-0">{detail.product_code}</div>
                            <div className="col text-left align-self-center  p-0 m-0">{detail.product_name}</div>
                            {(detail.m_product && detail.m_product.jan_code) &&
                              <div className="col-auto text-left align-self-center  p-0 m-0">
                                <div className="border border-black">
                                  <Barcode value={detail.m_product.jan_code} background={`#ffffff`} marginLeft={20} marginRight={20} format={'EAN13'} />
                                </div>
                              </div>
                            }
                            <div className="col-auto  text-right align-self-center py-0 px-1 m-0"><h3 className="mb-0"><small>×</small>{detail.count}</h3></div>
                          </div>
                        ))
                        }
                      </td>
                      {/* ステータス */}
                      <td className="text-center align-middle p-1">
                        {constClass.STATUS_NAME[data.status]}
                      </td>
                    </tr>
                  ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default BackyardOrderPaymentList;