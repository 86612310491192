import { createSlice } from '@reduxjs/toolkit';
import constClass from '../Constants/Constants';

// Stateの初期状態
const initialState = {
  shop: '',
  plan: '0',
  dept: '',
  receiveDate: null,
  selectReceiveDate: null,
  receiveDateRange: [null, null],
  status: [constClass.STATUS.REG, constClass.STATUS.PRE, constClass.STATUS.FIN, constClass.STATUS.DFIN, constClass.STATUS.CCL],
  selectStatus: [
    { value: constClass.STATUS.REG, label: constClass.STATUS_NAME.REG },
    { value: constClass.STATUS.PRE, label: constClass.STATUS_NAME.PRE },
    { value: constClass.STATUS.FIN, label: constClass.STATUS_NAME.FIN },
    { value: constClass.STATUS.DFIN, label: constClass.STATUS_NAME.DFIN },
    { value: constClass.STATUS.CCL, label: constClass.STATUS_NAME.CCL }
  ],
  isReload: true,
  shopMaster: [],
  planMaster: [],
  productMaster: [],
  deptMaster: [],
  CalendarShopMaster: [],
  sortOrder: [],
  index: 1,
  initFlag: false,
};

const BackyardOrderCondition = createSlice({
  name: "BackyardOrderCondition",
  initialState: initialState,
  reducers: {
    setShop: (state, action) => ({
      ...state,
      shop: action.payload,
      isReload: true,
    }),
    setPlan: (state, action) => ({
      ...state,
      plan: action.payload,
      isReload: true,
    }),
    setDept: (state, action) => ({
      ...state,
      dept: action.payload,
      isReload: true,
    }),
    setSelectReceiveDate: (state, action) => ({
      ...state,
      receiveDate: action.payload ? action.payload.map((item) => item.value) : null,
      selectReceiveDate: action.payload,
      isReload: true,
    }),
    setReceiveDateRange: (state, action) => ({
      ...state,
      receiveDateRange: action.payload,
    }),
    setSelectStatus: (state, action) => ({
      ...state,
      status: action.payload ? action.payload.map((item) => item.value) : null,
      selectStatus: action.payload,
      isReload: true,
    }),
    setIsReload: (state, action) => ({
      ...state,
      isReload: action.payload,
    }),
    setShopMaster: (state, action) => ({
      ...state,
      shopMaster: action.payload,
    }),
    setPlanMaster: (state, action) => ({
      ...state,
      planMaster: action.payload,
    }),
    setProductMaster: (state, action) => ({
      ...state,
      productMaster: action.payload,
    }),
    setDeptMaster: (state, action) => ({
      ...state,
      deptMaster: action.payload,
    }),
    setCalendarShopMaster: (state, action) => ({
      ...state,
      CalendarShopMaster: action.payload,
    }),
    setInitFlag: (state) => ({
      ...state,
      initFlag: true,
    }),
    setSortOrder: (state, action) => ({
      ...state,
      sortOrder: action.payload,
    }),
    setIndex: (state, action) => ({
      ...state,
      index: action.payload,
      sortOrder: [],
      isReload: true,
    }),
    login: (state) => ({
      ...initialState,
    }),
    logout: (state) => ({
      ...initialState,
    })
  }
});

// 個別でも使えるようにActionCreatorsをエクスポートしておく
export const { setShop, setPlan, setDept, setSelectReceiveDate, setReceiveDateRange, setSelectStatus, setIsReload, setShopMaster, setPlanMaster, setProductMaster, setDeptMaster, setCalendarShopMaster, setInitFlag, logout } = BackyardOrderCondition.actions;

export default BackyardOrderCondition;