import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import Reactcalendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Common from '../Common/common.js';

const ShopCalendar = (props) => {
    const { shop, settings } = props;
    const [values, setValues] = useState({shop_id: ""});
    const [shopMaster, setShopMaster] = useState([]);
    const [shopRecive, setShopRecieve] = useState([]);
    const { addToast } = useToasts();

  const refreshShop = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const param = {
        "site_id": props.match.params.siteId,
        "operator": "and",
        "where": [
            { 
                "operator": "eq",
                "attr": "site_id",
                "val": props.match.params.siteId 
            }
        ]
    }
    const shop = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/shop/search/`, param, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    setShopMaster(shop);
    
  },[props.match.params.siteId])

  const changeDate = async (date) => {
    const jwt = localStorage.getItem('jwt');
    date = moment(date).format('YYYYMMDD')
    let res;
    try {
      const params = {
        site_id: props.match.params.siteId,
        shop_id: values.shop_id,
        receive_date: date,
      };
      if(shopRecive.find(x => moment(x.receive_date).format('YYYYMMDD') === moment(date).format('YYYYMMDD'))){
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/shopcalendar/delete`, params, {
            headers: {
              Authorization: `Bearer ${jwt}`,
            }
          });
          addToast('削除しました。', { appearance: 'success', autoDismiss: true });
      }
      else{
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/shopcalendar/`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
      }
      const param = {
        "site_id": props.match.params.siteId,
        "operator": "and",
        "where": [
            { 
                "operator": "eq",
                "attr": "site_id",
                "val": props.match.params.siteId 
            }
        ]
      }
    const shop = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/shopcalendar/${props.match.params.siteId}/${values.shop_id}`, param, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      })).data;
      setShopRecieve(shop);
    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (!res) {
      } else if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } 
    }  
  }

  const getTileContent = (a) => {
    const cal = shopRecive.find(x => moment(x.receive_date).format('YYYYMMDD') === moment(a.date).format('YYYYMMDD'));
    return (
      <p className={`${cal ? "bg-yellow" : ""}`}>
        {cal ? "〇" : ""}
      </p>
    );
  }


      // value値変更イベント
  const handleChange = async (e) => {
    const jwt = localStorage.getItem('jwt');
    const param = {
        "site_id": props.match.params.siteId,
        "operator": "and",
        "where": [
            { 
                "operator": "eq",
                "attr": "site_id",
                "val": props.match.params.siteId 
            }
        ]
      }
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setValues({ ...values, [name]: value });
    if(value === ""){
      refreshShop();
      return;
    }
    const shop = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/shopcalendar/${props.match.params.siteId}/${value}`, param, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    setShopRecieve(shop);
  }
    
  useEffect(() => {
    async function fetchData() {
      await refreshShop();
    }
    return fetchData();
  }, [refreshShop]);

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(shop)}</div></div>
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
          <div className="row mx-0 my-3 text-center">
          <div className="col-1 text-center align-self-center">
          <span>受取{Common.getSettingValue(settings, 'SHOP_NAME', '店舗')}</span>
        </div>
        <div className="col-7 text-center align-self-center">
          <select className={`custom-select w-100 `} aria-label="店舗" name="shop_id" value={values.shop_id || ""} onChange={handleChange} required>
            <option value="">- 選択 -</option>
            {shopMaster.filter(s => s.shop_id > 0).map((item) => // 受取店舗 本社を除く
              <option key={item.shop_id} value={item.shop_id}>{item.shop_name}</option>
            )}
          </select>
        </div>
          </div>
          </div>
          </div>
          {values.shop_id !== "" &&
        <Reactcalendar 
          locale="ja-JP" 
          calendarType="US"
          onChange={changeDate}
          tileContent={getTileContent}
          className="w-100"
        />
          }
    </div>
  )
}

export default ShopCalendar