import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import ReactToPrint from 'react-to-print';
import { CSVLink } from "react-csv";
import moment from 'moment';

// 注文一覧（商品別）
const BackyardOrderDept = (props) => {
  const { user, condition, conditionActions } = props;
  const [orderData, setOrderData] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const componentRef = useRef();

  const sortData = useCallback((a, b) => {
    if (condition.sortOrder.length <= 0) { // 初期値は企画・商品・受取日
      return ((a.receive_date !== b.receive_date) ? new Date(a.receive_date) - new Date(b.receive_date) :
        (a.plan_id !== b.plan_id) ? Number(a.plan_id) - Number(b.plan_id) :
          Number(a.product_id) - Number(b.product_id))
    }
    for (var i = 0; i < condition.sortOrder.length; i++) {
      var item = condition.sortOrder[i];
      if (item.column === 'receive_date') {
        if (item.order === 'ASC') {
          if (new Date(a[item.column]) < new Date(b[item.column])) return -1;
          if (new Date(a[item.column]) > new Date(b[item.column])) return 1;
        } else {
          if (new Date(b[item.column]) < new Date(a[item.column])) return -1;
          if (new Date(b[item.column]) > new Date(a[item.column])) return 1;
        }
      } else {
        if (item.order === 'ASC') {
          if (a[item.column] < b[item.column]) return -1;
          if (a[item.column] > b[item.column]) return 1;
        } else {
          if (b[item.column] < a[item.column]) return -1;
          if (b[item.column] > a[item.column]) return 1;
        }
      }
    }
    return 0;
  }, [condition.sortOrder]);

  // 「注文データ出力」data
  const createCsvdata = (data) => {
    var list = [];
    var head = [];

    head.push({ label: "企画名", key: "plan_name" });
    head.push({ label: "商品カテゴリ", key: "product_category_name" });
    head.push({ label: "商品CD", key: "product_code" });
    head.push({ label: "商品名", key: "product_name" });
    head.push({ label: "注文数", key: "count" });
    head.push({ label: "受取日", key: "receive_date" });
    data.forEach(p => {
      var product = {
        plan_name: p.plan_name.replace(/"/g, '""'),
        product_category_name: p.product_category_name.replace(/"/g, '""'),
        product_code: p.product_code,
        product_name: p.product_name.replace(/"/g, '""'),
        count: p.count,
        receive_date: p.receive_date,
      };
      list.push(product);
    });

    setCsvData({ header: head, list: list });
  };

  const refreshOrder = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    if (condition.isReload) {
      var reg_params = {};
      reg_params = {
        "site_id": props.match.params.siteId,
        "operator": "and",
        "where": [{ "site_id": props.match.params.siteId }]
      }

      // 店舗
      if (user.userClass !== constClass.CLASS.ADMIN) {
        //店舗ユーザは自分の店舗のみ
        reg_params.where.push({ "receive_shop": user.shopId });
      } else if (condition.shop !== "") {
        reg_params.where.push({ "receive_shop": condition.shop });
      }
      // 企画
      if (condition.plan !== "") {
        reg_params.where.push({ "plan_id": condition.plan });
      }
      // 受取日
      if (condition.receiveDate !== null) {
        reg_params.where.push({
          "operator": "in",
          "attr": "receive_date",
          "val": condition.receiveDate
        });
      }
      // ステータス
      if (condition.status !== null) {
        reg_params.where.push({
          "operator": "in",
          "attr": "status",
          "val": condition.status
        });
      }

      const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/search/detail/`, reg_params, { // 注文一覧取得
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      })).data;

      var list = [];

      data.forEach(p => {
        var order = {
          plan_name: p.plan_name,
          // receive_date: p.receive_date,
        };
        p.t_order_detail.forEach(orderdetail => {
          const idx = list.findIndex(o =>
            o.product_id === orderdetail.product_id
            // && o.receive_date === order.receive_date
          );
          if (idx < 0) {
            var product = {
              idx: list.length + 1
              , product_id: orderdetail.product_id
              , plan_name: order.plan_name
              // , receive_date: order.receive_date
              , product_code: orderdetail.product_code
              , product_name: orderdetail.product_name
              , product_category_name: orderdetail.m_product.order_product_category.product_c_name
              , product_dept_code: orderdetail.m_product.order_product_dept.product_d_code
              , count: orderdetail.count
              , order: [order]
            }
            if (orderdetail.m_product.order_product_dept.product_d_code === Number(condition.dept)) {
              list.push(product);
            }
          } else {
            list[idx].count = list[idx].count + orderdetail.count;
            list[idx].order.push(order);
          }

        });
      });
      console.log(list);
      setOrderData(list);
      createCsvdata(list);
      conditionActions.setIsReload(false);
    }
  }, [props.match.params.siteId, user, condition.shop, condition.plan, condition.receiveDate, condition.status, condition.dept, condition.isReload, conditionActions]);

  useEffect(() => {
    refreshOrder();
  }, [refreshOrder]);

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(orderData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {orderData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {orderData !== null && (
        <div ref={componentRef} className="print-list">
          <div className="row mb-0 p-0 ">
            <div className="col-6 pl-0 text-left align-self-end">
              {props.condition.shop && orderData[0] && <div className="row"><div className="col-12"><h4>{orderData[0].receive_shop_name}</h4></div></div>}
              <div className="row">
                <div className="col-3 pr-2">
                  {orderData[0] &&
                    <ReactToPrint
                      trigger={() => (
                        <button type="button" className="btn btn-primary print-none px-0 mb-1 w-100">
                          注文情報印刷
                        </button>
                      )}
                      content={() => componentRef.current}
                      pageStyle={""}
                    />}
                </div>
                <div className="col-3 px-1">
                  {csvData &&
                    <CSVLink className="btn btn-primary print-none mb-1 px-0 w-100" data={csvData.list.sort((a, b) => sortData(a, b))} headers={csvData.header} filename={"注文データ.csv"}>
                      注文情報出力
                    </CSVLink>
                  }
                </div>
              </div>
            </div>
            <div className="col-6 m-0 p-1 align-self-end text-right">
              {(condition.receiveDateRange && condition.receiveDateRange.length > 1 && condition.receiveDateRange[0] && condition.receiveDateRange[1]) &&
                <span>{moment(condition.receiveDateRange[0]).format('YYYY年M月D日(dd)')} ～ {moment(condition.receiveDateRange[1]).format('YYYY年M月D日(dd)')}</span>
              }
            </div>
          </div>
          <div className="row mb-3 p-0">
            <div className="col-12 p-0">
              <table className="table table-bordered table-dept" height="1">
                <thead className={`table-${constClass.COLOR[user.userClass]}`}>
                  <tr>
                    <th className="text-center align-middle text-nowrap like-button">
                      企画
                    </th>
                    <th className="text-center align-middle text-nowrap like-button">
                      商品<br />カテゴリ
                    </th>
                    <th className="text-center align-middle text-nowrap like-button">
                      商品<br />コード
                    </th>
                    <th className="text-center align-middle text-nowrap like-button" style={{ maxWidth: '100px' }}>
                      商品名
                    </th>
                    <th className="text-center align-middle text-nowrap like-button">
                      注文数
                    </th>
                    {/* <th className="text-center align-middle like-button">
                      受取日
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {orderData.sort((a, b) => sortData(a, b)).map((data, idx) => (
                    <tr key={data.idx}>
                      {/* 企画 */}
                      <td className="text-center align-middle p-1">
                        {data.plan_name}
                      </td>
                      {/* 商品カテゴリ */}
                      <td className="text-center align-middle p-1">
                        {data.product_category_name}
                      </td>
                      {/* 商品コード */}
                      <td className="text-center align-middle p-1">
                        {data.product_code}
                      </td>
                      {/* 商品名 */}
                      <td className="text-center align-middle p-1" style={{ maxWidth: '150px' }}>
                        <div className="row p-0 m-1">
                          <div className="col-12 text-left align-middle  p-0 m-0">{data.product_name}</div>
                        </div>
                      </td>
                      {/* 注文数 */}
                      <td className="text-center align-middle p-1">
                        {data.count}<br />
                      </td>
                      {/* 受取日 */}
                      {/* <td className="text-center align-middle p-1 text-nowrap">
                        {data.receive_date}
                      </td> */}
                    </tr>
                  ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default BackyardOrderDept;