import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import Common from '../Common/common.js';

const Area = (props) => {
  const { user, settings } = props;
  const [areaData, setAreaData] = useState(null);
  const [areaNew, setAreaNew] = useState({ area_id: null, area_name: '', upd_date: null});
  const [lockData, setLockData] = useState(false);
  const { addToast } = useToasts();

  const refreshArea = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const reg_params = {
      "site_id": props.match.params.siteId,
      "operator": "and",
      "where": [{ "site_id": props.match.params.siteId }]
    }
    const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/area/search/`, reg_params, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    data.sort((a, b) => a.area_id - b.area_id);

    setAreaData(data);
    const new_data = { area_id: null, area_name: '', upd_date: null };
    setAreaNew(new_data);
  }, [props.match.params.siteId])

  // 登録ボタン：処理
  const handleAreaRegistClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!data.area_id) {
      window.alert('地区IDを入力してください。')
    }
    if (!data.area_name) {
      window.alert('地区名を入力してください。');
      return;
    }
    const params = {
      site_id: props.match.params.siteId,
      area_id: data.area_id,
      area_name: data.area_name,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/area/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshArea();
      }
      setLockData(false);
    }
  }

  // 削除ボタン：処理
  const handleAreaDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (data.area_count > 0) {
      window.alert('対象の地区名に'+Common.getSettingValue(settings, 'SHOP_NAME', '店舗')+'が登録されているため、削除できません。');
      return;
    }
    if (!window.confirm(`地区名[${data.area_id}:${data.area_name}]を削除します。よろしいですか？`)) {
      return;
    }
    const params = {
      site_id: props.match.params.siteId,
      area_id: data.area_id,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/area/delete/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        await refreshArea();
      }
      setLockData(false);
    }
  }

  // value値変更イベント
  const handleChangeCell = (index, key) => (event) => {
    if (index === 'new') {
      const new_data = { ...areaNew, [key]: event.target.value }
      setAreaNew(new_data);
    } else {
      const _areaData = [...areaData]
      _areaData[index] = { ..._areaData[index], [key]: event.target.value }
      console.log(_areaData)
      setAreaData(_areaData)
    }
  }

  // 登録ボタン
  const renderRegistButton = (data) => {
    return (
      <button type="button"
        disabled={lockData}
        className={`btn btn-primary mx-1`}
        onClick={() => handleAreaRegistClick(data)}>
        登録
      </button>
    )
  }

  // 削除ボタン
  const renderDeleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.upd_date === null}
        className={`btn btn-primary mx-1`}
        onClick={() => handleAreaDeleteClick(data)}>
        削除
      </button>
    )
  }

  // 初期実行トリガー
  useEffect(() => {
    async function fetchData() {
      await refreshArea();
    }
    return fetchData();
  }, [refreshArea]);


  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(areaData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {areaData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {areaData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-${constClass.COLOR[user.userClass]}`}>
                <tr>
                  <td className="text-center">
                    <span className='needmark'>※</span>
                      地区ID
                  </td>
                  <td className="text-center">
                    <span className='needmark'>※</span>
                      地区名
                  </td>
                  <td className="text-center">
                    作成者<br />更新者
                  </td>
                  <td className="text-center">
                    作成日時<br />更新日時
                  </td>
                  <td className="text-center">
                    処理
                  </td>
                </tr>
              </thead>
              <tbody>
                {/* 新規入力行 */}
                <tr key="new">
                  {/* 地区ID */}
                  <td className="text-center align-middle input-number-w120">
                    <input type="text" className="form-control" id={"area_id_new"} name="area_id[]" value={areaNew.area_id || ''} onChange={handleChangeCell('new', 'area_id')}></input>
                  </td>
                  {/* 地区名 */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"area_name_new"} name="area_name[]" value={areaNew.area_name || ''} onChange={handleChangeCell('new', 'area_name')}></input>
                  </td>
                  {/* 作成者更新者 */}
                  <td className="text-center align-middle">
                  </td>
                  {/* 作成日時 更新日時 */}
                  <td className="text-center align-middle">
                  </td>
                  {/* 処理 */}
                  <td className="text-center align-middle">
                    {renderRegistButton(areaNew)}
                    {renderDeleteButton(areaNew)}
                  </td>
                </tr>
                {areaData.map((data, idx) => (
                  <tr key={data.area_id}>
                    {/* 地区ID */}
                    <td className="text-center align-middle">
                      {data.area_id}
                    </td>
                    {/* 地区名 */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control"  id={"area_name" + data.area_id} name="area_name[]" value={data.area_name || ''} onChange={handleChangeCell(idx, 'area_name')}></input>
                    </td>
                    {/* 作成者 更新者*/}
                    <td className="text-center align-middle">
                      {data.ins_name}<br />
                      {data.upd_name}
                    </td>
                    {/* 作成日時 更新日時 */}
                    <td className="text-center align-middle">
                      {data.ins_date ? moment(data.ins_date).format('YYYY-MM-DD HH:mm:ss') : ''}<br />
                      {data.upd_date ? moment(data.upd_date).format('YYYY-MM-DD HH:mm:ss') : ''}
                    </td>
                    {/* 処理 */}
                    <td className="text-center align-middle">
                      {renderRegistButton(data)}
                      {renderDeleteButton(data)}
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default Area;