import constClass from '../../Constants/Constants';
import Common from '../Common/common.js';
import moment from 'moment';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/pagination/pagination.min.css'

const ProductList = ({site_id, products, disabled, message, receive_shop, displayCartCount, addCart, subCart, setting, productCalendarReceive, productCalendarMaster, select_receive_date}) => {

  const getDateString = (date = null) => {
    const sysdate = new Date();
    if (date === null) {
      date = sysdate;
    }
    return String(date.getFullYear()) + (('00' + String(date.getMonth() + 1)).slice(-2)) + (('00' + String(date.getDate())).slice(-2));
  }

  const checkEarlyDiscount = (product) => {
    return product.early_discount_start <= getDateString() && product.early_discount_end >= getDateString();
  }

  const getShortDateString = (date) => {
    date = date.replace(/-/g, '');
    return Number(date.substr(4, 2)).toString() + "/" + Number(date.substr(6, 2)).toString();
  }

  return (
    <div className="row mx-0">
      <div className="col px-0">
        {products.map((item, idx) => // 早割対象フィルタ
          <div key={item.product_id}>
            {idx > 0 &&
                <hr className="m-0"></hr>
               }
            <div className="row mx-0 bg-white px-3-env pt-2 pb-1">
              <div className="col bg-white py-1 px-1 align-self-center">
                <div className="row mx-0 text-center">
                  <Swiper
                    modules={[Navigation, Pagination]}
                    navigation
                    pagination={{ clickable: true, el: "#pagination" }}
                    slidesPerView={'auto'}
                  >
                  {item.product_image &&
                    <SwiperSlide>
                      <div className='row p-1'>
                        <div className='col-12 mt-0 pb-1 text-center'>
                          <div className='position-relative mx-auto py-0 px-3'>
                            <img src={`/${site_id}${constClass.PRODUCT_IMAGE_DIR}${item.product_image}`} className='w-100' alt={item.product_name} onError={(e) => e.target.style.display = 'none'} />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  }
                  {item.product_image2 &&
                    <SwiperSlide>
                      <div className='row p-1'>
                        <div className='col-12 mt-0 pb-1 text-center'>
                          <div className='position-relative mx-auto py-0 px-3'>
                            <img src={`/${site_id}${constClass.PRODUCT_IMAGE_DIR}${item.product_image2}`} className='w-100' alt={item.product_name} onError={(e) => e.target.style.display = 'none'} />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  }
                  {item.product_image3 &&
                    <SwiperSlide>
                      <div className='row p-1'>
                        <div className='col-12 mt-0 pb-1 text-center'>
                          <div className='position-relative mx-auto py-0 px-3'>
                            <img src={`/${site_id}${constClass.PRODUCT_IMAGE_DIR}${item.product_image3}`} className='w-100' alt={item.product_name} onError={(e) => e.target.style.display = 'none'} />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  }
                  </Swiper>
                </div>
                {checkEarlyDiscount(item) &&
                  <div className="row mx-0 text-left">
                    <div className="col px-0 bg-white">
                      <span className="badge badge-warning p-1 align-middle">早割対象商品</span>
                      <span className="font-weight-normal px-2 align-middle">{getShortDateString(item.early_discount_end)}までのご注文で早割適用</span>
                    </div>
                  </div>
                }
                <div className="row mx-0 text-left">
                  <div className="col-9 px-0 bg-white">{item.product_name}</div>
                  <div className="col-3 px-0 bg-white text-right">
                    &yen;{Common.getSettingValue(setting, 'TAX_INCLUDED_FLAG', '0') === '1' ? Math.floor(item.price).toLocaleString() + '(税込)' : Math.floor(item.price_before_tax).toLocaleString()}<br />
                    {Common.getSettingValue(setting, 'TAX_INCLUDED_FLAG', '0') !== '1' && <small>&yen;{item.price.toLocaleString()}(税込)</small>}
                  </div>
                </div>
                <div className="row mx-0 text-left">
                  <div className="col px-0 bg-white font-weight-normal">{Common.getBrString(item.product_detail)}</div>
                </div>
                {/* ご購入時ポイント表示 */}
                {item.point > 0 &&
                  <div className="row mx-0 text-left">
                    <div className="col px-0 bg-white text-danger">{Common.getSettingValue(setting, 'POINT_BUY_FRONTSTRING', '')}{item.point}{Common.getSettingValue(setting, 'POINT_BUY_BACKSTRING', '')}</div>
                  </div>
                }
                <div className="row mx-4">
                  <div className="col-auto text-center p-1 ml-1">
                    {Common.getSettingValue(setting, 'SHOP_CALENDAR', '') === '1' && productCalendarMaster && 
                      <button
                        disabled={((item.year_round_type!=="1" || productCalendarMaster.find(p => p.product_id === item.product_id && moment(p.product_start).format('YYYYMMDD') <= moment(select_receive_date).format('YYYYMMDD') && moment(p.product_end).format('YYYYMMDD') >= moment(select_receive_date).format('YYYYMMDD')).stock > 0) && (productCalendarMaster.find(p => p.product_id === item.product_id && moment(p.product_start).format('YYYYMMDD') <= moment(select_receive_date).format('YYYYMMDD') && moment(p.product_end).format('YYYYMMDD') >= moment(select_receive_date).format('YYYYMMDD')).stock - productCalendarReceive.find(p => p.product_id === item.product_id).order_count) <= 0 ) || disabled || item.m_ng_shop.map(s => s.shop_id).includes(receive_shop)}
                        className={`btn btn-active w-100 h-100 py-0 px-2`}
                        onClick={() => { subCart(item.product_id) }}>
                        －
                      </button>
                    }
                    {(Common.getSettingValue(setting, 'SHOP_CALENDAR', '') !== '1' || !productCalendarMaster) &&
                      <button
                        disabled={((item.year_round_type!=="1" || item.stock>0) && (item.stock - item.order_count) <= 0 ) || disabled || item.m_ng_shop.map(s => s.shop_id).includes(receive_shop)}
                        className={`btn btn-active w-100 h-100 py-0 px-2`}
                        onClick={() => { subCart(item.product_id) }}>
                        －
                      </button>
                    }
                  </div>
                  <div className="col text-center py-1 px-1 align-self-center">
                    <div className="bg-white border-textbox">
                      <h4 className="my-1">
                        {displayCartCount(item.plan_id, item.product_id)}
                      </h4>
                    </div>
                  </div>
                  <div className="col-auto text-center p-1 mr-1">
                    {Common.getSettingValue(setting, 'SHOP_CALENDAR', '') === '1' && productCalendarMaster &&
                      <button
                        disabled={((item.year_round_type!=="1" || productCalendarMaster.find(p => p.product_id === item.product_id && moment(p.product_start).format('YYYYMMDD') <= moment(select_receive_date).format('YYYYMMDD') && moment(p.product_end).format('YYYYMMDD') >= moment(select_receive_date).format('YYYYMMDD')).stock > 0) && (productCalendarMaster.find(p => p.product_id === item.product_id && moment(p.product_start).format('YYYYMMDD') <= moment(select_receive_date).format('YYYYMMDD') && moment(p.product_end).format('YYYYMMDD') >= moment(select_receive_date).format('YYYYMMDD')).stock - productCalendarReceive.find(p => p.product_id === item.product_id).order_count) <= 0 ) || disabled || item.m_ng_shop.map(s => s.shop_id).includes(receive_shop)}
                        className={`btn btn-active w-100 h-100 py-0 px-2`}
                        onClick={() => { addCart(item.product_id) }}>
                        ＋
                      </button>
                    }
                    {(Common.getSettingValue(setting, 'SHOP_CALENDAR', '') !== '1' || !productCalendarMaster) &&
                      <button
                        disabled={((item.year_round_type!=="1" || item.stock>0) && (item.stock - item.order_count) <= 0 ) || disabled || item.m_ng_shop.map(s => s.shop_id).includes(receive_shop)}
                        className={`btn btn-active w-100 h-100 py-0 px-2`}
                        onClick={() => { addCart(item.product_id) }}>
                        ＋
                      </button>
                    }
                  </div>
                </div>
                <div className="row mx-0 text-center">
                  <div className="col px-0 bg-white font-weight-normal text-danger">{message[item.product_id]}</div>
                </div>
              </div>
            </div>
            <div className="row mx-0 bg-white px-3-env pt-1 pb-2">
              <div className="col">
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductList;