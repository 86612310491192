import React from 'react';

const Common = {};

Common.getSettingValue = function (settings, setting_type, default_value = null) {
    return settings && settings.find(s => s.setting_type === setting_type) && settings.find(s => s.setting_type === setting_type).setting_val ? settings.find(s => s.setting_type === setting_type).setting_val : default_value;
}

Common.getTimeString = function (time) {
    return `${Math.floor(Number(time) / 100)}:${time.substr(2, 2)}`;
}


// 契約期間内かどうか
Common.checkContractPeriod = function (settings) {
    const start = this.getSettingValue(settings, "CONTRACT_PERIOD_START", "00010101");
    const end = this.getSettingValue(settings, "CONTRACT_PERIOD_END", "99991231");
    const today = new Date();
    const date = String(today.getFullYear()) + (('00' + String(today.getMonth() + 1)).slice(-2)) + (('00' + String(today.getDate())).slice(-2));
    if (start <= date && date <= end) {
        return true;
    }
    return false;
}


/****************************************************************
 * 機　能： 入力された値が日付でYYYY/MM/DD形式になっているか調べる
 * 引　数： strDate = 入力された値
 * 戻り値： 正 = true　不正 = false
 ****************************************************************/
Common.checkDate = function (strDate) {
    if (strDate !== null) {
        if (!strDate.match(/^\d{4}\/\d{2}\/\d{2}$/)) {
            return false;
        }
        var y = strDate.split("/")[0];
        var m = strDate.split("/")[1] - 1;
        var d = strDate.split("/")[2];
        var date = new Date(y, m, d);
        if (date.getFullYear() !== y || date.getMonth() !== m || date.getDate() !== d) {
            return false;
        }
    }
    return true;
}

Common.getBrString = function (str) {
    const texts = (str ? str : '').split(/(\n)/).map((item, index) => {
        return (
            <React.Fragment key={index}>
                {item.match(/\n/) ? <br /> : item}
            </React.Fragment>
        );
    });
    return <div>{texts}</div>;
}

Common.calcRounding = function (settings, type, value) {
    var round;
    if (type === 'TAX') {
        round = Common.getSettingValue(settings, 'TAX_ROUNDING_FLAG', '0');
    } else if (type === 'DISCOUNT') {
        round = Common.getSettingValue(settings, 'DISCOUNT_ROUNDING_FLAG', '1');
    }

    if (round === '1') {
        // 切り上げ
        return Math.ceil(value);
    } else if (round === '2') {
        // 四捨五入
        return Math.round(value);
    } else {
        // 切り捨て
        return Math.floor(value);
    }
}

export default Common;